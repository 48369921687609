import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import FilterListIcon from "@mui/icons-material/FilterList"
import { Box, Collapse, Grid, Typography } from "@mui/material"
import { useState } from "react"
import CollectionDatasetSwitch, {
  CatalogMode,
  type CatalogModeMenuProps,
} from "../../../../components/CollectionDatasetSwitch"
import LabeledIconButton from "../../../../components/CollectionDatasets/LabeledIconButton"
import { setAddDataCollectionPopupVisibleRA } from "../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../utils/redux/store"
import MyDataFilters from "./components/MyDataFilters"

type Props = {
  header: string
  description?: string
} & CatalogModeMenuProps

const MyDataHeader = (props: Props) => {
  const dispatch = useAppDispatch()
  const [showFiltering, setShowFiltering] = useState(false)

  const isCollectionMode = props.currentMode === CatalogMode.Collection

  const clickAddCollection = () => {
    dispatch(setAddDataCollectionPopupVisibleRA(true))
  }

  return (
    <Box>
      <Grid container alignItems={"center"}>
        <Grid item sx={{ mr: 1 }}>
          <Typography variant="h4" sx={{ fontWeight: 800, lineHeight: "60px" }}>
            {props.header}
          </Typography>
        </Grid>
        <Grid item display={"flex"} alignItems={"center"}>
          {isCollectionMode && (
            <LabeledIconButton
              sx={{ ml: 1 }}
              icon={<AddCircleOutlineIcon />}
              label={"Add New"}
              onClick={clickAddCollection}
            />
          )}
          <LabeledIconButton
            sx={{ ml: 0.5 }}
            icon={<FilterListIcon />}
            label={"Filter"}
            onClick={() => setShowFiltering(!showFiltering)}
            testId={"showMyDataFiltersBtn"}
          />
        </Grid>
        <Grid item sx={{ ml: "auto" }}>
          <CollectionDatasetSwitch setMode={props.setMode} currentMode={props.currentMode} />
        </Grid>
      </Grid>
      <Collapse in={showFiltering}>
        <MyDataFilters />
      </Collapse>
    </Box>
  )
}

export default MyDataHeader
