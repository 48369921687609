import React from "react"
import { StyledButton } from "../../../../../components/StyledButton"
import { externalPaths } from "../../../../../components/navigation.helpers"
import IdCopy from "./IdCopy"
import InteractionItem from "./InteractionItem"

type Props = {
  nameId: string
  uuid: string
}

const ApiIds = (p: Props) => {
  const clickOpenDocs = () => {
    window.open(externalPaths.Documentation)
  }

  return (
    <InteractionItem title="API">
      Use qualified name:
      <IdCopy text={p.nameId} />
      or UUID:
      <IdCopy text={p.uuid} />
      to interact with this dataset using our API.
      <StyledButton onClick={clickOpenDocs} variant="contained" sx={{ display: "block", mt: 1 }}>
        Open API documentation
      </StyledButton>
    </InteractionItem>
  )
}

export default React.memo(ApiIds)
