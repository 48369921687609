/* eslint-disable @typescript-eslint/dot-notation */
import { Box, Grid, Stack } from "@mui/material"
import _ from "lodash"
import React, { useRef, useState } from "react"
import { StyledButton } from "../../../../../../components/StyledButton"
import type { RawFileFilters } from "../../../../../../utils/api/raw.types"
import type { BoundingMapboxCoords } from "../../../../../../utils/frontendTypes/datasets.types"
import { createRawFileFilter } from "../../../../../MyDataset/components/MyDatasetContent/components/DatasetDetailsLeftColumnRaw/components/rawFile.helpers"
import AddMetadataKeyFilter from "./components/AddMetadataKeyFilter"
import ClearableInput from "./components/ClearableInput"
import RawGeolocationFilter from "./components/RawGeolocationFilter"
import { indexForShowingAddInputButton } from "./fileFilters.helpers"

type Props = {
  setFileFilters: React.Dispatch<React.SetStateAction<RawFileFilters | undefined>>
}

const FileFilters = (p: Props) => {
  const filtersRef = useRef<RawFileFilters>({})

  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") setFilters()
  }

  const [customFields, setcustomFields] = useState<string[]>([])
  const [geoFilter, setGeoFilter] = useState<BoundingMapboxCoords | undefined>()
  const [showMetadataKeyInput, setShowMetadataKeyInput] = useState(false)

  const nameInputRef = useRef<HTMLInputElement | null>(null)
  const typeInputRef = useRef<HTMLInputElement | null>(null)

  const [nameInputKey, setNameInputKey] = useState(0)
  const [typeInputKey, setTypeInputKey] = useState(0)

  const clearNameField = () => {
    setNameInputKey(nameInputKey + 1)
    setFilters()
  }

  const clearFileTypeField = () => {
    setTypeInputKey(typeInputKey + 1)
    setFilters()
  }

  const filters = filtersRef.current

  const generateFilters = (coords?: BoundingMapboxCoords | null) => {
    const filtersToSend: RawFileFilters = {}
    if (filters.metadata) filtersToSend["metadata"] = { ...filters.metadata }
    if (nameInputRef.current?.value) filtersToSend["name"] = nameInputRef.current?.value
    if (typeInputRef.current?.value) filtersToSend["mime_type"] = typeInputRef.current?.value
    const coordsToSet = coords ?? geoFilter
    if (coordsToSet && coords !== null) filtersToSend["geolocation"] = createRawFileFilter(coordsToSet)
    if (Object.keys(filtersToSend).length === 0) return undefined
    return filtersToSend
  }

  const setFilters = () => {
    const filtersToSend = generateFilters()
    p.setFileFilters(filtersToSend)
  }

  const setGeolocationFilter = (coords: BoundingMapboxCoords | null) => {
    coords ? setGeoFilter(coords) : setGeoFilter(undefined)
    const filtersToSend = generateFilters(coords)
    p.setFileFilters(filtersToSend)
  }

  const deleteCustomField = (field: string) => {
    setcustomFields(customFields.filter(f => f !== field))
  }

  const clearFilters = () => {
    filtersRef.current = {}
    setcustomFields([])
    if (nameInputRef.current) {
      nameInputRef.current.value = ""
      setNameInputKey(nameInputKey + 1)
    }
    if (typeInputRef.current) {
      typeInputRef.current.value = ""
      setTypeInputKey(typeInputKey + 1)
    }
    p.setFileFilters(undefined)
    setShowMetadataKeyInput(false)
    setGeoFilter(undefined)
  }

  const firstTwoCustomFilters = customFields.slice(0, 2)
  const restOfCustomFilters = customFields.slice(2)

  const addMetadataKeyFilter = (
    <AddMetadataKeyFilter
      customFields={customFields}
      setShowMetadataKeyInput={setShowMetadataKeyInput}
      setcustomFields={setcustomFields}
      showMetadataKeyInput={showMetadataKeyInput}
    />
  )

  const showButtonInBoxWithIndex = indexForShowingAddInputButton(customFields.length)

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item minWidth={230}>
          <Stack spacing={2}>
            <ClearableInput
              ref={nameInputRef}
              key={"nameInput" + nameInputKey}
              label="File Name"
              onKeyDown={onEnter}
              size="small"
              clearCallback={clearNameField}
            />
            <ClearableInput
              label="File Type"
              ref={typeInputRef}
              key={"textInput" + typeInputKey}
              onKeyDown={onEnter}
              clearCallback={clearFileTypeField}
              size="small"
            />
            {firstTwoCustomFilters.map(field => (
              <ClearableInput
                key={field}
                label={`Filter by ${field}`}
                onKeyDown={onEnter}
                onChange={e => {
                  if (!filtersRef.current.metadata) filtersRef.current.metadata = {}
                  filtersRef.current.metadata[field] = e.target.value
                }}
                clearCallback={() => {
                  delete filtersRef.current.metadata?.[field]
                  deleteCustomField(field)
                  setFilters()
                }}
                size="small"
                alwaysShowClear={true}
              />
            ))}
            {showButtonInBoxWithIndex === 0 && addMetadataKeyFilter}
          </Stack>
        </Grid>
        {(!!restOfCustomFilters.length || firstTwoCustomFilters.length === 2) &&
          _.range(Math.floor(restOfCustomFilters.length / 4) + 1).map(boxIndex => (
            <Grid item key={boxIndex + "inputBox"}>
              <Stack spacing={2}>
                {restOfCustomFilters.slice(boxIndex * 4, boxIndex * 4 + 4).map(field => (
                  <ClearableInput
                    key={field}
                    label={`Filter by ${field}`}
                    onKeyDown={onEnter}
                    onChange={e => {
                      if (!filtersRef.current.metadata) filtersRef.current.metadata = {}
                      filtersRef.current.metadata[field] = e.target.value
                    }}
                    clearCallback={() => {
                      delete filtersRef.current.metadata?.[field]
                      deleteCustomField(field)
                      setFilters()
                    }}
                    size="small"
                    alwaysShowClear={true}
                  />
                ))}
                {showButtonInBoxWithIndex === boxIndex + 1 && addMetadataKeyFilter}
              </Stack>
            </Grid>
          ))}
        <Grid item minWidth={230}>
          <RawGeolocationFilter setGeolocationFilter={setGeolocationFilter} geoLocationFilter={geoFilter} />
        </Grid>
      </Grid>
      <Box sx={{ my: 1 }}>
        <StyledButton variant="outlined" onClick={setFilters} sx={{ mr: 1 }}>
          Set filters
        </StyledButton>
        <StyledButton variant="outlined" onClick={clearFilters}>
          Clear filters
        </StyledButton>
      </Box>
    </>
  )
}

export default React.memo(FileFilters)
