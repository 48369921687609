import { type BoundingMapboxCoords } from "../frontendTypes/datasets.types"

type MapBounds = {
  minLong: number
  minLat: number
  maxLong: number
  maxLat: number
}

export const findBoundsOfPolygon = (polygon?: BoundingMapboxCoords): MapBounds => {
  if (!polygon) {
    return {
      minLong: -180,
      minLat: -90,
      maxLong: 180,
      maxLat: 90,
    }
  }

  const minLong = polygon.reduce((prev, currentArray) => {
    const current = currentArray[0]
    if (prev < current) return prev
    else return current
  }, 180)

  const maxLong = polygon.reduce((prev, currentArray) => {
    const current = currentArray[0]
    if (prev > current) return prev
    else return current
  }, -180)

  const minLat = polygon.reduce((prev, currentArray) => {
    const current = currentArray[1]
    if (prev < current) return prev
    else return current
  }, 90)

  const maxLat = polygon.reduce((prev, currentArray) => {
    const current = currentArray[1]
    if (prev > current) return prev
    else return current
  }, -90)

  return {
    minLong,
    minLat,
    maxLong,
    maxLat,
  }
}

const findBoundsOfMultiPolygon = (multiPolygon: BoundingMapboxCoords[][]): MapBounds => {
  let minLong = Infinity
  let maxLong = -Infinity
  let minLat = Infinity
  let maxLat = -Infinity

  for (const polygon of multiPolygon) {
    for (const ring of polygon) {
      for (const point of ring) {
        const longitude = point[0]
        const latitude = point[1]

        if (longitude < minLong) minLong = longitude
        if (longitude > maxLong) maxLong = longitude
        if (latitude < minLat) minLat = latitude
        if (latitude > maxLat) maxLat = latitude
      }
    }
  }

  return {
    minLong,
    minLat,
    maxLong,
    maxLat,
  }
}

const findBoundsOfMultiline = (multiLine: GeoJSON.Position[][]): MapBounds => {
  let minLong = 180
  let maxLong = -180
  let minLat = 90
  let maxLat = -90

  for (const point of multiLine[0]) {
    const longitude = point[0]
    const latitude = point[1]

    if (longitude < minLong) minLong = longitude
    if (longitude > maxLong) maxLong = longitude
    if (latitude < minLat) minLat = latitude
    if (latitude > maxLat) maxLat = latitude
  }

  return {
    minLong,
    minLat,
    maxLong,
    maxLat,
  }
}

const setToRange = (value: number, range: [number, number]) => {
  if (value < range[0]) return range[0]
  if (value > range[1]) return range[1]
  return value
}

const trimBounds = (bounds: MapBounds): MapBounds => ({
  minLong: setToRange(bounds.minLong, [-180, 180]),
  maxLong: setToRange(bounds.maxLong, [-180, 180]),
  maxLat: setToRange(bounds.maxLat, [-90, 90]),
  minLat: setToRange(bounds.minLat, [-90, 90]),
})

/**
 * Takes bounds of a geometry object and returns bounds that are larger - limits to show on the map
 * @param bounds bounds of the geometry object
 * @param deltaDegrees how much to add to the bounds in degrees - takes precedence over deltaPercent
 * @param deltaPercent how much to add to the bounds in percent (of bigger dimension of the geometry object) if deltaDegrees is not provided
 */
const getFinalBounds = ({
  bounds,
  deltaDegrees,
  deltaPercent,
}: {
  bounds: MapBounds
  deltaDegrees?: number
  deltaPercent?: number
}): MapBounds => {
  const finalBounds = { ...bounds }

  if (deltaDegrees) {
    const deltaY = deltaDegrees
    finalBounds.minLat -= deltaY
    finalBounds.maxLat += deltaY

    const deltaX = deltaDegrees
    finalBounds.minLong -= deltaX
    finalBounds.maxLong += deltaX

    return trimBounds(finalBounds)
  }

  if (deltaPercent) {
    const longestDistance = Math.max(Math.abs(bounds.maxLong - bounds.minLong), Math.abs(bounds.maxLat - bounds.minLat))

    const delta = deltaPercent * longestDistance
    finalBounds.minLat -= delta
    finalBounds.maxLat += delta

    finalBounds.minLong -= delta
    finalBounds.maxLong += delta
  }

  return trimBounds(finalBounds)
}

export const getBoundsToShowOnMap = (geometry?: GeoJSON.Geometry): MapBounds => {
  const fullGlobe: MapBounds = {
    minLong: -180,
    minLat: -90,
    maxLong: 180,
    maxLat: 90,
  }

  if (!geometry) return fullGlobe

  switch (geometry?.type) {
    case "Point":
      return getFinalBounds({
        bounds: {
          minLong: geometry.coordinates[0],
          minLat: geometry.coordinates[1],
          maxLong: geometry.coordinates[0],
          maxLat: geometry.coordinates[1],
        },
        deltaDegrees: 0.1,
      })
    case "MultiPoint":
      return getFinalBounds({ bounds: findBoundsOfPolygon(geometry.coordinates), deltaPercent: 10 })
    case "LineString":
      return getFinalBounds({ bounds: findBoundsOfPolygon(geometry.coordinates), deltaDegrees: 0 })
    case "MultiLineString":
      return getFinalBounds({ bounds: findBoundsOfMultiline(geometry.coordinates), deltaPercent: 0.3 })
    case "Polygon":
      return getFinalBounds({ bounds: findBoundsOfPolygon(geometry.coordinates[0]), deltaPercent: 0.3 })
    case "MultiPolygon":
      return getFinalBounds({ bounds: findBoundsOfMultiPolygon(geometry.coordinates), deltaPercent: 0.3 })
    case "GeometryCollection":
      return fullGlobe
    default:
      return fullGlobe
  }
}
