import React from "react"

const NoSearchResultsText = () => {
  return (
    <div>
      <p style={{ marginTop: 0 }}>Your search did not match any results.</p>
      <div>
        Suggestions:
        <ul style={{ margin: 0 }}>
          <li>Make sure that all words are spelled correctly.</li>
          <li>Try different keywords.</li>
        </ul>
      </div>
    </div>
  )
}

export default React.memo(NoSearchResultsText)
