import { Box, Tooltip } from "@mui/material"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import { debounce } from "lodash"
import React, { useCallback, useState } from "react"

type Props = {
  value: string
}

const ValueWithCopyButton = (p: Props) => {
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedClickCopy = useCallback(
    debounce(() => setShowCopiedTooltip(false), 1000),
    []
  )

  const clickCopy = async () => {
    setShowCopiedTooltip(true)
    await navigator.clipboard.writeText(p.value)
    debouncedClickCopy()
  }

  const clickAway = useCallback(() => setShowCopiedTooltip(false), [])

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <Tooltip
        placement="right"
        title="Copied to clipboard"
        open={showCopiedTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener>
        <Box display={"inline"} onClick={clickCopy} sx={{ cursor: "pointer", textDecoration: "underline" }}>
          {p.value}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default React.memo(ValueWithCopyButton)
