import React from "react"
import { useNavigate } from "react-router-dom"
import { paths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import DashbordItem from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const BrowseProjectsWidget = () => {
  const nav = useNavigate()

  const onClick = () => {
    nav(paths.Projects)
  }
  return (
    <DashbordItem sx={{ backgroundColor: colorPalette.luminousPurpleO15 }} elevation={0}>
      <WidgetHeader title="Browse Projects" color={colorPalette.luminousPurple} />
      <WidgetContent description="Projects combine ODP data according to a topic. See featured projects and find associated Workspace notebooks." />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousPurple} textColor={colorPalette.white}>
        View Projects
      </WidgetButton>
    </DashbordItem>
  )
}

export default React.memo(BrowseProjectsWidget)
