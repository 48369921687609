import { type DatasetRow } from "./schema.types"

export type DataPointsTable = {
  columns: string[]
  rows: Record<string, any>[]
}

export const mapDataPointsToTable = (points: DatasetRow[], maxRows?: number): DataPointsTable => {
  return {
    columns: points[0] ? Object.keys(points[0]) : [],
    rows: maxRows ? points.slice(0, maxRows) : points,
  }
}
