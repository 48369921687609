import { getNameIdFromReferenceId } from "../../../pages/Catalog/catalog.helpers"
import type { DataCollection } from "../../frontendTypes/dataCollection.types"
import { type DataSet } from "../../frontendTypes/datasets.types"
import { mapToDataCollection, mapToDataset } from "../../frontendTypes/frontendMappers"
import { jsSDK } from "../../sdk/jsSDK"
import { type DataCollectionDtoGet } from "../../sdk/utils/entities/sdk.dataCollection.types"
import { type APIResultsDto, type DatasetDtoGet } from "../../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"
import { DatasetApiTags, datasetsApi } from "./dataset.queries"
import { createResponse } from "./queries.helpers"

export enum CatalogTags {
  AllCatalogEntries = "AllCatalogEntries",
  DatasetResults = "DatasetResults",
  DataCollectionResults = "DataCollectionResults",
}

const catalogApi = datasetsApi.enhanceEndpoints({ addTagTypes: Object.values(CatalogTags) })

const metadataApi = catalogApi.injectEndpoints({
  endpoints: builder => ({
    getMetadataById: builder.query<DatasetDtoGet, string>({
      queryFn: async datasetID => {
        return await createResponse<DatasetDtoGet, DatasetDtoGet>(
          async () => await jsSDK.datasets.getDatasetByIdApi(datasetID),
          el => el
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getMetadataByNameId: builder.query<DataSet, string>({
      queryFn: async nameId => {
        return await createResponse<DatasetDtoGet, DataSet>(
          async () => await jsSDK.datasets.getDatasetByNameApi(nameId),
          mapToDataset
        )
      },
      providesTags: [DatasetApiTags.UserDatasets],
    }),

    getCollectionMetadataByNameId: builder.query<DataCollection, string>({
      queryFn: async nameId => {
        return await createResponse<DataCollectionDtoGet, DataCollection>(
          async () => await jsSDK.datasets.getDataCollectionByNameApi(nameId),
          el => mapToDataCollection(el)
        )
      },
      providesTags: [DatasetApiTags.UserDataCollections],
    }),

    getDatasetSearchResults: builder.query<
      DataSet[],
      {
        searchString: string
        filterIds: string[] | null
        isPublic: boolean
        collectionId?: string
        isInternallyShared?: boolean
      }
    >({
      queryFn: async ({ searchString, filterIds, isPublic, isInternallyShared }) => {
        const nameIds = filterIds?.map(getNameIdFromReferenceId) as string[]
        return await createResponse<APIResultsDto<DatasetDtoGet>, DataSet[]>(
          async () =>
            await jsSDK.catalog.getDatasetSearchResult({
              typedSearchString: searchString,
              filterIds: nameIds,
              isPublic,
              isInternallyShared,
            }),
          data => data.results.map(mapToDataset)
        )
      },
      providesTags: [CatalogTags.DatasetResults],
    }),

    getDataCollectionSearchResults: builder.query<
      DataCollection[],
      { searchString: string; filterIds: string[] | null; isPublic: boolean; isInternallyShared?: boolean }
    >({
      queryFn: async ({ searchString, filterIds, isPublic, isInternallyShared }) => {
        const nameIds = filterIds?.map(getNameIdFromReferenceId) as string[]
        return await createResponse<APIResultsDto<DataCollectionDtoGet>, DataCollection[]>(
          async () =>
            await jsSDK.catalog.getResourceSearchResultsAPI<DataCollectionDtoGet>({
              typedSearchString: searchString,
              filterIds: nameIds,
              kind: ResourceKind.collection,
              resourceAvailability: isPublic ? "public" : "private",
              isInternallyShared,
            }),
          data => data.results.map(mapToDataCollection)
        )
      },
      providesTags: [CatalogTags.DataCollectionResults],
    }),
  }),
})

export const {
  useGetMetadataByIdQuery,
  useGetDatasetSearchResultsQuery,
  useGetMetadataByNameIdQuery,
  useGetCollectionMetadataByNameIdQuery,
  useGetDataCollectionSearchResultsQuery,
} = metadataApi
