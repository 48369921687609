import { Box } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"
import React from "react"
import { Controller } from "react-hook-form"
import type { FillableMetadataDataCollection } from "../../../../../../../../../utils/frontendTypes/dataCollection.types"
import { createPropName } from "../../../../../../../../../utils/frontendTypes/datasetType.helpers"
import { metadataFormValidationCollection, type FormRuleType } from "../../../editMetadataDialog.helpers"
import { type MetadataInputProps } from "../MetadataInput"

const MetadataDateInput = (p: MetadataInputProps<FillableMetadataDataCollection>) => {
  return (
    <Controller
      name={p.metadataPropName}
      control={p.form.control}
      rules={metadataFormValidationCollection[p.metadataPropName] as (FormRuleType<FillableMetadataDataCollection, keyof FillableMetadataDataCollection> | undefined)}
      render={({ field, formState }) => {
        return (
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                label={createPropName(p.metadataPropName)}
                value={field.value ? dayjs(field.value) : null}
                slotProps={{
                  textField: {
                    error: !!formState.errors[p.metadataPropName],
                    helperText: formState.errors[p.metadataPropName]?.message?.toString(),
                    size: "small",
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        )
      }}
    />
  )
}

export default React.memo(MetadataDateInput)
