import React from "react"
import { type FieldValues, type UseFormReturn } from "react-hook-form"
import type { FillableMetadataDataCollection } from "../../../../../../../../utils/frontendTypes/dataCollection.types"
import MetadataDateInput from "./components/MetadataDateInput"
import MetadataTextFieldCollection from "./components/MetadataTextFieldCollection"

export type MetadataInputProps<T extends FieldValues> = {
  metadataPropName: keyof T
  form: UseFormReturn<T>
}

const MetadataInput = (p: MetadataInputProps<FillableMetadataDataCollection>) => {
  switch (p.metadataPropName) {
    case "publishedDate":
      return <MetadataDateInput {...p} />
    default:
      return <MetadataTextFieldCollection {...p} />
  }
}

export default React.memo(MetadataInput) as typeof MetadataInput
