import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import React, { useRef } from "react"
import { StyledButton } from "../../../../../../../components/StyledButton"
import ClearableInput from "./ClearableInput"

type Props = {
  showMetadataKeyInput: boolean
  setShowMetadataKeyInput: React.Dispatch<React.SetStateAction<boolean>>
  setcustomFields: React.Dispatch<React.SetStateAction<string[]>>
  customFields: string[]
}

const AddMetadataKeyFilter = (p: Props) => {
  const keyInputRef = useRef<HTMLInputElement | null>(null)

  const addMetadataFilter = () => {
    p.setShowMetadataKeyInput(true)
  }

  return (
    <>
      {p.showMetadataKeyInput && (
        <ClearableInput
          ref={keyInputRef}
          label="Metadata key"
          size="small"
          onKeyDown={e => {
            if (e.key === "Enter") {
              if (!keyInputRef.current?.value) return
              p.setcustomFields([...p.customFields, keyInputRef.current.value])
              p.setShowMetadataKeyInput(false)
            }
          }}
          clearCallback={() => {
            p.setShowMetadataKeyInput(false)
          }}
          alwaysShowClear={true}
        />
      )}
      {!p.showMetadataKeyInput && (
        <StyledButton
          onClick={addMetadataFilter}
          sx={{ ml: 1, mr: 0.5, p: 1, pl: 2, pr: 2, alignSelf: "flex-start" }}
          startIcon={<AddCircleOutlineIcon />}>
          Add metadata key filter
        </StyledButton>
      )}
    </>
  )
}

export default React.memo(AddMetadataKeyFilter)
