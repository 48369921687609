import { Grid } from "@mui/material"
import React, { useState } from "react"
import { createPropName } from "../../../../utils/frontendTypes/datasetType.helpers"
import { fillableMetadataDatasetProps, type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import DatasetMetadataDisplay from "./components/DatasetMetadataDisplay"
import EditDatasetMetadataDialog from "./components/EditMetadataDialog/EditDatasetMetadataDialog"
import HeaderWithEdit from "./components/HeaderWithEdit"

type Props = {
  dataset: DataSet
}

const DatasetMetadata = (p: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openEditMetadataDialog = () => {
    setDialogOpen(true)
  }
  const closeEditMetadataDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Grid container direction={"column"} spacing={1}>
        <Grid item>
          <HeaderWithEdit headerText="Metadata" clickEditFn={openEditMetadataDialog} testId="editDatasetMetadataBtn" />
        </Grid>
        {p.dataset.fillableMetadata && (
          <Grid item>
            <DatasetMetadataDisplay
              metadata={p.dataset.fillableMetadata}
              fillable={fillableMetadataDatasetProps}
              keyTransformFn={createPropName}
            />
          </Grid>
        )}
      </Grid>
      <EditDatasetMetadataDialog open={dialogOpen} closeFn={closeEditMetadataDialog} dataset={p.dataset} />
    </>
  )
}

export default React.memo(DatasetMetadata)
