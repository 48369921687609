import { Icon } from "@mui/material"
import { type MUIStyledCommonProps } from "@mui/system"

const LogoIcon = (p: MUIStyledCommonProps) => {
  return (
    <Icon {...p}>
      <svg
        className="Ocean_logo"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 37 37">
        <path
          fill="currentColor"
          d="M18.619.002c2.32-.036 4.618.436 6.728 1.382 1.929.878 1.451 4.249 2.843 5.826 1.4 1.608.593 3.363 5.355 3.368 4.762.005 1.117 4.37 1.105 6.623a19.906 19.906 0 01-1.105 6.668c-.69 2.003 2.503 3.86 1.105 5.47-1.389 1.573-7.373 2.835-9.303 3.699a17.322 17.322 0 01-6.725 1.357c-2.313 0-4.601-.462-6.726-1.357a14.767 14.767 0 01-5.067-3.7 16.565 16.565 0 01-3.161-5.47c-.737-2.145-5.346-6.657-1.08-6.662 4.267-.005.361-4.492 1.105-6.623a16.465 16.465 0 013.162-5.47c1.4-1.58 3.127-2.849 5.066-3.724C14.021.444 16.31-.03 18.62.002zm0 4.694a9.575 9.575 0 00-4.63 1.054 8.999 8.999 0 00-3.162 2.83 12.621 12.621 0 00-1.81 4.027c-.776 3.019 2.833 4.83-2.517 6.893-5.35 2.064 3.49 5.1 4.327 6.328a8.949 8.949 0 003.161 2.83 10.575 10.575 0 004.616 1.058c1.6 0 2.96 1.48 4.396.784 1.27-.681 6.137-3.494 6.947-4.672.834-1.229-.36-3.895 0-5.328.777-3.019 2.83-3.481 2.053-6.5-.36-1.434-5.608-1.476-8-3.5-2.392-2.024.489-4.067-.78-4.75a9.565 9.565 0 00-4.601-1.054z"
        />
      </svg>
    </Icon>
  )
}

export default LogoIcon
