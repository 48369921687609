import React, { useCallback } from "react"
import FilterTimeRange from "../../../../components/FilterTimeRange/FilterTimeRange"
import { setYearsFilter } from "../../../../utils/redux/reducers/catalogReducer"
import { useAppDispatch } from "../../../../utils/redux/store"

const FilterTimeRangeCatalog = () => {
  const dispatch = useAppDispatch()
  const setFilterValues = useCallback(
    (startYear?: number, endYear?: number) => {
      dispatch(setYearsFilter({ startYear, endYear }))
    },
    [dispatch]
  )

  return <FilterTimeRange setFilterValues={setFilterValues} />
}

export default React.memo(FilterTimeRangeCatalog)
