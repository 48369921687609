import { Box } from "@mui/material"
import React from "react"
import AccessAPIIcon from "../../../assets/icons/AccessAPIIcon"
import image from "../../../assets/img/accessAPI.png"
import { externalPaths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import DashbordItem from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const APIAccessWidget = () => {
  const onClick = () => {
    window.open(externalPaths.Documentation)
  }

  return (
    <DashbordItem sx={{ backgroundColor: colorPalette.deepPurple, color: colorPalette.white }} elevation={0}>
      <WidgetHeader
        title="View ODP Docs"
        color={colorPalette.luminousCoral}
        titleIcon={<AccessAPIIcon sx={{ mr: 1 }} color="primary" />}
      />
      <WidgetContent description="Get information on using the ODP and technical documentation for APIs, SDK, and Workspaces." />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousCoral} textColor={colorPalette.white}>
        Go to Docs
      </WidgetButton>
      <Box width="calc(100% + 64px)" marginLeft={"-32px"} marginBottom="-38px" marginTop={"10px"}>
        <img src={image} width="100%" alt="" />
      </Box>
    </DashbordItem>
  )
}

export default React.memo(APIAccessWidget)
