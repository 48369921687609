import React from "react"
import { type Facets } from "../../../../../../../utils/sdk/utils/entities/sdk.dataset.types"
import DialogSectionHeader from "./DialogSectionHeader"
import FacetDisplay from "./FacetDisplay"

type Props = {
  newFacets: Facets
  setNewFacets: React.Dispatch<Facets>
}

const NewFacets = (p: Props) => {
  const hasFacets = Object.keys(p.newFacets).length > 0

  const deleteFn = (key: string) => {
    const { [key]: _, ...newFacets } = p.newFacets
    p.setNewFacets(newFacets)
  }

  return (
    hasFacets && (
      <>
        <DialogSectionHeader title="New Facets: " sx={{ mb: 1, mt: 3 }} />
        {Object.entries(p.newFacets).map(([key, value]) => (
          <FacetDisplay key={key} value={value} facetKey={key} deleteFn={deleteFn} />
        ))}
      </>
    )
  )
}

export default React.memo(NewFacets)
