import type { PersonContact } from "../frontendTypes/dataCollection.types"

export const LINK_REGEX =
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/

// Define the basic parts as strings for reuse
const basicNamePattern = "([^<>@,\\n]+)"
const optionalAdditionalNamePattern = "(,\\s+([^<>@,\\n]+))?"

export type ApiNoneType = null
export const API_NONE: ApiNoneType = null

// Combine basicNamePattern and optionalAdditionalNamePattern into namePart RegExp
export const PUBLISHER_NAME_REGEX = new RegExp(`^${basicNamePattern}${optionalAdditionalNamePattern}$`)

// Define the email part pattern and convert it into an RegExp object
const basicEmailPattern = "([^<>@,\\n]+@[^<>@,\\n]+\\.[^<>@,\\n]{2,})"
export const EMAIL_REGEX = new RegExp(`^${basicEmailPattern}$`)

// Combine namePart and emailPart into a single CONTACT_REGEX RegExp
export const CONTACT_REGEX = new RegExp(
  `^${basicNamePattern}${optionalAdditionalNamePattern}\\s+<${basicEmailPattern}>$`
)

export const createContact = ({
  firstName,
  lastName,
  email,
}: {
  firstName?: string | null
  lastName: string
  email: string
}) => {
  if (!lastName && !firstName) return `<${email}>`
  return `${lastName}${firstName ? `, ${firstName}` : ""} <${email}>`
}

export const extractContactDetails = (contactString?: string | null): PersonContact => {
  const emptyResult: PersonContact = {
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
  }
  if (!contactString) return emptyResult
  const match = contactString.match(CONTACT_REGEX)
  if (match) {
    const publisherContactLastName = match[1].trim()
    const publisherContactFirstName = match[3] ? match[3].trim() : ""
    const publisherContactEmail = match[4].trim()

    return {
      contactFirstName: publisherContactFirstName,
      contactLastName: publisherContactLastName,
      contactEmail: publisherContactEmail,
    }
  } else {
    return emptyResult
  }
}

export const extractPublisherContactDetails = (contactString?: string | null): PersonContact => {
  return extractContactDetails(contactString)
}

export const extractMaintainerContactDetails = (contactString?: string | null): PersonContact => {
  return extractContactDetails(contactString)
}
