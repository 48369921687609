export const generateNumberRegex = (allowNegative: boolean, allowDecimal: boolean): RegExp => {
  const floatRegexStr = "(\\.[0-9]*)?"
  const positiveIntRegexStr = "[0-9]+"
  const positiveOrNegativeIntRegexStr = "-?[0-9]*"

  let regexStr = `^${allowNegative ? positiveOrNegativeIntRegexStr : positiveIntRegexStr}`
  if (allowDecimal) regexStr += floatRegexStr
  regexStr += "$"
  return new RegExp(regexStr)
}

export const getKeyDownChar = (e: React.KeyboardEvent): string | undefined => {
  const char = e.key
  if (char.length > 1) return

  const charCode = char.charCodeAt(0)
  const notPrintableChar = charCode < 32 || (charCode > 126 && charCode < 160) || charCode > 255
  if (notPrintableChar) return

  return char
}

export const isPasteEvent = (e: React.KeyboardEvent) => {
  if (e.ctrlKey && e.key === "v") return true
  if (e.metaKey && e.key === "v") return true
  return false
}
