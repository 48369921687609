import { Typography } from "@mui/material"
import React from "react"
import SignInButton from "../../components/SignInButton/SignInButton"

const Unauthorized = () => {
  return (
    <Typography>
      <SignInButton /> to access this page.
    </Typography>
  )
}

export default React.memo(Unauthorized)
