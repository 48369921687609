import FilterListIcon from "@mui/icons-material/FilterList"
import { Alert, Box, Collapse, Typography } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import LabeledIconButton from "../../../../components/CollectionDatasets/LabeledIconButton"
import { StyledButton } from "../../../../components/StyledButton"
import { type RawFileFilters } from "../../../../utils/api/raw.types"
import { useGetRawFilesInfoForDatasetQuery } from "../../../../utils/redux/queries/raw.queries"
import { S } from "../../../CatalogCollection/CatalogCollection.style"
import CatalogFileList from "./components/CatalogFileList"
import FileFilters from "./components/FileFilters/FileFilters"

type Props = {
  datasetNameId: string
}

const CatalogFiles = (p: Props) => {
  const [fileFilters, setFileFilters] = useState<RawFileFilters | undefined>(undefined)
  const [showFilters, setShowFilters] = useState(false)
  const [page, setPage] = useState<string | undefined>()
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const { data, isFetching, isError } = useGetRawFilesInfoForDatasetQuery(
    { datasetId: p.datasetNameId, fileFilters, page, limit: 10 },
    { refetchOnMountOrArgChange: true }
  )

  const clickNextPage = useCallback(() => {
    if (!data?.next) return
    setPage(data.next)
    setCurrentPageIndex(currentPageIndex + 1)
  }, [data?.next, currentPageIndex])

  const resetPage = useCallback(() => {
    setPage(undefined)
    setCurrentPageIndex(0)
  }, [])

  const files = data?.results

  useEffect(() => {
    if (!showFilters) setFileFilters(undefined)
  }, [showFilters])

  const showFileList = !isFetching && !isError && !!files?.length
  const showError = !isFetching && isError
  const showNoResults = !files?.length && !!fileFilters
  const showNoFiles = !files?.length && !fileFilters

  return (
    <>
      <Box display="flex" marginTop={4} marginBottom={1}>
        <S.SectionHeader>Files</S.SectionHeader>
        <LabeledIconButton
          sx={{ ml: 1.5, alignSelf: "center" }}
          icon={<FilterListIcon />}
          label={"Filter"}
          onClick={() => setShowFilters(!showFilters)}
        />
      </Box>

      <Collapse in={showFilters} unmountOnExit={true}>
        <FileFilters setFileFilters={setFileFilters} />
      </Collapse>

      {showError && <Alert severity="error">Error while fetching data files.</Alert>}
      {isFetching && <Box sx={{ p: 2 }}>Loading...</Box>}
      {showFileList && <CatalogFileList datasetNameId={p.datasetNameId} files={files} />}

      {showNoResults && (
        <Alert severity="info" sx={{ mt: 1 }}>
          {"Sorry, we couldn't find any files matching your search criteria. Please adjust your filters and try again."}
        </Alert>
      )}
      {showNoFiles && (
        <Alert severity="info" sx={{ mt: 1 }}>
          {"Currently, there are no files available in this dataset."}
        </Alert>
      )}

      {showFileList && (
        <Box display="flex" alignItems={"center"}>
          <StyledButton onClick={clickNextPage} sx={{ px: 2, mr: 1 }} disabled={!data?.next}>
            Next page
          </StyledButton>
          {currentPageIndex > 0 && (
            <StyledButton onClick={resetPage} sx={{ px: 2 }}>
              Reset page
            </StyledButton>
          )}
          <Typography sx={{ ml: "auto", mr: 3 }}>{`Showing results ${currentPageIndex * 10 + 1} - ${
            currentPageIndex * 10 + files?.length
          }`}</Typography>
        </Box>
      )}
    </>
  )
}

export default React.memo(CatalogFiles)
