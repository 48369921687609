import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Box, IconButton, Typography } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../styling/theme"

type Props = {
  text: string
}

const IdCopy = (p: Props) => {
  const clickCopy = async () => {
    await navigator.clipboard.writeText(p.text)
  }

  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography
        component={"code"}
        sx={{
          fontFamily: "monospace",
          fontSize: 14,
          backgroundColor: colorPalette.deepPurpleO10,
          px: 1,
          borderRadius: 1,
          mr: 0.5,
        }}>
        {p.text}
      </Typography>
      <IconButton onClick={clickCopy} data-testid="copyIdBtn">
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Box>
  )
}

export default React.memo(IdCopy)
