import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

import { Box, IconButton, Typography } from "@mui/material"
import React from "react"
import { useDeleteObservableMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import type { ObservableDtoGet } from "../../../../../../../utils/sdk/utils/entities/sdk.observable.types"
import GeometryMinimap from "../../../../../../CatalogCollection/components/GeometryMinimap"

const BBoxObservable = (p: ObservableDtoGet<GeoJSON.Geometry>) => {
  const points = p.spec.details.value

  const [deleteObservable] = useDeleteObservableMutation()

  const clickDelete = () => {
    const confirmed = window.confirm("Do you want to delete this observable?")

    if (confirmed) void deleteObservable(p.metadata.uuid)
  }

  return (
    <>
      <Box display={"flex"} alignItems="center">
        <Typography>Bounding Box</Typography>
        <IconButton color="inherit" sx={{ ml: 1 }} onClick={clickDelete} data-testid="deleteBBoxBtn">
          <DeleteOutlineIcon fontSize="small" />
        </IconButton>
      </Box>
      <GeometryMinimap geometry={points} height={"270px"} width="270px" containerId={p.metadata.uuid + "_map"} />
    </>
  )
}

export default React.memo(BBoxObservable)
