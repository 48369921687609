import { List } from "@mui/material"
import React, { useState } from "react"
import type { RawFileInfoGet } from "../../../../../utils/api/raw.types"
import CatalogRawFile from "../../CatalogRawFile"
import FileDetailsModal from "../../FileDetailsModal"

type Props = {
  datasetNameId: string
  files: RawFileInfoGet[]
}

const CatalogFileList = (p: Props) => {
  const [fileDetailsToShow, setFileDetailsToShow] = useState<RawFileInfoGet | undefined>(undefined)

  return (
    <List>
      {p.files.map(file => (
        <CatalogRawFile
          key={file.name}
          fileInfo={file}
          datsetNameId={p.datasetNameId}
          setFileInfo={setFileDetailsToShow}
        />
      ))}

      <FileDetailsModal fileInfo={fileDetailsToShow} setFileInfo={setFileDetailsToShow} />
    </List>
  )
}

export default React.memo(CatalogFileList)
