import React, { type PropsWithChildren } from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"

type Props = {
  title?: string
}
const InteractionItem = (p: PropsWithChildren<Props>) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{p.title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ mb: 1 }}>{p.children}</AccordionDetails>
    </Accordion>
  )
}

export default React.memo(InteractionItem)
