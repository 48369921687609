import { ResourceKind, ResourceTypeOdp } from "../sdk/utils/entities/sdk.resource.types"

const splitCamleCase = (string: string): string => {
  return string.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase()
  })
}

export const createPropName = (string: string): string => {
  const splited = splitCamleCase(string).replaceAll("_", " ")
  return splited.charAt(0).toUpperCase() + splited.slice(1)
}

export const toSimpleDate = (complexDateString: string) => {
  return new Date(complexDateString).toISOString().split("T")[0]
}

export const getResourceKindForResourceType = (resourceType: ResourceTypeOdp): ResourceKind => {
  switch (resourceType) {
    case ResourceTypeOdp.Dataset:
      return ResourceKind.dataset
    case ResourceTypeOdp.Collection:
      return ResourceKind.collection
  }
}
