import { styled, type SxProps } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { colorPalette } from "../../styling/theme"

type Props = {
  href: string
  text?: string | null
  sx?: SxProps
  newTab?: boolean
  testId?: string
}

const StyledLink = styled(Link)`
  color: ${colorPalette.surfieGreen};
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const TypographyLink = (p: Props) => {
  return (
    <StyledLink to={p.href} data-testid={p.testId} sx={p.sx} target={p.newTab ? "_blank" : "_self"}>
      {p.text ?? p.href}
    </StyledLink>
  )
}

export default React.memo(TypographyLink)
