import "mapbox-gl/dist/mapbox-gl.css"
import React, { useCallback } from "react"
import MinimapWithClick from "../../../components/MinimapWithClick.tsx/MinimapWithClick"
import { setBounidingBoxFilterRA } from "../../../utils/redux/reducers/catalogReducer"
import { useAppDispatch, useAppSelector } from "../../../utils/redux/store"

type Props = {
  openRefineLocation: () => void
}

const CatalogMiniMap = (p: Props) => {
  const boundingBox = useAppSelector(state => state.catalogReducer.boundingBox)
  const dispatch = useAppDispatch()
  const clickClearLocationFilter = useCallback(() => dispatch(setBounidingBoxFilterRA()), [dispatch])

  return (
    <MinimapWithClick
      clickButton={p.openRefineLocation}
      sxProps={{ width: "100%", height: "350px", position: "relative" }}
      containerId="catalog-map"
      boundingBox={boundingBox}
      closeButtonClick={clickClearLocationFilter}
    />
  )
}

export default React.memo(CatalogMiniMap)
