import { Grid } from "@mui/material"
import React from "react"
import type { DataSet } from "../../../../utils/frontendTypes/datasets.types"
import DatasetFacets from "./DatasetFacets"
import DatasetLinks from "./DatasetLinks/DatasetLinks"
import DatasetMetadata from "./DatasetMetadata"

type Props = {
  dataset: DataSet
}

const MyDatasetMetadata = (p: Props) => {
  return (
    <>
      <Grid item>
        <DatasetMetadata dataset={p.dataset} />
      </Grid>
      <Grid item>
        <DatasetLinks dataset={p.dataset} />
      </Grid>
      <Grid item>
        <DatasetFacets dataset={p.dataset} />
      </Grid>
    </>
  )
}

export default React.memo(MyDatasetMetadata)
