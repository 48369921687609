import React from "react"
import { useLoginFn } from "../../utils/auth/userHooks"
import { StyledButton } from "../StyledButton"

const SignInButton = () => {
  const { handleLogin } = useLoginFn()

  return (
    <StyledButton color="secondary" variant="contained" onClick={handleLogin}>
      Sign in
    </StyledButton>
  )
}

export default React.memo(SignInButton)
