import type { Dataset, PropertyValue } from "schema-dts"
import { type DataSet } from "../../utils/frontendTypes/datasets.types"

export const generateDoiLink = (doi: string) => `https://doi.org/${doi}`

type GeospatialGeometry = {
  "@type": "http://www.opengis.net/ont/sf#Point"
  "geosparql:asWKT": {
    "@type": "http://www.opengis.net/ont/geosparql#wktLiteral"
    "@value": string
  }
  "geosparql:crs": {
    "@id": "http://www.opengis.net/def/crs/OGC/1.3/CRS84"
  }
}

type JsonLdOdp = Dataset & {
  "@context": {
    "@vocab": "https://schema.org/"
    geosparql: "http://www.opengis.net/ont/geosparql#"
  }
  "geosparql:hasGeometry"?: GeospatialGeometry
  additionalProperty?: PropertyValue[]
}

// TODO add doi standarisation and implement identifier field
// TODO extend implementation to support fields contained in parrent dataCollection
export const generateJSONLD = (dataset: DataSet): JsonLdOdp => ({
  "@context": {
    "@vocab": "https://schema.org/",
    geosparql: "http://www.opengis.net/ont/geosparql#",
  },
  "@type": "Dataset",
  name: dataset.name,
  description: dataset.description,
  keywords: dataset.tags ?? undefined,
})
