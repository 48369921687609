import { Button, type ButtonProps } from "@mui/material"
import React, { type PropsWithChildren } from "react"

type Props = {
  textColor: string
  backgroundColor: string
  onClick: () => void
}

const WidgetButton = (p: PropsWithChildren<Props> & ButtonProps) => {
  return (
    <Button
      onClick={p.onClick}
      sx={{
        backgroundColor: p.backgroundColor,
        color: p.textColor,
        borderRadius: "999px",
        textTransform: "none",
        px: "22px",
        py: "4px",
        fontWeight: 500,
        ":hover": {
          color: p.textColor,
          backgroundColor: p.backgroundColor,
        },
        ...p.sx,
      }}>
      {p.children}
    </Button>
  )
}

export default React.memo(WidgetButton)
