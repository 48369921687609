import { getAccessToken } from "../auth/msalConfig"
import { CatalogSDK } from "./segments/sdkCatalog"
import { DatasetsSDK } from "./segments/sdkDatasets"
import type { getTokenFnType, requestFnType } from "./utils/requests/sdk.request.types"
import { requestFn } from "./utils/requests/sdk.requests"

class JsSDK {
  requestFn: requestFnType
  catalog: CatalogSDK
  datasets: DatasetsSDK

  constructor(getTokenFn: getTokenFnType) {
    this.requestFn = async (endpoint, requestOptions?) => await requestFn(endpoint, getTokenFn, requestOptions)
    this.catalog = new CatalogSDK(this.requestFn)
    this.datasets = new DatasetsSDK(this.requestFn)
  }
}

export const jsSDK = new JsSDK(getAccessToken)
