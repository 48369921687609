import { type PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import React, { type PropsWithChildren } from "react"

type Props = {
  instance: PublicClientApplication
}

const MsalWrapper = (p: PropsWithChildren<Props>) => {
  return <MsalProvider instance={p.instance}>{p.children}</MsalProvider>
}

export default React.memo(MsalWrapper)
