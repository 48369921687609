import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useLazyGetDataPointsByDatasetNameIdQuery } from "../utils/redux/queries/dataset.queries"
import { STREAM_DOWNLOAD_SIZE_LIMIT } from "../utils/sdk/utils/requests/sdk.request.helpers"
import CustomAlert from "./CustomAlert"
import { StyledButton } from "./StyledButton"
import { StyledLoadingButton } from "./StyledLoadingButton"
import { downloadAsCsv } from "./exportCsv.helpers"

type Props = {
  open: boolean
  closeFn: () => void
  datasetId: string
}

const ExportCsvDialog = (p: Props) => {
  const [getDataPoints, { data, isFetching, error, isSuccess }] = useLazyGetDataPointsByDatasetNameIdQuery()

  const [readyToExport, setReadyToExport] = useState(false)

  const maxObjectsInputRef = React.useRef<HTMLInputElement>(null)
  const maxMbInputRef = React.useRef<HTMLInputElement>(null)

  const clickExport = async () => {
    const maxMb = maxMbInputRef.current?.value ? parseFloat(maxMbInputRef.current?.value) : undefined
    const maxObjects = maxObjectsInputRef.current?.value ? parseInt(maxObjectsInputRef.current?.value) : undefined
    void getDataPoints({
      datasetNameId: p.datasetId,
      limitedStreamOptions: {
        maxMb,
        maxObjects,
      },
    })
    setReadyToExport(true) // Set the flag to true when the button is clicked
  }

  useEffect(() => {
    if (isSuccess && !isFetching && readyToExport && data) {
      downloadAsCsv(data)
      setReadyToExport(false)
    }
  }, [data, isSuccess, readyToExport, isFetching])

  const showLoader = isFetching

  return (
    <Dialog
      open={p.open}
      onClose={p.closeFn}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { p: 3, overflowY: "visible" } }}>
      <DialogTitle id="export-to-csv-dialog" fontWeight={700}>
        {"Export to CSV"}
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center", overflowY: "hidden" }}>
        {error && <CustomAlert error={error} sx={{ mb: 2 }} />}
        <TextField
          inputRef={maxObjectsInputRef}
          size="small"
          type="number"
          label="Number of rows"
          fullWidth
          sx={{ mt: 1, mb: 2 }}
          helperText={"Leave empty for no limit."}
        />
        <TextField
          inputRef={maxMbInputRef}
          size="small"
          type="number"
          label="Data size limit (MB)"
          fullWidth
          defaultValue={"5"}
          helperText={`Enter the download data size limit in megabytes (max ${STREAM_DOWNLOAD_SIZE_LIMIT}MB). Final CSV size may differ.`}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledLoadingButton onClick={clickExport} variant={"contained"} loading={showLoader}>
          Export
        </StyledLoadingButton>
        <StyledButton onClick={p.closeFn} variant="outlined">
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ExportCsvDialog)
