import { Box, Grid, Stack, Typography } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { useParams } from "react-router-dom"
import CatAllObservables from "../../components/CatAllObservables/CatAllObservables"
import PageLoader from "../../components/PageLoader.tsx/PageLoader"
import ResourceHeader from "../../components/ResourceHeader/ResourceHeader"
import TypographyLink from "../../components/TypographyLink/TypographyLink"
import { DatasetType } from "../../utils/frontendTypes/datasets.types"
import { useGetMetadataByNameIdQuery } from "../../utils/redux/queries/catalog.queries"
import { ResourceTypeOdp } from "../../utils/sdk/utils/entities/sdk.resource.types"
import { catalogCollectionPage, useErrorRedirects } from "../Catalog/catalog.helpers"
import { S } from "../CatalogCollection/CatalogCollection.style"
import DataDetailsTable from "../CatalogCollection/components/DataDetailsTable/DataDetailsTable"
import { generateDoiLink } from "./catalogDataset.helpers"
import CatalogFiles from "./components/CatalogFiles/CatalogFiles"
import CatalogTabularData from "./components/CatalogTabularData"
import DataAccessTable from "./components/DataAccessTable"
import DataInteraction from "./components/DataInteraction/DataInteraction"
import JsonLd from "./components/JsonLd"

const CatalogDataset = () => {
  const { id: nameId } = useParams()
  const { data, isFetching, error } = useGetMetadataByNameIdQuery(nameId ?? skipToken)
  useErrorRedirects(error)
  if (isFetching) return <PageLoader />

  if (!data) return <PageLoader />

  const { citation, doi } = data.fillableMetadata ?? {}
  const doiLink = doi ? generateDoiLink(doi) : undefined

  const isTabular = data.type === DatasetType.Tabular
  const isRaw = data.type === DatasetType.Files

  const facets = data.otherFacets

  // eslint-disable-next-line multiline-ternary
  const headerLink = data.collectionId ? (
    <>
      {" in "}
      <TypographyLink
        sx={{ display: "inline" }}
        href={catalogCollectionPage(data.collectionId)}
        text={"collection"}
        testId={""}
      />
    </>
  ) : null

  return (
    <Box sx={{ pl: 4, mb: 4 }}>
      <Box sx={{ mt: 7, mb: 3 }}>
        <ResourceHeader name={data.name ?? data.nameId} type="Dataset" headerComponent={headerLink} />
        <JsonLd {...data} />
      </Box>
      <Grid container rowSpacing={2} columnSpacing={6} sx={{ mb: 3 }}>
        <Grid item md={7}>
          <Stack spacing={5}>
            {data.description && (
              <Box sx={{ maxHeight: "90vh", overflow: "auto" }}>
                <S.SectionHeader>Overview</S.SectionHeader>
                <Typography>{data.description}</Typography>
              </Box>
            )}

            <Box>
              <S.SectionHeader>Data Access</S.SectionHeader>
              <DataAccessTable {...data} />
            </Box>
            {(!!citation || !!doiLink) && (
              <Box>
                <S.SectionHeader>Citation</S.SectionHeader>
                {citation && <Typography>{citation}</Typography>}
                {doiLink && <TypographyLink href={doiLink} newTab={true} sx={{ mt: 1 }} testId={"doiLink"} />}
              </Box>
            )}
            {facets && (
              <Box>
                <S.SectionHeader>Other Metadata</S.SectionHeader>
                <DataDetailsTable data={facets} />
              </Box>
            )}
            {!!data.tags?.length && (
              <Grid item container sx={{ display: "flex", alignItems: "center" }}>
                <S.SectionHeader>Tags</S.SectionHeader>
                <Box sx={{ ml: 1 }}>
                  {data.tags.map(el => (
                    <S.Tag key={"tag_" + el}>{el}</S.Tag>
                  ))}
                </Box>
              </Grid>
            )}
          </Stack>
        </Grid>
        <Grid item md={5}>
          <Stack spacing={3}>
            <Grid item sx={{ width: "100%" }}>
              <S.SectionHeader>Use Dataset</S.SectionHeader>
              <DataInteraction dataset={data} />
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              {nameId && <CatAllObservables nameId={nameId} type={ResourceTypeOdp.Dataset} />}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      {isTabular && (
        <Grid item sx={{ width: "100%" }}>
          <CatalogTabularData dataset={data} />
        </Grid>
      )}
      {isRaw && (
        <Grid item sx={{ width: "100%" }}>
          <CatalogFiles datasetNameId={data.nameId} />
        </Grid>
      )}
    </Box>
  )
}

export default React.memo(CatalogDataset)
