import { type Modify, type Nullable, type Subset } from "../../../utils"
import { type ContactInfoDto, type LicenseDto } from "./sdk.dataset.types"
import {
  type MetadataDtoCreate,
  type ResourceDtoCreate,
  type ResourceKind,
  type ResourceMetadataDtoGet,
  type ResourceStatus,
} from "./sdk.resource.types"

export type DistributionDto = Nullable<{
  published_by?: ContactInfoDto // Publisher information
  published_date?: string // Date of first published
  website?: string // Distribution website
  license?: LicenseDto // Dataset license information
}>

export type DataCollectionSpec = Nullable<{
  distribution?: DistributionDto // """Information on how the dataset was distributed"""
  tags?: string[]
}>

export const DATA_COLLECTION_VERSION = "v1alpha1"

export type CreateDataCollectionDto = ResourceDtoCreate & {
  spec: DataCollectionSpec
  kind: ResourceKind.collection
  version: typeof DATA_COLLECTION_VERSION
}

export type DataCollectionDtoGet = Modify<
  CreateDataCollectionDto,
  { status: ResourceStatus; metadata: ResourceMetadataDtoGet }
>

export type EditCollectionDto = Subset<
  Omit<CreateDataCollectionDto, "kind" | "version" | "spec" | "metadata"> & {
    spec: DataCollectionSpec
    metadata: Omit<MetadataDtoCreate, "name">
  }
>

export type EditMetadataDataCollectionDto = Omit<EditCollectionDto, "metadata">
