import { Grid } from "@mui/material"
import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import React from "react"
import CustomAlert from "../../../components/CustomAlert"
import NoSearchResultsText from "../../../components/NoSearchResultsText"
import PageLoader from "../../../components/PageLoader.tsx/PageLoader"
import type { DataEntity } from "../../../utils/frontendTypes/dataEntity.types"
import { useAppSelector } from "../../../utils/redux/store"
import type { ResourceTypeOdp } from "../../../utils/sdk/utils/entities/sdk.resource.types"
import ResourceTile from "./ResourceTile/ResourceTile"

type Props = {
  resources?: DataEntity[]
  isFetching?: boolean
  error?: FetchBaseQueryError | SerializedError | undefined
  resourceType: ResourceTypeOdp
}

const MyResources = (p: Props) => {
  const { searchString } = useAppSelector(state => state.datasetsReducer.filters)

  if (p.isFetching) return <PageLoader />
  if (p.error) {
    return <CustomAlert error={p.error} />
  }

  return (
    <Grid container sx={{ mt: 3 }} spacing={2}>
      {!!p.resources &&
        p.resources.map(dataColl => (
          <Grid item sx={{ pr: 1, pb: 1, flexGrow: 1 }} key={dataColl.uuid} xs={12} sm={6} md={4} lg={3}>
            <ResourceTile {...dataColl} resourceType={p.resourceType} testId="dataCollectionTile" />
          </Grid>
        ))}
      {!!searchString.length && !p.resources?.length && (
        <Grid item data-testid={"noMyDataSearchResults"}>
          <NoSearchResultsText />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(MyResources)
