import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { QueryStatus } from "@reduxjs/toolkit/query"
import type { Dayjs } from "dayjs"
import React, { useCallback, useEffect } from "react"
import { StyledButton } from "../../../../../../../components/StyledButton"
import {
  convertDateStringToSeconds,
  type TimeRangeSeconds,
} from "../../../../../../../utils/frontendTypes/datasets.types"
import { useAddTimerangeObservableMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import type { ResourceTypeOdp } from "../../../../../../../utils/sdk/utils/entities/sdk.resource.types"

type Props = {
  open: boolean
  setOpen: React.Dispatch<boolean>
  nameId: string
  type: ResourceTypeOdp
}

const AddTimeRangeObsModal = ({ setOpen, open, nameId, type }: Props) => {
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null)
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null)
  const [startErrorText, setStartErrorText] = React.useState<string | null>(null)
  const [endErrorText, setEndErrorText] = React.useState<string | null>(null)

  const [addObservable, result] = useAddTimerangeObservableMutation()

  const close = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const clickAdd = () => {
    if (!startDate) setStartErrorText("This field is required")
    if (!endDate) setEndErrorText("This field is required")
    if (!startDate || !endDate) return

    const startInSeconds = convertDateStringToSeconds(startDate)
    const endInSeconds = convertDateStringToSeconds(endDate)
    const timeRange: TimeRangeSeconds = [startInSeconds, endInSeconds]

    void addObservable({ timeRange, nameId, type })
  }

  useEffect(() => {
    if (result.status === QueryStatus.fulfilled) close()
  }, [result, close])

  return (
    <Dialog open={open} onClose={close} fullWidth PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle id="add-timerange-observable" fontWeight={700}>
        {"Add Time Range"}
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item>
              <DatePicker
                label="Start Date"
                value={startDate}
                onError={newError => setStartErrorText(newError)}
                onChange={newValue => {
                  setStartErrorText(null)
                  setStartDate(newValue)
                }}
                slotProps={{
                  textField: {
                    error: !!startErrorText,
                    helperText: startErrorText,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={newValue => {
                  setEndErrorText(null)
                  setEndDate(newValue)
                }}
                slotProps={{
                  textField: {
                    error: !!endErrorText,
                    helperText: endErrorText,
                  },
                }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledButton variant="contained" onClick={clickAdd}>
          Add
        </StyledButton>
        <StyledButton onClick={close}>Cancel</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddTimeRangeObsModal)
