/* eslint-disable @typescript-eslint/no-misused-promises */
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import CustomAlert from "../../../../../../components/CustomAlert"
import { StyledButton } from "../../../../../../components/StyledButton"
import { StyledLoadingButton } from "../../../../../../components/StyledLoadingButton"
import {
  fillableMetadataDatasetProps,
  type DataSet,
  type FillableMetadataDataset,
} from "../../../../../../utils/frontendTypes/datasets.types"
import { useUpdateMetadataMutation } from "../../../../../../utils/redux/queries/dataset.queries"
import MetadataTextField from "./components/MetadataInput/components/MetadataTextFieldDataset"

type Props = {
  open: boolean
  closeFn: () => void
  dataset: DataSet
}

const EditDatasetMetadataDialog = (p: Props) => {
  const [updateDataset, { error, isSuccess, isLoading }] = useUpdateMetadataMutation()

  const form = useForm<FillableMetadataDataset>({ defaultValues: { ...p.dataset.fillableMetadata } })

  const close = useCallback(() => {
    form.reset()
    p.closeFn()
  }, [form, p])

  const saveMetadata = async () => {
    await updateDataset({ datasetId: p.dataset.uuid, metadata: form.getValues() })
  }

  useEffect(() => {
    if (isSuccess) {
      close()
    }
  }, [isSuccess, close])

  return (
    <Dialog open={p.open} onClose={close} fullWidth maxWidth="sm" PaperProps={{ sx: { p: 3 } }}>
      <form onSubmit={form.handleSubmit(saveMetadata)}>
        <DialogTitle id="alert-dialog-title" fontWeight={700}>
          {"Edit metadata"}
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          {error && <CustomAlert error={error} />}

          <Grid container direction={"column"} sx={{ my: 2 }} spacing={1}>
            {fillableMetadataDatasetProps.map((prop, i) => (
              <Grid item key={"wrap_grid" + i}>
                <MetadataTextField metadataPropName={prop} form={form} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledLoadingButton variant="contained" type="submit" loading={isLoading}>
            Save
          </StyledLoadingButton>
          <StyledButton onClick={close}>Cancel</StyledButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(EditDatasetMetadataDialog)
