/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import type { FillableMetadataDataCollection } from "../frontendTypes/dataCollection.types"
import { DatasetType, type FillableMetadataDataset } from "../frontendTypes/datasets.types"
import { toSimpleDate } from "../frontendTypes/datasetType.helpers"
import { API_NONE, createContact } from "../sdk/helpers"
import {
  type DataCollectionDtoGet,
  type EditMetadataDataCollectionDto,
} from "../sdk/utils/entities/sdk.dataCollection.types"
import {
  type EditDatasetDto,
  type EditFacetsDto,
  type EditMetadataDto,
  type Facets,
} from "../sdk/utils/entities/sdk.dataset.types"
import { StorageClass } from "../sdk/utils/entities/sdk.resource.types"
import type { Nullable } from "../utils"
import { createCollectionRefernceName } from "./requests.utils"

const createCitationDto = ({ citation, doi }: Nullable<{ citation?: string; doi?: string }>) => {
  if (!citation && !doi) {
    return API_NONE
  }
  return {
    cite_as: citation || null,
    doi: doi || null,
  }
}

export const mapMetadataToUpdateDatasetDto = (metadata?: FillableMetadataDataset, facets?: Facets): EditMetadataDto => {
  const contact =
    metadata?.contactEmail && metadata?.contactLastName
      ? createContact({
          firstName: metadata.contactFirstName,
          lastName: metadata.contactLastName,
          email: metadata.contactEmail,
        })
      : null
  const metadataPart = metadata
    ? {
        citation: createCitationDto(metadata),
        maintainer: { organisation: metadata?.maintanierOrganisation, contact },
      }
    : {}
  const facetsPart = facets ? { facets } : {}
  return { spec: { ...metadataPart, ...facetsPart } }
}

export const mapFacetsToUpdateDatasetDto = (facets: Facets): EditFacetsDto => {
  return {
    spec: {
      facets,
    },
  }
}

export const mapCollectionIdToUpdateDatasetDto = (collectionId: string): EditDatasetDto => {
  return {
    spec: {
      data_collection: createCollectionRefernceName(collectionId),
    },
  }
}

export type MainInfo = {
  name: string
  description: string
  tags: string[]
}

export const createEditMainInfoDto = ({ name, description, tags }: MainInfo): EditDatasetDto => {
  return {
    metadata: {
      display_name: name,
      description,
    },
    spec: {
      tags,
    },
  }
}

export const mapMetadataToUpdateCollectionDto = (
  metadata: FillableMetadataDataCollection
): EditMetadataDataCollectionDto => {
  const contact =
    metadata.contactEmail && metadata.contactLastName
      ? createContact({
          firstName: metadata.contactFirstName,
          lastName: metadata.contactLastName,
          email: metadata.contactEmail,
        })
      : null

  return {
    spec: {
      distribution: {
        published_by: { contact, organisation: metadata.publisherContactOrganisation },
        website: metadata.website,
        published_date: metadata.publishedDate ? new Date(metadata.publishedDate).toISOString() : undefined,
        license: {
          full_text: metadata.licenseFullText,
          href: metadata.licenseHref,
          name: metadata.licenseName,
        },
      },
    },
  }
}

export const mapDataCollectionDtoToFillableMetadata = (
  dataCollectionDto: DataCollectionDtoGet
): Partial<FillableMetadataDataCollection> => {
  return {
    licenseFullText: dataCollectionDto.spec.distribution?.license?.full_text,
    licenseHref: dataCollectionDto.spec.distribution?.license?.href,
    licenseName: dataCollectionDto.spec.distribution?.license?.name,
    publishedDate: dataCollectionDto.spec.distribution?.published_date
      ? toSimpleDate(dataCollectionDto.spec.distribution?.published_date)
      : undefined,
    publisherContactOrganisation: dataCollectionDto.spec.distribution?.published_by?.contact ?? undefined, // TODO: check this after API update of optional metadata
    contactLastName: dataCollectionDto.spec.distribution?.published_by?.organisation ?? undefined, // TODO: check this after API update of optional metadata
    website: dataCollectionDto.spec.distribution?.website ?? undefined, // TODO: check this after API update of optional metadata
  }
}

export const mapStorageClassToDatasetType = (storageClass: StorageClass): DatasetType => {
  return storageClass === StorageClass.Raw ? DatasetType.Files : DatasetType.Tabular
}
