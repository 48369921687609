import { Box, keyframes, styled } from "@mui/material"
import { colorPalette } from "../../../../../../../styling/theme"

const pulsate = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0
  }

  70% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
`

export const PulsatingRipple = styled(Box)`
  position: absolute;
  background: ${colorPalette.deepPurpleO10};
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${pulsate} 1800ms linear infinite;
  z-index: -1;
`
