import { Grid } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import TypographyLink from "../../../../../../components/TypographyLink/TypographyLink"
import { createMyCollectionPath, type DataSet } from "../../../../../../utils/frontendTypes/datasets.types"
import HeaderWithEdit from "../../../MyDatasetMetadata/components/HeaderWithEdit"
import EditParentCollectionDialog from "./components/EditParentCollectionDialog"

type Props = {
  dataset: DataSet
}

const MyDatasetParentCollection = (p: Props) => {
  const [editMode, setEditMode] = useState(false)

  const collectionId = p.dataset.collectionId

  const clickEditFn = () => {
    setEditMode(!editMode)
  }

  const currentLink = useMemo(() => {
    if (!collectionId) return null

    const collectionLink = createMyCollectionPath(collectionId)

    return <TypographyLink href={collectionLink} text={collectionId} testId={"myDataParentCollectionLink"} />
  }, [collectionId])

  const moveCollection = editMode

  const closeEdit = useCallback(() => {
    setEditMode(false)
  }, [])

  return (
    <Grid item>
      <HeaderWithEdit headerText="Collection" clickEditFn={clickEditFn} />
      {currentLink}
      {moveCollection && (
        <EditParentCollectionDialog
          datasetUuid={p.dataset.uuid}
          open={editMode}
          currentCollectionId={collectionId}
          closeFn={closeEdit}
        />
      )}
    </Grid>
  )
}

export default React.memo(MyDatasetParentCollection)
