import { LoadingButton, type LoadingButtonProps } from "@mui/lab"
import { styled } from "@mui/material"
import { colorPalette } from "../styling/theme"
import { Loader } from "./Loader/Loader"

const StyledButton = styled(LoadingButton)(() => ({
  textTransform: "none",
  fontWeight: 700,
  borderRadius: "9999px",
})) as typeof LoadingButton

export const StyledLoadingButton = (props: LoadingButtonProps) => {
  return <StyledButton loadingIndicator={<Loader size={20} color={colorPalette.deepPurple} />} {...props} />
}
