import { skipToken } from "@reduxjs/toolkit/query"
import { useSearchQueryArgs } from "../../pages/Catalog/components/CatalogResults/catalogResults.helpers"
import {
  useGetDataCollectionSearchResultsQuery,
  useGetDatasetSearchResultsQuery,
} from "../redux/queries/catalog.queries"
import { ResourceTypeOdp } from "../sdk/utils/entities/sdk.resource.types"

/**
 * @param isPublic if set to true, will only return collections with label public
 * @param isInternallyShared if set to true, will only return collections with label internally shared,
 * if set to false, will only return collections without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useCollections = ({
  isPublic,
  isInternallyShared,
}: {
  isPublic: boolean
  isInternallyShared?: boolean
}) => {
  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs(ResourceTypeOdp.Collection, isPublic)

  const shouldSkip = isQueryParamsLoading || noPossibleResults
  const collectionHookResults = useGetDataCollectionSearchResultsQuery(
    shouldSkip ? skipToken : { ...queryArgs, isPublic, isInternallyShared },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const collections = collectionHookResults.data

  const isFetching = collectionHookResults.isFetching || isQueryParamsFetching

  return { collections, isFetching, noPossibleResults, collectionHookResults }
}

/**
 * @param isPublic if set to true, will only return datasets with label public
 * @param isInternallyShared if set to true, will only return datasets with label internally shared,
 * if set to false, will only return datasets without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useDatasets = ({ isPublic, isInternallyShared }: { isPublic: boolean; isInternallyShared?: boolean }) => {
  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs(ResourceTypeOdp.Dataset, isPublic)

  const shouldSkip = isQueryParamsLoading || noPossibleResults
  const getDatasetsHookResults = useGetDatasetSearchResultsQuery(
    shouldSkip ? skipToken : { ...queryArgs, isPublic, isInternallyShared },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const { data: datasets, isFetching: isFetchingDatasets } = getDatasetsHookResults

  const isFetching = isFetchingDatasets || isQueryParamsFetching

  return { datasets, isFetching, noPossibleResults, getDatasetsHookResults }
}
