import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { colorPalette } from "../styling/theme"

export const StyledSectionHeader = styled(Typography)({
  fontWeight: "bold",
})

export const StyledMetadataKey = styled(Typography)({
  fontSize: "0.875rem",
  color: colorPalette.deepPurpleO70,
})
