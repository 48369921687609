import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const AccessAPIIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3322_1262)">
          <path
            d="M6.18359 7.5C6.48027 7.5 6.77028 7.41203 7.01695 7.24721C7.26362 7.08238 7.45588 6.84812 7.56941 6.57403C7.68294 6.29994 7.71265 5.99834 7.65477 5.70737C7.59689 5.41639 7.45403 5.14912 7.24425 4.93934C7.03448 4.72956 6.7672 4.5867 6.47623 4.52882C6.18526 4.47094 5.88366 4.50065 5.60957 4.61418C5.33548 4.72771 5.10121 4.91997 4.93639 5.16665C4.77157 5.41332 4.68359 5.70333 4.68359 6C4.68359 6.39783 4.84163 6.77936 5.12293 7.06066C5.40424 7.34197 5.78577 7.5 6.18359 7.5Z"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.9336 22.875C13.2303 22.875 13.5203 22.787 13.7669 22.6222C14.0136 22.4574 14.2059 22.2231 14.3194 21.949C14.4329 21.6749 14.4626 21.3733 14.4048 21.0824C14.3469 20.7914 14.204 20.5241 13.9943 20.3143C13.7845 20.1046 13.5172 19.9617 13.2262 19.9038C12.9353 19.8459 12.6337 19.8756 12.3596 19.9892C12.0855 20.1027 11.8512 20.295 11.6864 20.5416C11.5216 20.7883 11.4336 21.0783 11.4336 21.375C11.4336 21.7728 11.5916 22.1544 11.8729 22.4357C12.1542 22.717 12.5358 22.875 12.9336 22.875Z"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.6836 7.5C19.9803 7.5 20.2703 7.41203 20.5169 7.24721C20.7636 7.08238 20.9559 6.84812 21.0694 6.57403C21.1829 6.29994 21.2126 5.99834 21.1548 5.70737C21.0969 5.41639 20.954 5.14912 20.7443 4.93934C20.5345 4.72956 20.2672 4.5867 19.9762 4.52882C19.6853 4.47094 19.3837 4.50065 19.1096 4.61418C18.8355 4.72771 18.6012 4.91997 18.4364 5.16665C18.2716 5.41332 18.1836 5.70333 18.1836 6C18.1836 6.39783 18.3416 6.77936 18.6229 7.06066C18.9042 7.34197 19.2858 7.5 19.6836 7.5Z"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.55859 12.375V18L11.8836 20.325"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.1836 22.875C21.4803 22.875 21.7703 22.787 22.0169 22.6222C22.2636 22.4574 22.4559 22.2231 22.5694 21.949C22.6829 21.6749 22.7126 21.3733 22.6548 21.0824C22.5969 20.7914 22.454 20.5241 22.2443 20.3143C22.0345 20.1046 21.7672 19.9617 21.4762 19.9038C21.1853 19.8459 20.8837 19.8756 20.6096 19.9892C20.3355 20.1027 20.1012 20.295 19.9364 20.5416C19.7716 20.7883 19.6836 21.0783 19.6836 21.375C19.6836 21.7728 19.8416 22.1544 20.1229 22.4357C20.4042 22.717 20.7858 22.875 21.1836 22.875Z"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.9339 16.875H14.0589L12.9339 15.75V14.625H11.8089L7.79636 10.6125C6.75252 10.9805 5.61418 10.9803 4.57046 10.6119C3.52674 10.2435 2.64047 9.52917 2.05886 8.58748C1.47508 7.64547 1.2243 6.53481 1.34669 5.43335C1.46907 4.33189 1.95754 3.30337 2.73386 2.51248C3.5173 1.72314 4.55193 1.23235 5.65886 1.12498C6.75872 1.00033 7.86843 1.25108 8.80784 1.83651C9.74725 2.42194 10.4612 3.30769 10.8339 4.34998C11.2089 5.39227 11.2089 6.53269 10.8339 7.57498L12.1839 8.99998H16.6839L18.6339 7.04998"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.1837 19.8746V17.9996L17.0587 13.8746H15.9337V12.7496L14.0962 10.9121"
            stroke="#EE7E57"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3322_1262">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default React.memo(AccessAPIIcon)
