import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

import { IconButton } from "@mui/material"
import React from "react"
import TimeRangeObservable from "../../../../../../../components/TimeRangeObservable"
import { useDeleteObservableMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import type {
  ObservableDtoGet,
  TimeRangeObservableValue,
} from "../../../../../../../utils/sdk/utils/entities/sdk.observable.types"

const MyDataTimeRangeObservable = (p: ObservableDtoGet<TimeRangeObservableValue>) => {
  const [deleteObservable] = useDeleteObservableMutation()

  const clickDelete = () => {
    const confirmed = window.confirm("Do you want to delete this observable?")

    if (confirmed) void deleteObservable(p.metadata.uuid)
  }

  return (
    <TimeRangeObservable
      rangeStart={p.spec.details.value[0]}
      rangeEnd={p.spec.details.value[1]}
      button={
        <IconButton color="inherit" sx={{ ml: 1 }} onClick={clickDelete} data-testid="deleteTRBtn">
          <DeleteOutlineIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

export default React.memo(MyDataTimeRangeObservable)
