import React from "react"
import { useCollections } from "../../../utils/hooks/resourceHooks"
import { useAppSelector } from "../../../utils/redux/store"
import { ResourceTypeOdp } from "../../../utils/sdk/utils/entities/sdk.resource.types"
import MyResources from "./MyResources"

type Props = any

const MyCollections = (p: Props) => {
  const { showInternallyShared } = useAppSelector(state => state.datasetsReducer.filters)

  const { collections, isFetching, collectionHookResults } = useCollections({
    isPublic: false,
    isInternallyShared: showInternallyShared,
  })

  return (
    <MyResources
      resources={collections}
      isFetching={isFetching}
      error={collectionHookResults.error}
      resourceType={ResourceTypeOdp.Collection}
    />
  )
}

export default React.memo(MyCollections)
