import downloadjs from "downloadjs"
import { unparse } from "papaparse"
import type { DatasetRow } from "../utils/api/schema.types"

const stringifyObjectMembers = <T extends Record<string, any>>(obj: T): T => {
  const newObj: Record<string, any> = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "object" && value !== null) {
      newObj[key] = JSON.stringify(value)
    } else {
      newObj[key] = value
    }
  })

  return newObj as T
}

/**
 * Check few first rows of data to see if it contains objects
 * @param data
 * @returns true if the data contains objects, false otherwise
 */
const dataContainsObjects = (data: DatasetRow[]) => {
  for (let i = 0; i < data.length; i++) {
    if (i >= 5) {
      return false
    }
    const row = data[i]
    for (const key in row) {
      const value = row[key]
      if (typeof value === "object" && value !== null) {
        return true
      }
    }
  }
}

export const downloadAsCsv = (data: DatasetRow[]) => {
  const hasObjects = dataContainsObjects(data)
  const unparsed = hasObjects ? unparse(data.map(el => stringifyObjectMembers(el))) : unparse(data)
  const blob = new Blob([unparsed], { type: "text/csv" })
  downloadjs(blob, "data.csv", "text/csv")
}
