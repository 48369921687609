import type { Modify } from "../../../utils"

export enum ResourceKind {
  dataset = "catalog.hubocean.io/dataset",
  observable = "catalog.hubocean.io/observable",
  collection = "catalog.hubocean.io/dataCollection",
}

export enum ResourceTypeOdp {
  Dataset = "Dataset",
  Collection = "Data Collection",
}

export enum StorageClass {
  Raw = "registry.hubocean.io/storageClass/raw",
  Tabular = "registry.hubocean.io/storageClass/tabular",
}

export enum StorageController {
  Raw = "registry.hubocean.io/storageController/storage-raw-cdffs",
  Tabular = "registry.hubocean.io/storageController/storage-tabular",
}

export const RELEASED_LABEL = "catalog.hubocean.io/released"
export const INTERNALY_SHARED_LABEL = "catalog.hubocean.io/internal"
export const RESOURCE_VERSION = "v1alpha1"
export type ResourceVersionType = `v${number}${"alpha"}${number}`

export type MetadataDtoCreate = {
  name: string
  display_name?: string
  description?: string
  labels?: Record<string, boolean | string>
}

export interface ResourceDtoCreate {
  kind: ResourceKind
  version: ResourceVersionType
  metadata: MetadataDtoCreate
}

export type ResourceMetadataDtoGet = Modify<
  MetadataDtoCreate,
  { display_name: string | null; description: string | null; uuid: string; owner: string }
>

export type ResourceStatus = {
  num_updates: number // Number of time the manifest has been updated
  created_time: string // Created timestamp
  created_by: string // UUID of `PolicyConsumerDto` that created the resource
  updated_time: string // Last updated timestamp
  updated_by: string // UUID of `PolicyConsumerDto` that updated the resource
  deleted_time: string | null // Deleted timestamp - used for soft-delete
  deleted_by: string | null // UUID of `PolicyConsumerDto` that deleted the resource
}

export type CatalogFilterParams = {
  typedSearchString?: string
  filterIds?: string[]
  searchInTags?: boolean
  isPublic?: boolean
}

export type ResourceFilterParams = CatalogFilterParams & {
  isInternallyShared?: boolean
}

export type OqsOperands = "#EQUALS" | "#REGEX_LIKE" | "#WITHIN" | "#OR" | "#IS_NULL"
export type OqsCondition = Partial<Record<OqsOperands, any[]>>
