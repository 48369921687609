import { Box } from "@mui/material"
import React from "react"
import { useMiniMap } from "../../../utils/hooks/useMiniMap"

type Props = {
  geometry: GeoJSON.Geometry
}

const ModalMap = (p: Props) => {
  useMiniMap({ containerId: "data-sample-map", interactive: true, geometry: p.geometry })

  return (
    <Box sx={{ width: "100%", height: "60vh", position: "relative" }}>
      <div id="data-sample-map" style={{ width: "100%", height: "100%" }} />
    </Box>
  )
}

export default React.memo(ModalMap)
