import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { Box, Grid, Typography } from "@mui/material"
import React, { useMemo, useState } from "react"
import { useGetCollectionDatasetsAPIQuery } from "../../utils/redux/queries/dataset.queries"
import { Loader } from "../Loader/Loader"
import { StyledButton } from "../StyledButton"
import CollectionDatasetLink from "./components/CollectionDatasetLink"
import CollectionDatasetsSearch from "./components/CollectionDatasetsSearch"

type Props = {
  collectionNameId: string
  headerComponent?: React.ReactNode
  isPublic?: boolean
  isMyData?: boolean
}

const CollectionDatasets = (p: Props) => {
  const [showsAll, setShowsAll] = useState(false)
  const [searchString, setSearchString] = useState("")

  const { data: datasets, isFetching } = useGetCollectionDatasetsAPIQuery(
    {
      collectionId: p.collectionNameId,
      published: p.isPublic,
      searchString,
    },
    { refetchOnMountOrArgChange: true }
  )

  const noDatasets = !datasets?.length && !isFetching && searchString.length === 0
  const noSearchResults = !datasets?.length && !isFetching && searchString.length > 0

  const manyDatasets = !noDatasets && !isFetching && datasets && datasets.length > 7

  const showMoreIcon = showsAll ? <VisibilityOffIcon /> : <VisibilityIcon />

  const sortedDatasets = useMemo(() => {
    if (!datasets?.length) return []
    return [...datasets].sort((a, b) => {
      // if name is missing, sort by nameId
      if (!a.name || !b.name) {
        if (a.nameId < b.nameId) return -1
        if (a.nameId > b.nameId) return 1
      }

      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }, [datasets])

  return (
    <Grid item>
      <Box display="flex" alignItems={"center"} height={38} marginBottom={1}>
        {p.headerComponent}
        <CollectionDatasetsSearch setSearchString={setSearchString} />
        {manyDatasets && (
          <StyledButton sx={{ pl: 2 }} onClick={() => setShowsAll(!showsAll)} startIcon={showMoreIcon}>
            {showsAll ? "Hide" : "Show all"}
          </StyledButton>
        )}
      </Box>
      {isFetching && (
        <Box sx={{ ml: 12, mt: 2 }}>
          <Loader size={30} />
        </Box>
      )}
      {!isFetching && !!sortedDatasets?.length && (
        <Box sx={{ wordBreak: "break-word" }} maxHeight={showsAll ? "undefined" : 200} overflow={"auto"}>
          {sortedDatasets.map(el => (
            <CollectionDatasetLink {...el} isMyData={p.isMyData} key={el.nameId} />
          ))}
        </Box>
      )}
      {noDatasets && <Typography>There are no datasets in this collection yet.</Typography>}
      {noSearchResults && <Typography>Sorry, we couldn’t find any results matching your search.</Typography>}
    </Grid>
  )
}

export default React.memo(CollectionDatasets)
