import { Typography } from "@mui/material"
import React from "react"
import TypographyLink from "../../../../../components/TypographyLink/TypographyLink"
import InteractionItem from "./InteractionItem"

type Props = {
  link: string
}

const WebodvLink = (p: Props) => {
  return (
    <InteractionItem title="webODV">
      <Typography>Explore dataset using the ODV-online browser tool.</Typography>
      <TypographyLink href={p.link} newTab={true} sx={{ wordBreak: "break-all" }} testId={"odvLink"}></TypographyLink>
    </InteractionItem>
  )
}

export default React.memo(WebodvLink)
