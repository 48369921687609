import { Alert, type AlertProps } from "@mui/material"
import type { SerializedError } from "@reduxjs/toolkit"
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import React from "react"
import { getRtkQueryErrorMessage } from "../utils/utils"

type Props = AlertProps & {
  error: SerializedError | FetchBaseQueryError
}

const CustomAlert = (p: Props) => {
  return (
    <Alert severity="error" {...p}>
      {getRtkQueryErrorMessage(p.error)}
    </Alert>
  )
}

export default React.memo(CustomAlert)
