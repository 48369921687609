import { Typography } from "@mui/material"
import React from "react"
import { trimCatalogDescription } from "../catalogResults.helpers"

type Props = {
  text: string
  searchString: string | null
}

const CatalogDescription = (p: Props) => {
  const trimmed = trimCatalogDescription(p.text)

  return (
    <Typography
      sx={{
        fontSize: 14,
      }}>
      {!p.searchString
        ? trimmed
        : trimmed.split(new RegExp(`(${p.searchString})`)).map((el: string, i: number) => {
            if (el === p.searchString) return <strong key={"description tag " + el + i}>{el}</strong>
            return el
          })}
    </Typography>
  )
}

export default React.memo(CatalogDescription)
