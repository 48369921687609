import { Grid } from "@mui/material"
import { skipToken } from "@reduxjs/toolkit/query"
import React from "react"
import { type DataSet } from "../../../../../../utils/frontendTypes/datasets.types"
import { useGetSchemaByDatasetNameIdQuery } from "../../../../../../utils/redux/queries/dataset.queries"
import DatasetSampleData from "./components/DatasetSampleData/DatasetSampleData"
import DatasetSchema from "./components/DatasetSchema/DatasetSchema"
import UploadInstructions from "./components/UploadInstructions/UploadInstructions"

type Props = {
  dataset: DataSet
}

const DatasetDetailsLeftColumnTabular = (p: Props) => {
  const {
    data: schema,
    isFetching: schemaIsLoading,
    error: schemaError,
  } = useGetSchemaByDatasetNameIdQuery(p.dataset?.nameId ?? skipToken)

  return (
    <Grid container spacing={5} direction={"column"}>
      <Grid item>
        <UploadInstructions />
      </Grid>
      <Grid item>
        <DatasetSchema dataset={p.dataset} schema={schema} isLoading={schemaIsLoading} schemaError={schemaError} />
      </Grid>
      {schema && !schemaIsLoading && !schemaError && (
        <Grid item>
          <DatasetSampleData nameId={p.dataset.nameId} />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(DatasetDetailsLeftColumnTabular)
