import { S } from "./Loader.style"

export type LoaderProps = {
  color?: string
  size?: number
}

export const Loader = (props: LoaderProps) => {
  return (
    <S.Loader {...props}>
      {Array.from({ length: 5 }, (_, i) => (
        <S.LoaderCircle key={"loader_circle" + i} {...props} />
      ))}
    </S.Loader>
  )
}
