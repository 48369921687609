import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import RestoreIcon from "@mui/icons-material/Restore"
import { Box, Grow, IconButton, Typography } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../../../styling/theme"

type Props = {
  facetKey: string
  value: any
  deleteFn?: (key: string) => void
  restoreFn?: (key: string) => void
  deleted?: boolean
}

const FacetDisplay = (p: Props) => {
  const textDecoration = p.deleted ? "line-through" : "none"
  const color = p.deleted ? colorPalette.deepPurpleO60 : "inherit"

  const deleteFn = () => {
    if (!p.deleteFn) return
    p.deleteFn(p.facetKey)
  }

  const restoreFn = () => {
    if (!p.restoreFn) return
    p.restoreFn(p.facetKey)
  }

  return (
    <Grow in={true}>
      <Box display={"flex"} alignItems={"center"} sx={{ position: "relative" }}>
        <Typography
          sx={{
            textDecoration,
            color,
            py: 1,
            maxWidth: "35%",
            minWidth: "35%",
            overflow: "hidden",
            wordBreak: "break-all",
          }}>
          {p.facetKey}
        </Typography>
        <Typography
          variant="subtitle2"
          fontStyle={"italic"}
          sx={{ ml: 1, textDecoration, color, wordBreak: "break-all", overflow: "hidden", pr: 1 }}>
          {JSON.stringify(p.value)}
        </Typography>
        {p.deleteFn && !p.deleted && (
          <IconButton sx={{ ml: "auto" }} onClick={deleteFn} data-testid="removeFieldBtn">
            <DeleteOutlineIcon />
          </IconButton>
        )}
        {p.restoreFn && p.deleted && (
          <IconButton sx={{ ml: "auto" }} onClick={restoreFn} data-testid="removeFieldBtn">
            <RestoreIcon />
          </IconButton>
        )}
      </Box>
    </Grow>
  )
}

export default React.memo(FacetDisplay)
