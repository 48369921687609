import React from "react"
import { useNavigate } from "react-router-dom"
import { StyledButton } from "../../../../../components/StyledButton"
import { ResourceTypeOdp } from "../../../../../utils/sdk/utils/entities/sdk.resource.types"
import { catalogCollectionPage, catalogDatasetPage } from "../../../../Catalog/catalog.helpers"

type Props = {
  nameId: string
  resourceType: ResourceTypeOdp
}

const CatalogPreviewButton = (p: Props) => {
  const nav = useNavigate()
  const link =
    p.resourceType === ResourceTypeOdp.Dataset ? catalogDatasetPage(p.nameId) : catalogCollectionPage(p.nameId)
  const onClick = () => nav(link)

  return (
    <StyledButton variant="outlined" onClick={onClick}>
      Preview in Data Catalog
    </StyledButton>
  )
}

export default React.memo(CatalogPreviewButton)
