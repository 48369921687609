import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { Box } from "@mui/material"
import SignInButton from "../../../SignInButton/SignInButton"
import NavUserLoggedIn from "./components/NavUserLoggedIn"

const NavUser = () => {
  return (
    <Box sx={{ flexGrow: 0 }}>
      <UnauthenticatedTemplate>
        <Box>
          <SignInButton />
        </Box>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <NavUserLoggedIn />
      </AuthenticatedTemplate>
    </Box>
  )
}

export default NavUser
