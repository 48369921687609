import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const DataCollectionIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.9375 14.25H2.75V13.125H8.9375V14.25Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M8.9375 6.75H2.75V5.625H8.9375V6.75Z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.23438 0.9375H3.26562V20.625H8.9375V21.75H2.23438V0.9375Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.42188 3.75H19.7656V8.625H8.42188V3.75ZM9.45312 4.875V7.5H18.7344V4.875H9.45312Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.42188 18.75H19.7656V23.625H8.42188V18.75ZM9.45312 19.875V22.5H18.7344V19.875H9.45312Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.42188 11.25H19.7656V16.125H8.42188V11.25ZM9.45312 12.375V15H18.7344V12.375H9.45312Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}

export default React.memo(DataCollectionIcon)
