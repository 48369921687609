import { Box } from "@mui/material"
import React from "react"
import { type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import ApiIds from "./components/ApiIds"
import WebodvLink from "./components/WebodvLink"
import WorkspaceLink from "./components/WorkspaceLink"
import { generateDataInteractionProps } from "./dataInteraction.helpers"

type Props = {
  dataset: DataSet
}

const DataInteraction = (p: Props) => {
  const { workspaceLink, webodvLink, apiIds } = generateDataInteractionProps(p.dataset)

  return (
    <Box>
      {workspaceLink && <WorkspaceLink link={workspaceLink} />}
      <ApiIds nameId={apiIds.nameId} uuid={apiIds.uuid} />
      {webodvLink && <WebodvLink link={webodvLink} />}
    </Box>
  )
}

export default React.memo(DataInteraction)
