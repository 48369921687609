import { Box } from "@mui/material"
import React from "react"
import { colorPalette } from "../../styling/theme"
import { Loader, type LoaderProps } from "../Loader/Loader"

const PageLoader = (p: LoaderProps) => {
  const color = p.color ?? colorPalette.deepPurpleO50
  return (
    <Box display={"flex"} justifyContent={"center"} sx={{ pt: 6 }}>
      <Loader {...p} color={color} />
    </Box>
  )
}

export default React.memo(PageLoader)
