import { Grid } from "@mui/material"
import React, { useState } from "react"
import {
  fillableMetadataCollectionProps,
  type DataCollection,
} from "../../../../utils/frontendTypes/dataCollection.types"
import { createPropName } from "../../../../utils/frontendTypes/datasetType.helpers"
import EditCollectionMetadataDialog from "../../../MyDataset/components/MyDatasetMetadata/components/EditMetadataDialog/EditCollectionMetadataDialog"
import HeaderWithEdit from "../../../MyDataset/components/MyDatasetMetadata/components/HeaderWithEdit"
import CollectionMetadataDisplay from "./components/CollectionMetadataDisplay"

type Props = {
  dataCollection: DataCollection
}

const CollectionMetadata = (p: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openEditMetadataDialog = () => {
    setDialogOpen(true)
  }
  const closeEditMetadataDialog = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <HeaderWithEdit
            headerText="Metadata"
            clickEditFn={openEditMetadataDialog}
            testId="editCollectionMetadataBtn"
          />
        </Grid>
        <Grid item>
          {p.dataCollection.metadata && (
            <CollectionMetadataDisplay
              metadata={p.dataCollection.metadata}
              fillable={fillableMetadataCollectionProps}
              keyTransformFn={createPropName}
            />
          )}
        </Grid>
      </Grid>
      <EditCollectionMetadataDialog
        closeFn={closeEditMetadataDialog}
        dataCollection={p.dataCollection}
        open={dialogOpen}
      />
    </>
  )
}

export default React.memo(CollectionMetadata)
