import CloseIcon from "@mui/icons-material/Close"
import { TextField } from "@mui/material"
import { debounce } from "lodash"
import React, { useMemo, useState } from "react"
import type { DataEntity } from "../../../../../../../../utils/frontendTypes/dataEntity.types"
import { useGetDataCollectionSearchResultsQuery } from "../../../../../../../../utils/redux/queries/catalog.queries"
import { S } from "./AutocompleteSearch.style"
import CollectionOption from "./components/CollectionOption"

type Props = {
  currentCollectionId?: string | null
  onSelect: (collectionId?: string) => void
}

const AutocompleteSelectCollection = (props: Props) => {
  const [searchString, setSearchString] = useState("")
  const { data, isFetching } = useGetDataCollectionSearchResultsQuery(
    { searchString, isPublic: false, filterIds: null },
    { refetchOnMountOrArgChange: true }
  )

  const handleSearchInputChange = async (searchString: string) => {
    setSearchString(searchString)
  }

  const debouncedHandleSearchInputChange = debounce(async searchString => {
    await handleSearchInputChange(searchString)
  }, 300)

  const options = useMemo(() => {
    if (data) return data.filter(dataEntity => dataEntity.nameId !== props.currentCollectionId)
    else return []
  }, [data, props.currentCollectionId])

  return (
    <S.Autocomplete<DataEntity, undefined, undefined, true>
      sx={{ display: "inline" }}
      loading={isFetching}
      loadingText="Loading collections..."
      size="small"
      id="dataCatalogAutoComplete"
      onInputChange={(_, val, reason) => {
        if (reason === "clear") {
          // setResults([])
          return
        }
        void debouncedHandleSearchInputChange(val)
      }}
      onChange={(e, val, reason) => {
        if (reason === "selectOption" && val) {
          const option = val as DataEntity
          props.onSelect(option.nameId)
        }
        if (reason === "clear") {
          setSearchString("")
          props.onSelect()
        }
      }}
      options={options}
      getOptionLabel={(option: any) => option.nameId ?? ""}
      renderOption={(props, option: DataEntity) => (
        <li {...props}>
          <CollectionOption {...option} />
        </li>
      )}
      clearIcon={
        <S.ClearIconWrapper>
          <CloseIcon />
        </S.ClearIconWrapper>
      }
      disablePortal
      renderInput={params => (
        <TextField
          placeholder="Enter collection qualified name"
          {...params}
          InputProps={{
            ...params.InputProps,
            onKeyUp: e => {
              const ev = e as React.KeyboardEvent
              if (ev.key === "Enter" && e.currentTarget.value === "") {
                props.onSelect("")
              }
            },
            // @ts-expect-error - mui data-testid not supported in input props yet
            "data-testid": "catalogSearchInput",
          }}
        />
      )}
      filterOptions={x => x} // overwrite mui built in filtering
    />
  )
}

export default AutocompleteSelectCollection
