import React, { useCallback, useMemo, useState } from "react"
import MinimapWithClick from "../../../../../../../components/MinimapWithClick.tsx/MinimapWithClick"
import RefineLocationMapModal from "../../../../../../../components/RefineLocationMapModal/RefineLocationMapModal"
import type { BoundingMapboxCoords } from "../../../../../../../utils/frontendTypes/datasets.types"

type Props = {
  setGeolocationFilter: (coords: BoundingMapboxCoords | null) => void
  geoLocationFilter?: BoundingMapboxCoords
}

const RawGeolocationFilter = (p: Props) => {
  const [refineModalOpen, setRefineModalOpen] = useState(false)
  const clickOpenRefineModal = useCallback(() => setRefineModalOpen(true), [])
  const clickCloseRefineModal = useCallback(() => setRefineModalOpen(false), [])
  const actionButtonProps = useMemo(
    () => ({
      onClick: (coords: BoundingMapboxCoords | null) => p.setGeolocationFilter(coords),
      text: "Set filter",
    }),
    [p]
  )

  return (
    <>
      <MinimapWithClick
        clickButton={clickOpenRefineModal}
        sxProps={{ width: { sm: "100%" }, height: "210px", position: "relative" }}
        containerId="filter-map-my-data"
        closeButtonClick={() => actionButtonProps.onClick(null)}
        boundingBox={p.geoLocationFilter}
      />

      <RefineLocationMapModal
        open={refineModalOpen}
        closeFn={clickCloseRefineModal}
        actionButtonProps={actionButtonProps}
      />
    </>
  )
}

export default React.memo(RawGeolocationFilter)
