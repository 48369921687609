import { Typography } from "@mui/material"
import React from "react"
import TypographyLink from "../../../../../components/TypographyLink/TypographyLink"
import InteractionItem from "./InteractionItem"

type Props = {
  link: string
}

const WorkspaceLink = (p: Props) => {
  return (
    <InteractionItem title="Workspace">
      <Typography sx={{ mb: 1 }}>Connect to a Jupyter Hub environment where you can directly access data:</Typography>
      <TypographyLink
        href={p.link}
        newTab={true}
        sx={{ wordBreak: "break-all" }}
        testId={"workspaceLink"}></TypographyLink>
    </InteractionItem>
  )
}

export default React.memo(WorkspaceLink)
