import { Box, TableCell, Tooltip } from "@mui/material"
import React from "react"
import { jStringify } from "../../../utils/api/jsonInfinity.mjs"
import WktVisualizeButton from "../../WKTMap/WktVisualizeButton/WktVisualizeButton"

type Props = {
  value: unknown
  column: string
}

const DataCell = (p: Props) => {
  const valueStringified = p.value instanceof Object ? jStringify(p.value) : p.value?.toString()
  const valueTruncated = valueStringified?.length > 50 ? valueStringified.substring(0, 50) + "..." : valueStringified
  const possiblyGeometry = p.column === "geometry"
  return (
    <TableCell>
      <Box display={"flex"} alignItems={"center"}>
        <Tooltip
          title={
            <Box maxHeight={100} overflow={"auto"}>
              {valueStringified}
            </Box>
          }>
          <Box
            sx={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>
            {valueTruncated}
          </Box>
        </Tooltip>
        <Box>{possiblyGeometry && <WktVisualizeButton wkt={p.value as any} />}</Box>
      </Box>
    </TableCell>
  )
}

export default React.memo(DataCell)
