import { type PublicClientApplication } from "@azure/msal-browser"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { Container } from "@mui/system"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Navbar from "./components/Navbar/Navbar"
import PrivateRoute from "./components/PrivateRoute"
import { paths } from "./components/navigation.helpers"
import Catalog from "./pages/Catalog/Catalog"
import CatalogCollection from "./pages/CatalogCollection/CatalogCollection"
import CatalogDataset from "./pages/CatalogDataset/CatalogDataset"
import Dashboard from "./pages/Dashboard/Dashboard"
import ErrorPage from "./pages/ErrorPage/ErrorPage"
import MyCollection from "./pages/MyCollection/MyCollection"
import MyData from "./pages/MyData/MyData"
import DatasetDetails from "./pages/MyDataset/MyDataset"
import Projects from "./pages/Projects/Projects"
import { theme } from "./styling/theme"
import MsalWrapper from "./utils/auth/MsalWrapper"

type Props = {
  msalInstance: PublicClientApplication
}

const App = (p: Props) => {
  const mainPage = <Dashboard />

  return (
    <MsalWrapper instance={p.msalInstance}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Navbar />
            <Container maxWidth="xl" sx={{ marginTop: "40px" }}>
              <Routes>
                <Route path="/" element={mainPage} />
                <Route path={paths.Catalog} element={<Catalog />} />
                <Route element={<PrivateRoute />}>
                  <Route path={`${paths.Datasets}/:datasetID`} element={<DatasetDetails />} />
                  <Route path={paths["My Data"]} element={<MyData />} />
                  <Route path={`${paths["My Data"]}/:collectionID`} element={<MyCollection />} />
                  <Route path={paths.CatalogCollection} element={<CatalogCollection />} />
                  <Route path={paths.CatalogDataset} element={<CatalogDataset />} />
                </Route>
                <Route path={paths.Projects} element={<Projects />} />
                <Route
                  path="401"
                  element={<ErrorPage code={401} text="You are not authorized to access this page." />}
                />
                <Route path="*" element={<ErrorPage code={404} text="The page you're looking for doesn't exist." />} />
              </Routes>
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </MsalWrapper>
  )
}

export default App
