import React, { useCallback } from "react"
import AutocompleteSearch from "../../../../../components/AutocompleteSearch/AutocompleteSearch"
import { setMyDataSearchStringRA } from "../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../utils/redux/store"

const AutocompleteSearchMyData = () => {
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    (searchString: string) => {
      dispatch(setMyDataSearchStringRA(searchString))
    },
    [dispatch]
  )

  return <AutocompleteSearch onSubmit={onSubmit} />
}

export default React.memo(AutocompleteSearchMyData)
