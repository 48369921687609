import ArticleIcon from "@mui/icons-material/Article"
import DownloadIcon from "@mui/icons-material/Download"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material"
import React from "react"
import { type RawFileInfoGet } from "../../../utils/api/raw.types"
import { formatBytes } from "../../../utils/utils"
import { downloadRawFile } from "../../MyDataset/components/MyDatasetContent/components/DatasetDetailsLeftColumnRaw/components/rawFile.helpers"

type Props = {
  fileInfo: RawFileInfoGet
  datsetNameId: string
  setFileInfo: React.Dispatch<RawFileInfoGet | undefined>
}

const DisplayRawFl = (p: Props) => {
  const clickDownload = async (e: React.MouseEvent) => {
    e.stopPropagation()
    await downloadRawFile(p.datsetNameId, p.fileInfo.name)
  }

  const clickShowFildeDetails = () => {
    p.setFileInfo(p.fileInfo)
  }

  return (
    <ListItemButton onClick={clickShowFildeDetails} disableRipple>
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText primary={p.fileInfo.name} />
      {p.fileInfo.size_bytes && (
        <Typography sx={{ fontSize: 12, textAlign: "center", mr: 1 }}>
          size: {formatBytes(p.fileInfo.size_bytes)}
        </Typography>
      )}
      <IconButton onClick={clickDownload} data-testid="downloadFileBtn">
        <DownloadIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <IconButton onClick={clickShowFildeDetails} data-testid="showFileDetailsBtn">
        <MoreHorizIcon sx={{ fontSize: 20 }} />
      </IconButton>
    </ListItemButton>
  )
}

export default React.memo(DisplayRawFl)
