import { AuthenticatedTemplate } from "@azure/msal-react"
import React from "react"
import { useNavigate } from "react-router-dom"
import UploadDataIcon from "../../../../assets/icons/UploadDataIcon"
import { paths } from "../../../../components/navigation.helpers"
import { colorPalette } from "../../../../styling/theme"
import { DashbordItem } from "../components/DashbordItem"
import WidgetButton from "../components/WidgetButton"
import WidgetContent from "../components/WidgetContent"
import WidgetHeader from "../components/WidgetHeader"
import WidgetDataCollections from "./components/WidgetDataCollections"

const UploadDataWidget = () => {
  const navigate = useNavigate()

  const addDatasetClick = () => {
    navigate(paths["My Data"])
  }

  return (
    <DashbordItem variant="outlined">
      <WidgetHeader
        title="Upload Data"
        titleIcon={<UploadDataIcon sx={{ mr: 1, color: colorPalette.luminousPurple }} />}
      />
      <WidgetContent description="Upload your datasets and create collections, then access via API or open them for analysis in a worksapce." />
      <AuthenticatedTemplate>
        <WidgetDataCollections />
      </AuthenticatedTemplate>
      <WidgetButton
        backgroundColor={colorPalette.luminousGreen}
        textColor={colorPalette.deepPurple}
        onClick={addDatasetClick}>
        Go to My Data
      </WidgetButton>
    </DashbordItem>
  )
}

export default React.memo(UploadDataWidget)
