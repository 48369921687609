import MenuIcon from "@mui/icons-material/Menu"
import { Box, IconButton, Menu } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../styling/theme"
import { useOdpNavigate, type ExternalPageNames, type PageNames } from "../../../navigation.helpers"
import HamMenuItem from "./components/HamMenuItem"

export type NavProps = {
  pages: PageNames[]
  externalPages: ExternalPageNames[]
}

const HamburgerNavbar = (p: NavProps) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)

  const { odpExternalNav, odpInternalNav } = useOdpNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const nav = (page: PageNames) => {
    setAnchorElNav(null)
    odpInternalNav(page)
  }

  const externalNav = (page: ExternalPageNames) => {
    setAnchorElNav(null)
    odpExternalNav(page)
  }

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{ color: colorPalette.white, "&:hover": { backgroundColor: colorPalette.deepPurpleO70 } }}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}>
        {p.pages.map(page => (
          <HamMenuItem key={page} pageName={page} onClick={() => nav(page)} />
        ))}
        {p.externalPages.map(page => (
          <HamMenuItem key={page} pageName={page} onClick={() => externalNav(page)} isExternal />
        ))}
      </Menu>
    </Box>
  )
}

export default React.memo(HamburgerNavbar)
