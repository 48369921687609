/* eslint-disable multiline-ternary */
import PeopleIcon from "@mui/icons-material/People"
import { Card, CardContent, Link, Stack, Tooltip, Typography } from "@mui/material"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import DataCollectionIcon from "../../../../assets/icons/DataCollectionIcon"
import DatasetIcon from "../../../../assets/icons/DatasetIcon"
import { paths } from "../../../../components/navigation.helpers"
import { colorPalette } from "../../../../styling/theme"
import type { DataEntity } from "../../../../utils/frontendTypes/dataEntity.types"
import { shortenDescription } from "../../../../utils/frontendTypes/datasets.types"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"

type Props = DataEntity & {
  resourceType: ResourceTypeOdp
  testId?: string
}

const ResourceTile = (p: Props) => {
  const formattedDescription = shortenDescription(p.description)

  const pathbase = p.resourceType === ResourceTypeOdp.Dataset ? paths.Datasets : paths["My Data"]
  const icon =
    p.resourceType === ResourceTypeOdp.Dataset ? (
      <DatasetIcon sx={{ color: colorPalette.luminousPurple }} />
    ) : (
      <DataCollectionIcon sx={{ color: colorPalette.surfieGreen }} />
    )

  const borderColor = p.resourceType === ResourceTypeOdp.Dataset ? colorPalette.luminousPurpleO30 : undefined
  const hoverStyle =
    p.resourceType === ResourceTypeOdp.Dataset
      ? { backgroundColor: colorPalette.luminousPurpleO10, borderColor: colorPalette.luminousPurple }
      : { backgroundColor: colorPalette.luminousGreenO10, borderColor: colorPalette.luminousGreen }

  return (
    <Link component={RouterLink} to={`${pathbase}/${p.nameId}`} sx={{ textDecoration: "none" }}>
      <Card
        elevation={0}
        variant="outlined"
        data-testid={p.testId}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.96)",
          height: "200px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "4px",
          transitionProperty: "background-color, border-color",
          transitionDuration: "400ms",
          transitionTimingFunction: "ease-in-out",
          borderColor,
          "&:hover": hoverStyle,
        }}>
        <CardContent sx={{ position: "relative" }}>
          {p.isInternalyShared && (
            <Tooltip title={"Internally shared"} placement="top">
              <PeopleIcon sx={{ position: "absolute", top: 12, right: 12, color: colorPalette.deepPurpleO50 }} />
            </Tooltip>
          )}
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {p.resourceType}
          </Typography>
          <Stack direction="row" alignItems={"cente"} gap={1}>
            {icon}
            <Typography
              variant="h5"
              component="h2"
              sx={{ textWrap: "nowrap", mb: 0.25 }}
              textOverflow={"ellipsis"}
              overflow={"hidden"}>
              {p.name}
            </Typography>
          </Stack>
          <Typography variant="body2">{formattedDescription}</Typography>
        </CardContent>
      </Card>
    </Link>
  )
}

export default React.memo(ResourceTile)
