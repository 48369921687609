import { TextField } from "@mui/material"
import React from "react"
import { Controller } from "react-hook-form"
import {
  fillableMetadataCollectionPropLabelMap,
  type FillableMetadataDataCollection,
} from "../../../../../../../../../utils/frontendTypes/dataCollection.types"
import { metadataFormValidationCollection, type FormRuleType } from "../../../editMetadataDialog.helpers"
import { type MetadataInputProps } from "../MetadataInput"

const MetadataTextFieldCollection = (p: MetadataInputProps<FillableMetadataDataCollection>) => {
  return (
    <Controller
      name={p.metadataPropName}
      control={p.form.control}
      rules={metadataFormValidationCollection[p.metadataPropName] as (FormRuleType<FillableMetadataDataCollection, keyof FillableMetadataDataCollection> | undefined)}
      render={({ field, formState }) => {
        return (
          <TextField
            fullWidth
            label={fillableMetadataCollectionPropLabelMap[p.metadataPropName]}
            size="small"
            {...field}
            value={field.value ?? ""}
            error={!!formState.errors[p.metadataPropName]}
            helperText={formState.errors[p.metadataPropName]?.message?.toString()}
          />
        )
      }}
    />
  )
}

export default React.memo(MetadataTextFieldCollection)
