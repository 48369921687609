import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material"
import React from "react"
import { type AuthorizedGroups } from "../project.helpers"

export type ProjectCardProps = {
  imgSrc: string
  title: string
  description: string
  url: string
  authorizedGroups?: AuthorizedGroups[]
}

const ProjectCard = (p: ProjectCardProps) => {
  return (
    <CardActionArea
      href={p.url}
      target="_blank"
      sx={{
        height: "100%",
        transition: "300ms ease-in-out",
        "&:hover": {
          transform: "scale(1.06)",
        },
      }}>
      <Card sx={{ height: "100%", maxWidth: 345 }} elevation={3}>
        <CardMedia sx={{ height: 180 }} image={p.imgSrc} title={p.title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {p.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {p.description}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default React.memo(ProjectCard)
