import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { colorPalette } from "../../styling/theme"
import { type LoaderProps } from "./Loader"

const Loader = styled.div<LoaderProps>`
  width: ${props => props.size ?? 100}px;
  height: ${props => props.size ?? 100}px;
  position: relative;
`

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoaderCircle = styled.div<LoaderProps>`
  width: ${props => props.size ?? 100}px;
  height: ${props => props.size ?? 100}px;
  border-radius: 100%;
  border: 1px solid ${props => props.color ?? colorPalette.deepPurpleO50};
  animation: ${rotation} 1s linear infinite;
  transition: 2s;
  border-bottom: none;
  border-right: none;
  left: 0;
  top: 0;
  position: absolute;

  &:nth-of-type(2) {
    width: calc(${props => props.size ?? 100}px * 0.9);
    height: calc(${props => props.size ?? 100}px * 0.9);
    margin: calc(${props => props.size ?? 100}px * 0.05);
    animation-delay: 0.2s;
  }

  &:nth-of-type(3) {
    margin: calc(${props => props.size ?? 100}px * 0.1);
    width: calc(${props => props.size ?? 100}px * 0.8);
    height: calc(${props => props.size ?? 100}px * 0.8);
    animation-delay: 0.4s;
  }

  &:nth-of-type(4) {
    margin: calc(${props => props.size ?? 100}px * 0.15);
    width: calc(${props => props.size ?? 100}px * 0.7);
    height: calc(${props => props.size ?? 100}px * 0.7);
    animation-delay: 0.6s;
  }

  &:nth-of-type(5) {
    margin: calc(${props => props.size ?? 100}px * 0.2);
    width: calc(${props => props.size ?? 100}px * 0.6);
    height: calc(${props => props.size ?? 100}px * 0.6);
    animation-delay: 0.8s;
  }
`

export const S = {
  Loader,
  LoaderCircle,
}
