import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import React from "react"
import { useForm } from "react-hook-form"
import { StyledButton } from "../../../../../../components/StyledButton"
import { useUpdateMetadataMutation } from "../../../../../../utils/redux/queries/dataset.queries"
import type { Facets } from "../../../../../../utils/sdk/utils/entities/sdk.dataset.types"
import {
  SpecialFacetKeys,
  facetApiNames,
  mapToFormKeys,
  type SpecialFacetFormKey,
  type SpecialFacetsApi,
  type SpecialFacetsType,
} from "../../../../../CatalogDataset/components/DataInteraction/dataInteraction.helpers"
import InteractionLinkInput from "./components/InteractionLinkInput"

type Props = {
  open: boolean
  closeFn: () => void
  datasetId: string
  currentSpecialFacets?: Partial<SpecialFacetsApi> | null
  currentOtherFacets?: Facets | null
}

const EditLinksDialog = (p: Props) => {
  const [updateDataset] = useUpdateMetadataMutation()
  const defaultVals = p.currentSpecialFacets ? mapToFormKeys(p.currentSpecialFacets) : {}

  const form = useForm<SpecialFacetsType>({ defaultValues: { ...defaultVals } })

  const close = () => {
    p.closeFn()
  }

  const saveFacets = async () => {
    const apiNamedFacets = Object.fromEntries(
      Object.entries(form.getValues()).map(([key, value]) => [facetApiNames[key as SpecialFacetFormKey], value])
    )
    const updatedFacets = { ...p.currentOtherFacets, ...apiNamedFacets }
    await updateDataset({ datasetId: p.datasetId, facets: updatedFacets })
  }

  return (
    <Dialog open={p.open} onClose={close} fullWidth maxWidth="sm" PaperProps={{ sx: { p: 3 } }}>
      <form onSubmit={form.handleSubmit(saveFacets)}>
        <DialogTitle id="facets-dialog" fontWeight={700}>
          {"Edit links"}
        </DialogTitle>
        <DialogContent sx={{ justifyContent: "center" }}>
          <Grid container direction={"column"} sx={{ my: 2 }} spacing={1}>
            {SpecialFacetKeys.map(key => (
              <Grid item key={key}>
                <InteractionLinkInput facetKey={key} form={form} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledButton variant="contained" type="submit">
            Save
          </StyledButton>
          <StyledButton onClick={close}>Cancel</StyledButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default React.memo(EditLinksDialog)
