import { Box, Typography } from "@mui/material"
import { Link, useSearchParams } from "react-router-dom"
import { colorPalette } from "../../../../styling/theme"
import { type DataEntity } from "../../../../utils/frontendTypes/dataEntity.types"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import { catalogCollectionPage, catalogDatasetPage } from "../../catalog.helpers"
import CatalogDescription from "./components/CatalogDescription"

type Props = {
  dataSource: DataEntity
  resultType: ResourceTypeOdp
}

function CatalogResult({ dataSource, resultType }: Props) {
  const dataProductResult = dataSource
  const [searchParams] = useSearchParams()
  const searchString = searchParams.get("q")
  const { name, description: databaseDescription, tags, nameId } = dataProductResult

  const link = resultType === ResourceTypeOdp.Dataset ? catalogDatasetPage(nameId) : catalogCollectionPage(nameId)

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",

        alignItems: "center",
        WebkitFlexDirection: "row",

        "&:hover": {
          backgroundColor: colorPalette.deepPurpleO03,
          transition: "background-color 100ms",
        },
      }}>
      <Box>
        <Typography
          component={Link}
          to={link}
          sx={{
            fontSize: 20,
            fontWeight: "bold",
            color: "inherit",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          data-testid="resourceLink">
          {name}
        </Typography>

        <CatalogDescription text={databaseDescription} searchString={searchString} />
        <Typography sx={{ fontSize: 14 }}>
          {!!tags?.length &&
            tags.map((el: string, i: number) => {
              const tagToDisplay = i === tags.length - 1 ? el + "." : el + ", "
              if (el === searchString) {
                return (
                  <strong key={"desc_tag" + el + i}>
                    <em>{tagToDisplay}</em>
                  </strong>
                )
              }
              return <em key={"desc_tag" + el + i}>{tagToDisplay}</em>
            })}
        </Typography>
      </Box>
    </Box>
  )
}

export default CatalogResult
