import { Box, type SxProps } from "@mui/material"
import KeyValueDisplay from "./KeyValueDisplay"
import { StyledMetadataKey } from "./StyledTypography"

type MetadataBlockDisplayProps<T extends Record<string, unknown>> = {
  title: string
  metadata: T
  keyNamesMap?: Record<keyof T, string>
  sx?: SxProps
}

const MetadataBlockDisplay = <T extends Record<string, unknown>>(p: MetadataBlockDisplayProps<T>) => {
  return (
    <Box sx={p.sx}>
      <StyledMetadataKey>{p.title}</StyledMetadataKey>
      {Object.entries(p.metadata).map(([key, value]) => (
        <KeyValueDisplay
          key={key}
          objectKey={p.keyNamesMap ? p.keyNamesMap[key] : key}
          value={value}
          sx={{ ml: 1 }}
          valueSx={{ ml: -1 }}
        />
      ))}
    </Box>
  )
}

export default MetadataBlockDisplay
