import { Box } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import BrowseCatalogIcon from "../../../assets/icons/BrowseCatalogIcon"
import image from "../../../assets/img/browseCatalog.png"
import { paths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import DashbordItem from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const CatalogWidget = () => {
  const nav = useNavigate()

  const onClick = () => {
    nav(paths.Catalog)
  }

  return (
    <DashbordItem sx={{ background: colorPalette.deepPurple, color: colorPalette.white }} elevation={0}>
      <WidgetHeader
        color={colorPalette.luminousGreen}
        title="Browse Catalog"
        titleIcon={<BrowseCatalogIcon sx={{ mr: 1 }} />}
      />
      <WidgetContent description="Browse our catalog of open source and user-contributed ocean data." />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousGreen} textColor={colorPalette.deepPurple}>
        Browse Catalog
      </WidgetButton>
      <Box width="calc(100% + 64px)" marginLeft={"-32px"} marginBottom="-38px" marginTop={"10px"}>
        <img src={image} width="100%" alt="" />
      </Box>
    </DashbordItem>
  )
}

export default React.memo(CatalogWidget)
