import { type ApiError, type ApiErrorMember, type ApiResponse, type QueryResponse } from "../../api/response.types"
import { ResourceKind } from "../../sdk/utils/entities/sdk.resource.types"

type mapper<In, Out> = (input: In) => Out

export const createResponse = async <APItype extends object, AppType>(
  callback: () => Promise<ApiResponse<APItype>>,
  mapper: mapper<APItype, AppType>
): Promise<QueryResponse<AppType>> => {
  try {
    const result = await callback()
    if (result.error) {
      const resultTyped = result as { error: ApiError }
      return { error: { data: resultTyped.error.text, status: resultTyped.error.statusCode } }
    }
    if (result.errors) {
      return {
        error: {
          data: createValidationErrorMessagerFromList(result.errors.list),
          status: result.errors.statusCode,
        },
      }
    }
    if (result.data) {
      const resultTyped = result as { data: APItype }
      return { data: mapper(resultTyped.data) }
    }
  } catch (e) {
    return { error: e as any }
  }
  return { error: { data: "Something went wrong", status: 500 } }
}

export const getIdFromObservableRef = (ref: string): string => {
  if (ref.startsWith(ResourceKind.dataset)) return ref.replace(ResourceKind.dataset + "/", "")
  if (ref.startsWith(ResourceKind.collection)) return ref.replace(ResourceKind.collection + "/", "")
  return ref
}

export const createValidationErrorMessagerFromList = (errors: ApiErrorMember[]): string => {
  return errors.map(el => `${el.location}: ${el.message}`).join(", ")
}
