import { type FieldPath, type FieldValues, type RegisterOptions } from "react-hook-form"
import type { FillableMetadataDataCollection } from "../../../../../../utils/frontendTypes/dataCollection.types"
import type { FillableMetadataDataset } from "../../../../../../utils/frontendTypes/datasets.types"
import { EMAIL_REGEX, LINK_REGEX, PUBLISHER_NAME_REGEX } from "../../../../../../utils/sdk/helpers"

// based on type defined in react-hook-form library
export type FormRuleType<FormType extends FieldValues = FieldValues, FormTypeKeys extends FieldPath<FormType> = FieldPath<FormType>> = Omit<RegisterOptions<FormType, FormTypeKeys>, "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled">

export type MetadataValidationFormDataset = {
  [P in keyof FillableMetadataDataset]?: FormRuleType
}

export type MetadataFormPlaceholdersDataset = {
  [P in keyof FillableMetadataDataset]?: string
}

export type MetadataValidationFormCollection = {
  [P in keyof FillableMetadataDataCollection]?: FormRuleType
}

export const metadataFormValidation: MetadataValidationFormDataset = {}
export const metadataFormValidationCollection: MetadataValidationFormCollection = {
  contactEmail: {
    required: "This field is required.",
    pattern: { value: EMAIL_REGEX, message: "Invalid email." },
  },
  contactLastName: {
    required: "This field is required.",
    pattern: { value: PUBLISHER_NAME_REGEX, message: "Invalid name." },
  },
  publishedDate: { required: "This field is required." },
  website: { required: "This field is required.", pattern: { value: LINK_REGEX, message: "Invalid link." } },
  licenseName: { required: "This field is required." },
  licenseHref: { required: "This field is required.", pattern: { value: LINK_REGEX, message: "Invalid link." } },
}

// eslint-disable-next-line prefer-regex-literals, @typescript-eslint/quotes
const doiRegex = '^(?:doi:\\/\\/)?(10\\.[0-9]{4,9}(?:\\.[0-9]+)*\\/(?:(?![%"#?\\s])\\S)+)$'

export const metadataFormValidationDataset: MetadataValidationFormDataset = {
  doi: {
    pattern: {
      value: new RegExp(doiRegex),
      message: "Invalid value. Valid exaples: doi://10.48670/moi-00016, 10.48670/moi-00016",
    },
  },
  contactEmail: {
    required: "This field is required.",
    pattern: { value: EMAIL_REGEX, message: "Invalid email." },
  },
  contactLastName: {
    required: "This field is required.",
    pattern: { value: PUBLISHER_NAME_REGEX, message: "Invalid name." },
  },
}

export const metadataFormDatasetPlaceHolders: MetadataFormPlaceholdersDataset = {
  doi: "E.g. doi://10.48670/moi-00016, 10.48670/moi-00016",
}
