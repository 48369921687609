import { Box, Typography } from "@mui/material"
import React from "react"
import { convertSecondsToDateString } from "../utils/frontendTypes/datasets.types"

type Props = {
  rangeStart: number
  rangeEnd: number
  button?: React.ReactNode
}

const TimeRangeObservable = (p: Props) => {
  const startDate = convertSecondsToDateString(p.rangeStart)
  const endDate = convertSecondsToDateString(p.rangeEnd)

  return (
    <>
      <Box display={"flex"} alignItems="center">
        <Typography>Time Range: </Typography>
        {p.button}
      </Box>
      <Box display={"flex"}>
        <Box sx={{ mr: 2 }}>
          <Typography>From:</Typography>
          <Typography>To:</Typography>
        </Box>
        <Box>
          <Typography>{startDate}</Typography>
          <Typography>{endDate}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default React.memo(TimeRangeObservable)
