import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const DatasetIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="24" height="24" viewBox="0 -4 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 8L7 15" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M15 8L19 15" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M9 18H17" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" />
        <path
          d="M5 22C5.79112 22 6.56448 21.7654 7.22228 21.3259C7.88008 20.8864 8.39277 20.2616 8.69552 19.5307C8.99827 18.7998 9.07748 17.9956 8.92314 17.2196C8.7688 16.4437 8.38784 15.731 7.82843 15.1716C7.26902 14.6122 6.55628 14.2312 5.78036 14.0769C5.00444 13.9225 4.20017 14.0017 3.46927 14.3045C2.73836 14.6072 2.11365 15.1199 1.67412 15.7777C1.2346 16.4355 1 17.2089 1 18C1 19.0609 1.42143 20.0783 2.17157 20.8284C2.54301 21.1999 2.98396 21.4945 3.46927 21.6955C3.95457 21.8965 4.47471 22 5 22V22Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M21 22C21.7911 22 22.5645 21.7654 23.2223 21.3259C23.8801 20.8864 24.3928 20.2616 24.6955 19.5307C24.9983 18.7998 25.0775 17.9956 24.9231 17.2196C24.7688 16.4437 24.3878 15.731 23.8284 15.1716C23.269 14.6122 22.5563 14.2312 21.7804 14.0769C21.0044 13.9225 20.2002 14.0017 19.4693 14.3045C18.7384 14.6072 18.1136 15.1199 17.6741 15.7777C17.2346 16.4355 17 17.2089 17 18C17 19.0609 17.4214 20.0783 18.1716 20.8284C18.9217 21.5786 19.9391 22 21 22V22Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M13 9C13.7911 9 14.5645 8.7654 15.2223 8.32588C15.8801 7.88635 16.3928 7.26164 16.6955 6.53073C16.9983 5.79983 17.0775 4.99556 16.9231 4.21964C16.7688 3.44372 16.3878 2.73098 15.8284 2.17157C15.269 1.61216 14.5563 1.2312 13.7804 1.07686C13.0044 0.92252 12.2002 1.00173 11.4693 1.30448C10.7384 1.60723 10.1136 2.11992 9.67412 2.77772C9.2346 3.43552 9 4.20888 9 5C9 6.06087 9.42143 7.07828 10.1716 7.82843C10.9217 8.57857 11.9391 9 13 9Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </SvgIcon>
  )
}

export default React.memo(DatasetIcon)
