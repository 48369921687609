import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { StyledButton } from "../../../components/StyledButton"
import { type RawFileInfoGet } from "../../../utils/api/raw.types"
import DataDetailsTable from "../../CatalogCollection/components/DataDetailsTable/DataDetailsTable"
import GeometryMinimap from "../../CatalogCollection/components/GeometryMinimap"

type Props = {
  fileInfo: RawFileInfoGet | undefined
  setFileInfo: React.Dispatch<RawFileInfoGet | undefined>
}

const FileDetailsModal = ({ fileInfo, setFileInfo }: Props) => {
  const close = useCallback(() => {
    setFileInfo(undefined)
  }, [setFileInfo])

  return (
    <Dialog open={!!fileInfo} onClose={close} fullWidth maxWidth="lg" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle id="refine-location" fontWeight={700}>
        {"File details"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>Name: {fileInfo?.name}</Typography>
            <Typography>Type: {fileInfo?.mime_type}</Typography>
            <Typography>Size: {fileInfo?.size_bytes}</Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Metadata</Typography>
              {fileInfo?.metadata && (
                <Box sx={{ pl: 1, mt: 1 }}>
                  <DataDetailsTable data={fileInfo.metadata} cellSx={{ py: "2px", fontWeight: "normal" }} />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <GeometryMinimap geometry={fileInfo?.geo_location?.geometry} containerId="FileMap" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledButton onClick={close} size="large">
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(FileDetailsModal)
