import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const BrowseCatalogIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 23.25C12.2967 23.25 12.5867 23.162 12.8334 22.9972C13.08 22.8324 13.2723 22.5981 13.3858 22.324C13.4994 22.0499 13.5291 21.7483 13.4712 21.4574C13.4133 21.1664 13.2704 20.8991 13.0607 20.6893C12.8509 20.4796 12.5836 20.3367 12.2926 20.2788C12.0017 20.2209 11.7001 20.2506 11.426 20.3642C11.1519 20.4777 10.9176 20.67 10.7528 20.9166C10.588 21.1633 10.5 21.4533 10.5 21.75C10.5 22.1478 10.658 22.5294 10.9393 22.8107C11.2206 23.092 11.6022 23.25 12 23.25Z"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 21.75C6.67167 21.75 6.96168 21.662 7.20836 21.4972C7.45503 21.3324 7.64729 21.0981 7.76082 20.824C7.87435 20.5499 7.90406 20.2483 7.84618 19.9574C7.7883 19.6664 7.64544 19.3991 7.43566 19.1893C7.22588 18.9796 6.95861 18.8367 6.66764 18.7788C6.37666 18.7209 6.07506 18.7506 5.80097 18.8642C5.52689 18.9777 5.29262 19.17 5.1278 19.4166C4.96297 19.6633 4.875 19.9533 4.875 20.25C4.875 20.6478 5.03304 21.0294 5.31434 21.3107C5.59564 21.592 5.97718 21.75 6.375 21.75Z"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 16.875C12.2967 16.875 12.5867 16.787 12.8334 16.6222C13.08 16.4574 13.2723 16.2231 13.3858 15.949C13.4994 15.6749 13.5291 15.3733 13.4712 15.0824C13.4133 14.7914 13.2704 14.5241 13.0607 14.3143C12.8509 14.1046 12.5836 13.9617 12.2926 13.9038C12.0017 13.8459 11.7001 13.8756 11.426 13.9892C11.1519 14.1027 10.9176 14.295 10.7528 14.5416C10.588 14.7883 10.5 15.0783 10.5 15.375C10.5 15.7728 10.658 16.1544 10.9393 16.4357C11.2206 16.717 11.6022 16.875 12 16.875Z"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.50002 14.2502C6.55641 13.5472 5.79202 12.6314 5.269 11.5774C4.74598 10.5233 4.47911 9.36073 4.49007 8.1841C4.50103 7.00747 4.78952 5.85003 5.33209 4.80591C5.87465 3.76178 6.65597 2.86044 7.61252 2.17516C8.88791 1.24887 10.4237 0.75 12 0.75C13.5763 0.75 15.1121 1.24887 16.3875 2.17516C17.3434 2.86093 18.124 3.76245 18.6661 4.80651C19.2082 5.85058 19.4964 7.00777 19.5074 8.18412C19.5183 9.36047 19.2517 10.5228 18.7292 11.5768C18.2066 12.6308 17.4429 13.5467 16.5 14.2502"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 11.625C12.6675 11.625 13.32 11.4271 13.8751 11.0562C14.4301 10.6854 14.8626 10.1583 15.1181 9.54156C15.3735 8.92486 15.4404 8.24626 15.3102 7.59157C15.1799 6.93689 14.8585 6.33552 14.3865 5.86352C13.9145 5.39151 13.3131 5.07008 12.6584 4.93985C12.0037 4.80963 11.3251 4.87646 10.7084 5.13191C10.0917 5.38735 9.56464 5.81994 9.19379 6.37495C8.82294 6.92997 8.625 7.58249 8.625 8.25C8.625 9.14511 8.98058 10.0036 9.61351 10.6365C10.2465 11.2694 11.1049 11.625 12 11.625V11.625Z"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 20.25V16.875"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.375 18.75V17.25L10.5 15.375"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.625 21.75C17.9217 21.75 18.2117 21.662 18.4584 21.4972C18.705 21.3324 18.8973 21.0981 19.0108 20.824C19.1244 20.5499 19.1541 20.2483 19.0962 19.9574C19.0383 19.6664 18.8954 19.3991 18.6857 19.1893C18.4759 18.9796 18.2086 18.8367 17.9176 18.7788C17.6267 18.7209 17.3251 18.7506 17.051 18.8642C16.7769 18.9777 16.5426 19.17 16.3778 19.4166C16.213 19.6633 16.125 19.9533 16.125 20.25C16.125 20.6478 16.283 21.0294 16.5643 21.3107C16.8456 21.592 17.2272 21.75 17.625 21.75Z"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.625 18.75V17.25L13.5 15.375"
          stroke="#14F9CF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default React.memo(BrowseCatalogIcon)
