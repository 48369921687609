import React from "react"
import WorkspacesIcon from "../../../assets/icons/WorkspacesIcon"
import { externalPaths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import { DashbordItem } from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const EnterWorkspaceWidget = () => {
  const onClick = () => {
    window.open(externalPaths.Connector)
  }

  return (
    <DashbordItem variant="outlined">
      <WidgetHeader title="Enter Workspace" titleIcon={<WorkspacesIcon sx={{ mr: 1 }} />} />
      <WidgetContent description="Connect to a Jupyter Hub environment where you can directly access your uploaded data and the rest of the ODP Catalog" />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousGreen} textColor={colorPalette.deepPurple}>
        Enter Workspace
      </WidgetButton>
    </DashbordItem>
  )
}

export default React.memo(EnterWorkspaceWidget)
