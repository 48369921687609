import { Box, Popper as MUIPopper, Autocomplete as MuiAutocomplete, styled } from "@mui/material"
import { colorPalette } from "../../styling/theme"

const AutoCompleteWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ClearIconWrapper = styled(Box)`
  cursor: pointer;
  visibility: visible;
  display: flex;
  align-items: center;
  border-left: 2px solid ${colorPalette.deepPurpleO30};
  padding-left: 4px;
  svg {
    color: ${colorPalette.deepPurpleO30};
    transform: scale(0.9);
    &:hover {
      color: ${colorPalette.deepPurpleO50};
      background-color: none;
    }
  }
`

const SearchIconWrapper = styled(Box)`
  display: flex;
  padding-right: 8px;
  > svg {
    transform: scale(0.9);
  }
`

const Autocomplete = styled(MuiAutocomplete)`
  flex-basis: 100%;
  margin-right: "10px";

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colorPalette.deepPurpleO30};
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: ${colorPalette.deepPurple};
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      .MuiAutocomplete-clearIndicator {
        background: none;
        border-radius: 0;
      }
    }
  }
` as typeof MuiAutocomplete

const Popper = styled(MUIPopper)`
  .MuiAutocomplete-listbox {
    .MuiAutocomplete-option.Mui-focused {
      background-color: ${colorPalette.deepPurpleO03};
    }
    .MuiAutocomplete-option:hover {
      background-color: ${colorPalette.deepPurpleO10};
    }
  }
`

export const S = {
  Popper,
  ClearIconWrapper,
  SearchIconWrapper,
  Autocomplete,
  AutoCompleteWrapper,
}
