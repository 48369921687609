import { Grid, Typography } from "@mui/material"
import APIAccessWidget from "./Widgets/APIAccessWidget"
import BrowseProjectsWidget from "./Widgets/BrowseProjectsWidget"
import CatalogWidget from "./Widgets/CatalogWidget"
import EnterWorkspaceWidget from "./Widgets/EnterWorkspaceWidget"
import GetSupportWidget from "./Widgets/GetSupportWidget"
import UploadDataWidget from "./Widgets/UploadDataWidget/UploadDataWidget"

const Dashboard = () => {
  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        textAlign={"start"}
        marginBottom="54px"
        fontWeight={500}
        data-appid={process.env.REACT_APP_VERSION}>
        Welcome to the Ocean Data Platform
      </Typography>
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid container item md={4} sm={6} spacing={3} direction="column" sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <CatalogWidget />
          </Grid>
          <Grid item flexGrow={1}>
            <EnterWorkspaceWidget />
          </Grid>
        </Grid>

        <Grid container item md={4} sm={6} spacing={3} direction="column" sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <APIAccessWidget />
          </Grid>
          <Grid item flexGrow={1}>
            <BrowseProjectsWidget />
          </Grid>
        </Grid>

        <Grid container item md={4} sm={6} spacing={3} direction="column" sx={{ justifyContent: "space-between" }}>
          <Grid item flexGrow={1}>
            <UploadDataWidget />
          </Grid>
          <Grid item flexGrow={1}>
            <GetSupportWidget />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
