import ArticleIcon from "@mui/icons-material/Article"
import CloseIcon from "@mui/icons-material/Close"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import { Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import React, { useState, type ChangeEvent } from "react"
import CustomAlert from "../../../../../../../components/CustomAlert"
import { Loader } from "../../../../../../../components/Loader/Loader"
import { StyledButton } from "../../../../../../../components/StyledButton"
import { StyledSectionHeader } from "../../../../../../../components/StyledTypography"
import { slugify } from "../../../../../../../utils/api/requests.utils"
import { type DataSet } from "../../../../../../../utils/frontendTypes/datasets.types"
import { useUploadRawFileForDatasetByNameIdMutation } from "../../../../../../../utils/redux/queries/raw.queries"
import { PulsatingRipple } from "./FileToUploadRaw.style"
import { prepareOpenedFile } from "./rawFile.helpers"

const FileToUploadRaw = (p: { dataset: DataSet }) => {
  const [file, setFile] = useState<File | null>(null)
  const [uploadFile, { isLoading, error }] = useUploadRawFileForDatasetByNameIdMutation()

  const clickSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : undefined
    if (selectedFile) setFile(prepareOpenedFile(selectedFile))
  }

  const clickRemove = () => {
    setFile(null)
  }

  const clickUploadFile = async () => {
    if (!file) return
    await uploadFile({ datasetNameId: p.dataset.nameId, fileName: slugify(file.name), file })
    setFile(null)
  }

  return (
    <>
      <StyledSectionHeader>{file ? "File Ready for Upload" : "Add a New File"} </StyledSectionHeader>

      {error && <CustomAlert error={error} sx={{ mt: 1 }} />}

      {!file && (
        <StyledButton component="label" variant="outlined" startIcon={<UploadFileIcon />} sx={{ mt: 2 }}>
          Open file
          <input type="file" hidden onChange={clickSelectFile} />
        </StyledButton>
      )}

      {!!file && !isLoading && (
        <List dense={true}>
          <ListItem key={file.name + "_file"}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>file name: {file.name}</Typography>
              <Typography sx={{ fontSize: 14 }}>type: {file.type}</Typography>
              <Typography sx={{ fontSize: 14 }}>size: {file.size}</Typography>
            </ListItemText>

            <IconButton onClick={clickUploadFile} data-testid="uploadFileBtn">
              <UploadFileIcon />
              <PulsatingRipple />
            </IconButton>
            <IconButton onClick={clickRemove} data-testid="discardFileBtn">
              <CloseIcon />
            </IconButton>
          </ListItem>
        </List>
      )}

      {isLoading && (
        <Box sx={{ mt: 2, ml: 5 }}>
          <Loader size={25} />
        </Box>
      )}
    </>
  )
}

export default React.memo(FileToUploadRaw)
