import { Alert, Box } from "@mui/material"
import React, { useMemo } from "react"
import CustomAlert from "../../../components/CustomAlert"
import DataSampleTable from "../../../components/DataSampleTable/DataSampleTable"
import { Loader } from "../../../components/Loader/Loader"
import { mapDataPointsToTable } from "../../../utils/api/schema.mappers"
import { useGetDataPointsByDatasetNameIdQuery } from "../../../utils/redux/queries/dataset.queries"
import { SemanticError } from "../../../utils/sdk/utils/requests/sdk.request.helpers"

type Props = {
  datasetNameId: string
}

const CatalogSampleTable = (p: Props) => {
  const catalogSampleSizeLimit = 50

  const {
    data: dataPoints,
    isLoading,
    isError,
    error,
  } = useGetDataPointsByDatasetNameIdQuery({
    datasetNameId: p.datasetNameId,
    limitedStreamOptions: {
      maxObjects: 5,
      maxMb: catalogSampleSizeLimit,
    },
  })

  const unauthorized = error && "status" in error && error?.status === 401

  const tableData = useMemo(() => {
    if (dataPoints) return mapDataPointsToTable(dataPoints)
  }, [dataPoints])

  if (isLoading) {
    return (
      <Box display={"flex"} justifyContent="center" sx={{ mt: 3 }}>
        <Loader />
      </Box>
    )
  }

  const incompleteDataSampleError = error && "data" in error && error.data === SemanticError.incompleteResponse

  if (unauthorized) return <Alert severity="info">{"Sorry, you do not have access to the data sample yet."}</Alert>
  if (error && incompleteDataSampleError) {
    return (
      <Alert severity="info">{`The data sample exceeds ${catalogSampleSizeLimit} MB and cannot be displayed.`}</Alert>
    )
  }
  if (isError && error) return <CustomAlert severity={incompleteDataSampleError ? "info" : "error"} error={error} />
  if (isError) return <Alert severity="error">This data sample is not working right now.</Alert>

  if (!tableData) return <Alert severity="info">The dataset is empty</Alert>
  if (tableData.columns.length === 0 || tableData.columns[0] === "@@end") {
    return <Alert severity="info">There is no data in the table yet.</Alert>
  }

  return <DataSampleTable data={tableData} />
}

export default React.memo(CatalogSampleTable)
