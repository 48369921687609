import { Box } from "@mui/material"
import React from "react"
import GeometryMinimap from "../../../../../pages/CatalogCollection/components/GeometryMinimap"
import type { ObservableDtoGet } from "../../../../../utils/sdk/utils/entities/sdk.observable.types"

const BBoxCatalogObservable = (p: ObservableDtoGet<GeoJSON.Geometry>) => {
  const geometry = p.spec.details.value

  return (
    <Box sx={{ mb: 2 }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <GeometryMinimap geometry={geometry} height={"400px"} containerId={"map_" + p.metadata.uuid} />
    </Box>
  )
}

export default React.memo(BBoxCatalogObservable)
