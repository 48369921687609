import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import { Loader } from "../../../../../../../../../components/Loader/Loader"
import { StyledButton } from "../../../../../../../../../components/StyledButton"
import { StyledLoadingButton } from "../../../../../../../../../components/StyledLoadingButton"
import { colorPalette } from "../../../../../../../../../styling/theme"
import { type TableSchemaGetDto } from "../../../../../../../../../utils/api/schema.types"
import { type DataSet } from "../../../../../../../../../utils/frontendTypes/datasets.types"
import { useDeleteSchemaByNameIdMutation } from "../../../../../../../../../utils/redux/queries/dataset.queries"
import DataDetailsTable from "../../../../../../../../CatalogCollection/components/DataDetailsTable/DataDetailsTable"

type Props = {
  open: boolean
  closeFn: () => void
  schema: TableSchemaGetDto
  dataset: DataSet
}

const DisplaySchemaDialog = (p: Props) => {
  const [deleteSchema, { isLoading }] = useDeleteSchemaByNameIdMutation()

  const clickDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this schema?")
    if (confirmed) {
      const deleteResult = await deleteSchema(p.dataset.nameId)
      const isDeleted = "data" in deleteResult
      if (isDeleted) p.closeFn()
    }
  }

  const { table_schema: tableSchema, ...rest } = p.schema

  return (
    <Dialog open={p.open} onClose={p.closeFn} fullWidth maxWidth="sm" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle fontWeight={700}>{"Schema"}</DialogTitle>
      <DialogContent sx={{ justifyContent: "center" }}>
        <Grid container direction={"column"} sx={{ mb: 2 }} spacing={1}>
          <Grid item>
            <Typography sx={{ mb: 1, fontWeight: "bold" }}>{"Table schema:"}</Typography>
            {Object.entries(tableSchema).map(([key, value]) => {
              return (
                <Box sx={{ mb: 1, pl: 2 }} key={"schema_" + key}>
                  <Typography display={"inline"}>{key}</Typography>
                  <Typography variant="subtitle2" sx={{ ml: 2 }} display="inline" color={colorPalette.deepPurpleO50}>
                    {`Type: ${value.type}`}
                  </Typography>
                </Box>
              )
            })}
          </Grid>
          <Grid item>
            <Typography sx={{ mb: 1, fontWeight: "bold" }}>{"Schema details:"}</Typography>
            {p.schema && <DataDetailsTable data={rest} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledButton onClick={p.closeFn}>Close</StyledButton>
        <StyledLoadingButton
          onClick={clickDelete}
          loading={isLoading}
          variant={"outlined"}
          loadingIndicator={<Loader size={20} color={colorPalette.deepPurple} />}>
          Delete
        </StyledLoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(DisplaySchemaDialog)
