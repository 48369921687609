import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const UploadDataIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3322_1251)">
          <path
            d="M5.36621 6.49609V9.81109L8.68496 13.1261V23.9073"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.87988 15.6152V18.9302L5.36613 21.4165V23.9065"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.12 15.6152V18.9302L18.6338 21.4165V23.9065"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6337 6.49609V9.81109L15.3149 13.1261V23.9073"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 4.00977V23.9073"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.366 6.49469C5.69382 6.49469 6.01428 6.39748 6.28685 6.21535C6.55943 6.03322 6.77187 5.77435 6.89733 5.47149C7.02278 5.16862 7.0556 4.83535 6.99165 4.51383C6.92769 4.1923 6.76983 3.89696 6.53803 3.66516C6.30622 3.43335 6.01088 3.27549 5.68936 3.21154C5.36783 3.14758 5.03457 3.18041 4.7317 3.30586C4.42883 3.43131 4.16996 3.64376 3.98783 3.91633C3.80571 4.18891 3.7085 4.50937 3.7085 4.83719C3.71047 5.27618 3.88573 5.69662 4.19615 6.00704C4.50656 6.31745 4.92701 6.49272 5.366 6.49469Z"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.88016 15.6158C3.20798 15.6158 3.52844 15.5186 3.80101 15.3364C4.07359 15.1543 4.28603 14.8954 4.41149 14.5926C4.53694 14.2897 4.56976 13.9564 4.50581 13.6349C4.44185 13.3134 4.28399 13.0181 4.05219 12.7863C3.82038 12.5544 3.52504 12.3966 3.20352 12.3326C2.88199 12.2687 2.54873 12.3015 2.24586 12.427C1.94299 12.5524 1.68412 12.7649 1.502 13.0374C1.31987 13.31 1.22266 13.6305 1.22266 13.9583C1.22365 14.3976 1.39859 14.8186 1.70922 15.1292C2.01985 15.4398 2.44086 15.6148 2.88016 15.6158Z"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.1204 15.6158C21.4482 15.6158 21.7687 15.5186 22.0412 15.3364C22.3138 15.1543 22.5263 14.8954 22.6517 14.5926C22.7772 14.2897 22.81 13.9564 22.746 13.6349C22.6821 13.3134 22.5242 13.0181 22.2924 12.7863C22.0606 12.5544 21.7653 12.3966 21.4438 12.3326C21.1222 12.2687 20.789 12.3015 20.4861 12.427C20.1832 12.5524 19.9244 12.7649 19.7422 13.0374C19.5601 13.31 19.4629 13.6305 19.4629 13.9583C19.4639 14.3976 19.6388 14.8186 19.9495 15.1292C20.2601 15.4398 20.6811 15.6148 21.1204 15.6158Z"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.6339 6.49468C18.9616 6.49394 19.2817 6.3961 19.5537 6.21352C19.8258 6.03095 20.0377 5.77182 20.1626 5.46889C20.2874 5.16595 20.3197 4.83281 20.2553 4.51153C20.191 4.19025 20.0328 3.89527 19.8009 3.66384C19.5689 3.4324 19.2736 3.27492 18.9521 3.21126C18.6307 3.14761 18.2976 3.18065 17.995 3.30622C17.6923 3.43178 17.4337 3.64422 17.2517 3.91672C17.0698 4.18921 16.9727 4.50952 16.9727 4.83718C16.9736 5.27712 17.1491 5.69871 17.4605 6.00944C17.772 6.32018 18.194 6.49469 18.6339 6.49468Z"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0003 4.00804C12.3292 4.00878 12.6509 3.91189 12.9246 3.72964C13.1984 3.54738 13.4119 3.28797 13.5381 2.98426C13.6643 2.68055 13.6975 2.34622 13.6335 2.02362C13.5696 1.70102 13.4113 1.40467 13.1787 1.17212C12.9461 0.939561 12.6498 0.781266 12.3272 0.717286C12.0046 0.653306 11.6703 0.68652 11.3666 0.81272C11.0629 0.938921 10.8034 1.15243 10.6212 1.42619C10.4389 1.69996 10.342 2.02166 10.3428 2.35054C10.3438 2.78984 10.5187 3.21085 10.8293 3.52148C11.14 3.83211 11.561 4.00705 12.0003 4.00804V4.00804Z"
            stroke="#9361EC"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_3322_1251">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default React.memo(UploadDataIcon)
