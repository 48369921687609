import { TextField } from "@mui/material"
import React from "react"
import { Controller } from "react-hook-form"
import { createPropName } from "../../../../../../../../../utils/frontendTypes/datasetType.helpers"
import type { FillableMetadataDataset } from "../../../../../../../../../utils/frontendTypes/datasets.types"
import { metadataFormDatasetPlaceHolders, metadataFormValidationDataset, type FormRuleType } from "../../../editMetadataDialog.helpers"
import { type MetadataInputProps } from "../MetadataInput"

const MetadataTextFieldDataset = (p: MetadataInputProps<FillableMetadataDataset>) => {
  const metadataFormValidation = metadataFormValidationDataset

  return (
    <Controller
      name={p.metadataPropName}
      control={p.form.control}
      rules={metadataFormValidation[p.metadataPropName] as (FormRuleType<FillableMetadataDataset, keyof FillableMetadataDataset> | undefined)}
      render={({ field, formState }) => {
        return (
          <TextField
            fullWidth
            label={createPropName(p.metadataPropName)}
            size="small"
            {...field}
            value={field.value ?? ""}
            error={!!formState.errors[p.metadataPropName]}
            helperText={formState.errors[p.metadataPropName]?.message?.toString()}
            placeholder={metadataFormDatasetPlaceHolders[p.metadataPropName]}
          />
        )
      }}
    />
  )
}

export default React.memo(MetadataTextFieldDataset)
