import { TextField } from "@mui/material"
import { Controller, type UseFormReturn } from "react-hook-form"
import {
  SpecialFacetNames,
  SpecialFacetRules,
  type SpecialFacetFormKey,
  type SpecialFacetsType,
} from "../../../../../../CatalogDataset/components/DataInteraction/dataInteraction.helpers"
import type { FormRuleType } from "../../../components/EditMetadataDialog/editMetadataDialog.helpers"

type Props = {
  facetKey: SpecialFacetFormKey
  form: UseFormReturn<SpecialFacetsType>
}

const InteractionLinkInput = (p: Props) => {
  return (
    <Controller
      name={p.facetKey}
      control={p.form.control}
      rules={SpecialFacetRules[p.facetKey] as (FormRuleType<SpecialFacetsType, SpecialFacetFormKey> | undefined)}
      render={({ field, formState }) => {
        return (
          <TextField
            fullWidth
            label={SpecialFacetNames[p.facetKey]}
            size="small"
            {...field}
            value={field.value || ""}
            error={!!formState.errors[p.facetKey]}
            helperText={formState.errors[p.facetKey]?.message?.toString()}
          />
        )
      }}
    />
  )
}

export default InteractionLinkInput
