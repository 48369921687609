import { Box, Typography } from "@mui/material"
import React from "react"
import { catalogDatasetPage } from "../../../pages/Catalog/catalog.helpers"
import { myDatasetLink } from "../../../pages/MyData/myData.helpers"
import { colorPalette } from "../../../styling/theme"
import type { DataSet } from "../../../utils/frontendTypes/datasets.types"
import TypographyLink from "../../TypographyLink/TypographyLink"

const CollectionDatasetLink = (p: DataSet & { isMyData?: boolean }) => {
  const link = p.isMyData ? myDatasetLink(p.nameId) : catalogDatasetPage(p.nameId)

  return (
    <Box>
      <TypographyLink key={p.nameId} text={p.name} href={link} sx={{ display: "inline" }} testId={"datasetLink"} />
      <Typography
        variant="subtitle2"
        sx={{
          display: "inline",
          color: colorPalette.deepPurpleO50,
          ml: 0.5,
          fontWeight: "normal",
          fontStyle: "italic",
          wordBreak: "keep-all",
        }}>
        {p.type}
      </Typography>
    </Box>
  )
}

export default React.memo(CollectionDatasetLink)
