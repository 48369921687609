import React from "react"
import NoSearchResultsText from "../../../../../components/NoSearchResultsText"
import { S } from "../CatalogResultsList.style"

const NoResults = () => {
  return (
    <S.ResultsWrapper>
      <S.Results>
        <NoSearchResultsText />
      </S.Results>
    </S.ResultsWrapper>
  )
}

export default React.memo(NoResults)
