import { Box, Typography } from "@mui/material"
import { type PropsWithChildren } from "react"

type Props = {
  title: string
  titleIcon?: React.ReactNode
  color?: string
}
const WidgetHeader = (p: PropsWithChildren<Props>) => {
  return (
    <Box display="flex" alignItems={"center"} color={p.color} sx={{ mb: 2 }}>
      {p.titleIcon}
      <Typography component={"h2"} variant={"h5"}>
        {p.title}
      </Typography>
    </Box>
  )
}

export default WidgetHeader
