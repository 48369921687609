import React, { useCallback } from "react"
import AutocompleteSearch from "../../../../components/AutocompleteSearch/AutocompleteSearch"
import { setCatalogSearchString } from "../../../../utils/redux/reducers/catalogReducer"
import { useAppDispatch } from "../../../../utils/redux/store"

const AutocompleteSearchCatalog = () => {
  const dispatch = useAppDispatch()

  const onSubmit = useCallback(
    (searchString: string) => {
      dispatch(setCatalogSearchString(searchString))
    },
    [dispatch]
  )

  return <AutocompleteSearch onSubmit={onSubmit} />
}

export default React.memo(AutocompleteSearchCatalog)
