import { Paper, styled } from "@mui/material"

export const DashbordItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(4),
  height: `calc(100% - ${theme.spacing(8)})`,
  borderRadius: 10,
  boxSizing: "content-box",
})) as typeof Paper

export default DashbordItem
