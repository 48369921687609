import { FormControl, Grid, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material"
import React, { useEffect, useState } from "react"
import { StyledButton } from "../../../../../../components/StyledButton"
import { StyledSectionHeader } from "../../../../../../components/StyledTypography"
import { useGetObservablesForResourceQuery } from "../../../../../../utils/redux/queries/dataset.queries"
import { ObservableCls } from "../../../../../../utils/sdk/utils/entities/sdk.observable.types"
import type { ResourceTypeOdp } from "../../../../../../utils/sdk/utils/entities/sdk.resource.types"
import AddObservableModal from "./components/AddObservableModal"
import AddTimeRangeObsModal from "./components/AddTimeRangeObsModal"
import Observable from "./components/Observable"

type Props = {
  nameId: string
  type: ResourceTypeOdp
}

const ResourceObservables = (p: Props) => {
  const { data: observables } = useGetObservablesForResourceQuery({ resourceId: p.nameId, resourceType: p.type })
  const [mode, setMode] = React.useState<"time" | "bbox" | undefined>(undefined)

  const observableTypes = observables?.map(el => el.spec.observable_class)
  const hasBBoxObservable = observableTypes?.includes(ObservableCls.BBox)
  const hasTimeRangeObservable = observableTypes?.includes(ObservableCls.TimeRange)
  const hasBothObservables = hasBBoxObservable && hasTimeRangeObservable

  const displayCreateObservable = !hasBothObservables && !!mode

  useEffect(() => {
    if (hasBothObservables) return setMode(undefined)
    if (hasBBoxObservable) return setMode("time")
    if (hasTimeRangeObservable) return setMode("bbox")
    setMode("bbox")
  }, [hasBBoxObservable, hasTimeRangeObservable, hasBothObservables])

  const handleChange = (event: SelectChangeEvent) => {
    setMode(event.target.value as "time" | "bbox")
  }

  const [showCreateObservableModal, setShowCreateObservableModal] = useState(false)

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <StyledSectionHeader>Coverage</StyledSectionHeader>
      </Grid>
      {!!observables?.length && (
        <Grid item container spacing={2} direction="column" sx={{ width: "100%" }}>
          {observables?.map(el => (
            <Grid item key={"observable_" + el.metadata.uuid}>
              <Observable {...el} />
            </Grid>
          ))}
        </Grid>
      )}
      {displayCreateObservable && (
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <FormControl>
            <InputLabel id="select_observable_label">Type</InputLabel>
            <Select
              labelId="select_observable_label"
              id="select_observable"
              value={mode}
              label="Type"
              size="small"
              onChange={handleChange}>
              {!hasBBoxObservable && <MenuItem value={"bbox"}>Geography</MenuItem>}
              {!hasTimeRangeObservable && <MenuItem value={"time"}>Time</MenuItem>}
            </Select>
          </FormControl>
          <StyledButton variant="outlined" onClick={() => setShowCreateObservableModal(true)} sx={{ ml: 1 }}>
            Define Coverage
          </StyledButton>
        </Grid>
      )}
      {showCreateObservableModal && mode === "bbox" && (
        <AddObservableModal
          open={showCreateObservableModal}
          setOpen={setShowCreateObservableModal}
          nameId={p.nameId}
          type={p.type}
        />
      )}
      {showCreateObservableModal && mode === "time" && (
        <AddTimeRangeObsModal
          open={showCreateObservableModal}
          setOpen={setShowCreateObservableModal}
          nameId={p.nameId}
          type={p.type}
        />
      )}
    </Grid>
  )
}

export default React.memo(ResourceObservables)
