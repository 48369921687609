import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { StyledButton } from "../../../../../../../components/StyledButton"
import { StyledLoadingButton } from "../../../../../../../components/StyledLoadingButton"
import TypographyLink from "../../../../../../../components/TypographyLink/TypographyLink"
import { createMyCollectionPath } from "../../../../../../../utils/frontendTypes/datasets.types"
import { useUpdateParrentCollectionMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import AutocompleteSelectCollection from "./AutocompleteSelectCollection/AutocompleteSelectCollection"

type Props = {
  open: boolean
  currentCollectionId?: string | null
  closeFn: () => void
  datasetUuid: string
}

const EditParentCollectionDialog = (p: Props) => {
  const [targetCollectionId, setTargetCollectionId] = useState<string | undefined>()

  const collectionLink = p.currentCollectionId ? createMyCollectionPath(p.currentCollectionId) : undefined
  const selectTargetCollection = (collectionId?: string) => {
    setTargetCollectionId(collectionId)
  }

  const [updateParentCollection, { isLoading, isSuccess }] = useUpdateParrentCollectionMutation()

  const clickMove = async () => {
    void updateParentCollection({ datasetId: p.datasetUuid, collectionId: targetCollectionId! })
  }

  useEffect(() => {
    if (isSuccess) p.closeFn()
  }, [isSuccess, p])

  return (
    <Dialog open={p.open} onClose={close} fullWidth maxWidth="sm" PaperProps={{ sx: { p: 3, overflowY: "visible" } }}>
      <DialogTitle id="edit-parent-collection" fontWeight={700}>
        {"Move dataset"}
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center", overflowY: "hidden" }}>
        {!!collectionLink && (
          <Box>
            <Typography>
              Move dataset from collection:{" "}
              <TypographyLink
                href={collectionLink}
                text={p.currentCollectionId}
                sx={{ display: "inline" }}
                testId="myDataParentCollectionLink"
              />
            </Typography>
          </Box>
        )}
        {!collectionLink && <Typography sx={{ mb: 1 }}>Select a destination collection for your dataset:</Typography>}
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          {collectionLink && <Typography sx={{ display: "inline", mr: 1 }}> To: </Typography>}
          <AutocompleteSelectCollection onSelect={selectTargetCollection} currentCollectionId={p.currentCollectionId} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledLoadingButton
          onClick={clickMove}
          disabled={!targetCollectionId}
          variant={"contained"}
          loading={isLoading}>
          Move
        </StyledLoadingButton>
        <StyledButton onClick={p.closeFn} variant="outlined">
          Cancel
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditParentCollectionDialog)
