import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, Button } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../styling/theme"
import { paths } from "../../../../navigation.helpers"
import type { NavItemProps } from "../../HamburgerMenu/components/HamMenuItem"

const DesktopNavItem = (p: NavItemProps) => {
  const selected = (paths as Record<string, any>)[p.pageName] === location.pathname
  return (
    <Button
      onClick={p.onClick}
      sx={{
        color: selected ? colorPalette.luminousGreen : "white",
        display: "block",
        textTransform: "none",
        fontSize: "1.10rem",
        fontWeight: "400",
        height: "100%",
        borderRadius: 0,
        mr: 2,
        "&::after": selected
          ? {
              // eslint-disable-next-line @typescript-eslint/quotes
              content: '""',
              width: "100%",
              height: "10px",
              background: colorPalette.luminousGreen,
              position: "absolute",
              left: 0,
              bottom: 0,
            }
          : "none",
      }}>
      <Box display={"flex"} alignItems={"center"}>
        {p.pageName}
        {p.isExternal && (
          <OpenInNewIcon sx={{ fontSize: "medium", ml: 1, color: `${colorPalette.deepPurpleO50} !important` }} />
        )}
      </Box>
    </Button>
  )
}

export default React.memo(DesktopNavItem)
