import { Box, Typography, type SxProps } from "@mui/material"
import React from "react"
import ShowMoreText from "./ShowMoreText/ShowMoreText"
import { StyledMetadataKey } from "./StyledTypography"

type Props = {
  objectKey: string
  value: any
  sx?: SxProps
  valueSx?: SxProps
}

const KeyValueDisplay = (p: Props) => {
  return (
    <Box display={"flex"} sx={{ fontSize: "0.875rem", my: 0.5, ...p.sx }}>
      <StyledMetadataKey
        sx={{
          minWidth: "130px",
          maxWidth: "130px",
          mr: 1,
        }}>
        {p.objectKey + ":"}
      </StyledMetadataKey>
      {p.value && (
        <Typography sx={{ fontSize: "inherit", wordBreak: "break-all", ...p.valueSx }}>
          {typeof p.value === "string" ? <ShowMoreText text={p.value} /> : p.value}
        </Typography>
      )}
    </Box>
  )
}

export default React.memo(KeyValueDisplay)
