import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react"
import { Alert, Box, Grid, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import CollectionDatasetSwitch, { CatalogMode } from "../../components/CollectionDatasetSwitch"
import RefineLocationMapModal from "../../components/RefineLocationMapModal/RefineLocationMapModal"
import SignInButton from "../../components/SignInButton/SignInButton"
import { StyledButton } from "../../components/StyledButton"
import type { BoundingMapboxCoords } from "../../utils/frontendTypes/datasets.types"
import { setBounidingBoxFilterRA, setCatalogSearchString } from "../../utils/redux/reducers/catalogReducer"
import { useAppDispatch } from "../../utils/redux/store"
import AutocompleteSearchCatalog from "./components/AutocompleteSearchCatalog/AutocompleteSearchCatalog"
import CatalogMiniMap from "./components/CatalogMinimap"
import CatalogCollectionsList from "./components/CatalogResults/CatalogCollectionsList"
import CatalogDatasetsList from "./components/CatalogResults/CatalogDatasetsList"
import FilterTimeRangeCatalog from "./components/FilterTimeRangeCatalog/FilterTimeRangeCatalog"

const Catalog = () => {
  const [mode, setMode] = useState<CatalogMode>(CatalogMode.Collection)
  const [showRefineLocation, setShowRefineLocation] = useState(false)
  const dispatch = useAppDispatch()

  const closeRefineLocation = () => setShowRefineLocation(false)
  const openRefineLocation = () => setShowRefineLocation(true)

  const clearLocationFilter = () => {
    dispatch(setBounidingBoxFilterRA())
  }

  const refineModalProps = useMemo(
    () => ({ onClick: (coords: BoundingMapboxCoords) => dispatch(setBounidingBoxFilterRA(coords)), text: "Done" }),
    [dispatch]
  )

  useEffect(() => {
    return () => {
      dispatch(setCatalogSearchString(""))
    }
  }, [dispatch])

  return (
    <Grid container spacing={5}>
      <Grid item container direction="column" md={4} spacing={4}>
        <Grid item>
          <AutocompleteSearchCatalog />
        </Grid>
        <Grid item>
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 2 }}>Location</Typography>
          <ErrorBoundary
            fallbackRender={() => (
              <Alert severity="error">Map display error. We apologize for the inconvenience.</Alert>
            )}>
            <CatalogMiniMap openRefineLocation={openRefineLocation} />
          </ErrorBoundary>
          <StyledButton onClick={clearLocationFilter} sx={{ mt: 1, fontWeight: 500 }}>
            Clear location filter
          </StyledButton>
        </Grid>
        <Grid item sx={{ mb: { md: 3 } }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 18, mb: 2 }}>Date Range</Typography>
          <FilterTimeRangeCatalog />
        </Grid>
      </Grid>
      <Grid item md={8} sm={12}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <Typography variant="h5">All Catalog Results</Typography>
          <AuthenticatedTemplate>
            <CollectionDatasetSwitch setMode={setMode} currentMode={mode} />
          </AuthenticatedTemplate>
        </Box>
        <AuthenticatedTemplate>
          {mode === CatalogMode.Collection && <CatalogCollectionsList />}
          {mode === CatalogMode.Dataset && <CatalogDatasetsList />}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Typography>
            <SignInButton />
            {" to browse the Data Catalog"}
          </Typography>
        </UnauthenticatedTemplate>
      </Grid>
      <RefineLocationMapModal
        open={showRefineLocation}
        closeFn={closeRefineLocation}
        actionButtonProps={refineModalProps}
      />
    </Grid>
  )
}

export default React.memo(Catalog)
