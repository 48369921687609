import CustomAlert from "../../../../components/CustomAlert"
import PageLoader from "../../../../components/PageLoader.tsx/PageLoader"
import { useDatasets } from "../../../../utils/hooks/resourceHooks"
import { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"
import CatalogResult from "./CatalogResult"
import { S } from "./CatalogResultsList.style"
import NoResults from "./components/NoResults"

const CatalogDatasetsList = () => {
  const { datasets, isFetching, noPossibleResults, getDatasetsHookResults } = useDatasets({ isPublic: true })

  if (isFetching) return <PageLoader />

  const error = getDatasetsHookResults.error

  if (error) return <CustomAlert error={error} />
  if (!datasets?.length || noPossibleResults) return <NoResults />

  return (
    <S.ResultsWrapper>
      <S.Results>
        {datasets.map(el => (
          <CatalogResult dataSource={el} key={"catalogResult" + el.uuid} resultType={ResourceTypeOdp.Dataset} />
        ))}
      </S.Results>
    </S.ResultsWrapper>
  )
}

export default CatalogDatasetsList
