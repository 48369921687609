import { Grid, Typography } from "@mui/material"
import { useUserAuthorizationGroups } from "../../utils/auth/userHooks"
import ProjectCard from "./components/ProjectCard"
import { hasUserAuthorization, projectsData } from "./project.helpers"

const Projects = () => {
  const groups = useUserAuthorizationGroups()

  const appsToShow = projectsData.filter(el =>
    hasUserAuthorization({
      requiredGroups: el.authorizedGroups,
      authorizedGroups: groups,
    })
  )

  return (
    <Grid container spacing={8}>
      <Grid item md={3} sm={12}>
        <Typography variant="h4">Projects</Typography>
        <Typography sx={{ mt: 2 }}>
          Browse a collection of recommended tools which solve ocean problems and are derived from data from the Ocean
          Data Platform.
        </Typography>
      </Grid>
      <Grid item container md={9} spacing={3} alignItems={"stretch"} sx={{ mb: 3 }}>
        {appsToShow.map(el => (
          <Grid item key={"app" + el.title}>
            <ProjectCard {...el} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Projects
