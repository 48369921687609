import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material"
import React from "react"
import { setMyDataShowInternallySharedFilterRA } from "../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch, useAppSelector } from "../../../../../utils/redux/store"

const FilterInternallyShared = () => {
  const { showInternallyShared } = useAppSelector(state => state.datasetsReducer.filters)
  const dispatch = useAppDispatch()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value
    if (value === "all") dispatch(setMyDataShowInternallySharedFilterRA(undefined))
    if (value === "private") dispatch(setMyDataShowInternallySharedFilterRA(false))
    if (value === "shared") dispatch(setMyDataShowInternallySharedFilterRA(true))
  }

  return (
    <FormControl>
      <FormLabel id="access_radio_group" sx={{ fontWeight: "bold", fontSize: 18, mb: 2, color: "inherit" }}>
        Access type
      </FormLabel>
      <RadioGroup aria-labelledby="access_radio_group" onChange={handleChange}>
        <FormControlLabel
          value="shared"
          control={<Radio checked={showInternallyShared === true} />}
          label="Internally Shared"
        />
        <FormControlLabel
          value="private"
          control={<Radio checked={showInternallyShared === false} />}
          label="Private"
        />
        <FormControlLabel value="all" control={<Radio checked={showInternallyShared === undefined} />} label="All" />
      </RadioGroup>
    </FormControl>
  )
}

export default React.memo(FilterInternallyShared)
