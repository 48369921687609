import { Box, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import image from "../../assets/img/browseCatalog.png"
import { colorPalette } from "../../styling/theme"
import WidgetButton from "../Dashboard/Widgets/components/WidgetButton"

type Props = {
  code: number
  text: string
}

const ErrorPage = (p: Props) => {
  const nav = useNavigate()
  const clickBack = () => {
    nav("/")
  }

  return (
    <Box
      sx={{ backgroundColor: colorPalette.deepPurple, color: colorPalette.white }}
      height={500}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"relative"}
      flexDirection={"column"}
      overflow={"hidden"}
      borderRadius={3}>
      <img src={`/${p.code}.png`} alt={`${p.code}`} style={{ maxWidth: "500px", width: "100%", padding: "20px" }} />
      <Box display="flex" flexDirection={"column"} zIndex={1} sx={{ mt: 2 }}>
        <Typography sx={{ mb: 2, px: 3, textAlign: "center" }} variant={"h5"}>
          {p.text}
        </Typography>
        <WidgetButton
          textColor={colorPalette.deepPurple}
          backgroundColor={colorPalette.luminousGreen}
          sx={{ alignSelf: "center" }}
          onClick={clickBack}>
          Back to dashboard
        </WidgetButton>
      </Box>
      <Box width="calc(100%)" bottom={0} marginBottom={"-10px"} position={"absolute"} sx={{ opacity: 0.23 }}>
        <img src={image} width="100%" alt="" />
      </Box>
    </Box>
  )
}

export default React.memo(ErrorPage)
