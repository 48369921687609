import { InteractionStatus } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { type AuthorizedGroups } from "../../pages/Projects/project.helpers"
import type { LoggedInUser } from "../frontendTypes/datasets.types"
import { loginRequest } from "./msalConfig"

export const useUserEmail = (): string | undefined => {
  const { instance } = useMsal()
  return instance.getActiveAccount()?.idTokenClaims?.email as string | undefined
}

export const useUserInfo = (): LoggedInUser => {
  const { instance } = useMsal()
  const claims = instance.getActiveAccount()?.idTokenClaims

  if (!claims?.email) throw new Error("Missing token claims")
  const user: LoggedInUser = {
    name: claims.name as string,
    email: claims.email as string,
    family_name: claims.family_name as string | undefined,
    given_name: claims.given_name as string | undefined,
    groups: claims.groups as string[] | undefined,
    azureId: claims.oid as string,
  }
  return user
}

export const useLoginFn = () => {
  const { instance, inProgress } = useMsal()

  const handleLogin = () => {
    if (inProgress !== InteractionStatus.None) return
    instance.loginRedirect(loginRequest).catch(e => {
      console.log(e)
    })
  }

  return { handleLogin }
}

export const useUserAuthorizationGroups = (): AuthorizedGroups[] => {
  const { instance } = useMsal()
  const claims = instance.getActiveAccount()?.idTokenClaims

  return (claims?.groups as AuthorizedGroups[]) || []
}

export const useIsUserOwner = (ownerId?: string): boolean => {
  const userId = useUserInfo().azureId

  return userId === ownerId
}
