import React from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  ObservableCls,
  type ObservableDtoGet,
} from "../../../../../../../utils/sdk/utils/entities/sdk.observable.types"
import BBoxObservable from "./BBoxObservable"
import BrokenObservable from "./BrokenObservable"
import MyDataTimeRangeObservable from "./TimeRangeObservable"

const Observable = (p: ObservableDtoGet<any>) => {
  switch (p.spec.observable_class) {
    case ObservableCls.BBox:
      return (
        <ErrorBoundary fallback={<BrokenObservable {...p} />}>
          <BBoxObservable {...p} />
        </ErrorBoundary>
      )
    case ObservableCls.TimeRange:
      return (
        <ErrorBoundary fallback={<BrokenObservable {...p} />}>
          <MyDataTimeRangeObservable {...p} />
        </ErrorBoundary>
      )
    default:
      return <></>
  }
}

export default React.memo(Observable)
