import type { SxProps } from "@mui/material"
import React from "react"
import { StyledButton } from "../StyledButton"

type Props = {
  sx?: SxProps
  icon: React.ReactNode
  label: string
  onClick: () => void
  testId?: string
}

const LabeledIconButton = (p: Props) => {
  return (
    <StyledButton sx={{ p: 1, pl: 2, pr: 2, ...p.sx }} startIcon={p.icon} onClick={p.onClick} data-testid={p.testId}>
      {p.label}
    </StyledButton>
  )
}

export default React.memo(LabeledIconButton)
