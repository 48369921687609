import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { TextField } from "@mui/material"
import { debounce } from "lodash"
import React, { useState } from "react"
import { S } from "./AutocompleteSearch.style"

type Props = {
  initialValue?: string
  onSubmit: (searchString: string) => void
  size?: "small" | "medium"
}

const AutocompleteSearch = (props: Props) => {
  const [results, setResults] = useState<string[]>([])

  const autoCompleteSearch = async (searchString: string) => {
    const searchResults: string[] = []
    setResults(searchResults)
  }

  const handleSearchInputChange = async (searchString: string) => {
    if (searchString.length < 3) return
    await autoCompleteSearch(searchString)
  }

  const debouncedHandleSearchInputChange = debounce<(searchString: string) => any>(async searchString => {
    await handleSearchInputChange(searchString)
  }, 300)

  return (
    <S.AutoCompleteWrapper>
      <S.Autocomplete<string, undefined, undefined, true>
        id="dataCatalogAutoComplete"
        freeSolo
        defaultValue={props.initialValue}
        onInputChange={(_, val, reason) => {
          if (reason === "clear") {
            setResults([])
            return
          }
          void debouncedHandleSearchInputChange(val)
        }}
        onChange={(e, val, reason) => {
          const ev = e as React.KeyboardEvent
          if ((reason === "selectOption" || ev.key === "Enter") && val) {
            props.onSubmit(val)
          }
          if (reason === "clear") props.onSubmit(val ?? "")
        }}
        options={results}
        clearIcon={
          <S.ClearIconWrapper>
            <CloseIcon />
          </S.ClearIconWrapper>
        }
        disablePortal
        size={props.size}
        renderInput={params => (
          <TextField
            placeholder="Discover data"
            {...params}
            InputProps={{
              ...params.InputProps,
              onKeyUp: e => {
                const ev = e as React.KeyboardEvent
                if (ev.key === "Enter" && e.currentTarget.value === "") {
                  props.onSubmit("")
                }
              },
              // @ts-expect-error - mui data-testid not supported in input props yet
              "data-testid": "catalogSearchInput",
              endAdornment: (
                <>
                  <S.SearchIconWrapper>
                    <SearchIcon />
                  </S.SearchIconWrapper>
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={x => x} // overwrite mui built in filtering
      />
    </S.AutoCompleteWrapper>
  )
}

export default AutocompleteSearch
