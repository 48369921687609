import React from "react"
import { ObservableCls, type ObservableDtoGet } from "../../../../utils/sdk/utils/entities/sdk.observable.types"
import TimeRangeObservable from "../../../TimeRangeObservable"
import CatalogBBoxObservable from "./components/CatalogBBoxObservable"

const CatalogObservable = (p: ObservableDtoGet<any>) => {
  switch (p.spec.observable_class) {
    case ObservableCls.BBox:
      return <CatalogBBoxObservable {...p} />
    case ObservableCls.TimeRange:
      return <TimeRangeObservable rangeStart={p.spec.details.value[0]} rangeEnd={p.spec.details.value[1]} />
    default:
      return <></>
  }
}

export default React.memo(CatalogObservable)
