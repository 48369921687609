import { Link } from "@mui/material"
import React from "react"

import { type DataDetailsTableProps, type DataDetailsValue } from "../../DataDetailsTable"
import ValueWithCopyButton from "./components/ValueWithCopyButton"

const DataDetailsTableValue = (
  p: Omit<DataDetailsTableProps, "data"> & { value: DataDetailsValue; isLink: boolean; enableCopying?: boolean }
) => {
  if (!p.value) return null
  if (typeof p.value === "object" || typeof p.value === "boolean") return JSON.stringify(p.value)
  if (p.isLink && typeof p.value === "string") {
    return (
      <Link href={p.value} rel="noopener noreferrer" target="_blank">
        {p.value}
      </Link>
    )
  }
  if (p.enableCopying && typeof p.value === "string") return <ValueWithCopyButton value={p.value} />
  return p.value
}

export default React.memo(DataDetailsTableValue)
