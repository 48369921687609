import type { ResourceStatus } from "../sdk/utils/entities/sdk.resource.types"
import type { Nullable } from "../utils"
import type { DataEntity } from "./dataEntity.types"

export type PersonContact = {
  contactEmail: string
  contactLastName: string
  contactFirstName: string
}

export type PublisherContact = PersonContact & {
  publisherContactOrganisation: string
}

export type LicenseMetadata = {
  licenseFullText: string
  licenseHref: string
  licenseName: string
}

export type FillableMetadataDataCollection = Nullable<
  PublisherContact &
    LicenseMetadata & {
      publishedDate: string
      website: string
    }
>

export const fillableMetadataCollectionPropLabelMap: Record<keyof FillableMetadataDataCollection, string> = {
  publisherContactOrganisation: "Publisher organisation",
  contactFirstName: "Publisher contact first name",
  contactLastName: "Publisher contact last name",
  contactEmail: "Publisher contact email",
  publishedDate: "Published date",
  licenseFullText: "License full text",
  licenseHref: "License href",
  licenseName: "License name",
  website: "Website",
}

export const fillableMetadataCollectionProps = Object.keys(
  fillableMetadataCollectionPropLabelMap
) as (keyof FillableMetadataDataCollection)[]

export type CollectionMetadata = Partial<FillableMetadataDataCollection>

export type DataCollection = DataEntity & {
  metadata: CollectionMetadata
  status: ResourceStatus
}

export type createDataCollectionFrontend = {
  name: string
  description: string
  tags?: string[]
}
