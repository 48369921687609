import DownloadIcon from "@mui/icons-material/Download"
import React, { useCallback, useState } from "react"
import ExportCsvDialog from "./ExportCsvDialog"
import { StyledButton } from "./StyledButton"

type Props = {
  datasetNameId: string
}

const CsvExportButtonTrigger = (p: Props) => {
  const [exportModalOpen, setExportModalOpen] = useState(false)

  const closeModal = useCallback(() => {
    setExportModalOpen(false)
  }, [])

  const clickExport = useCallback(() => {
    setExportModalOpen(true)
  }, [])
  return (
    <>
      <StyledButton onClick={clickExport} sx={{ ml: 2, px: 2 }} startIcon={<DownloadIcon />}>
        Export to CSV
      </StyledButton>
      {exportModalOpen && <ExportCsvDialog open={exportModalOpen} closeFn={closeModal} datasetId={p.datasetNameId} />}
    </>
  )
}

export default React.memo(CsvExportButtonTrigger)
