import type { createDataCollectionFrontend } from "../frontendTypes/dataCollection.types"
import { DatasetType, type CreateDataSet } from "../frontendTypes/datasets.types"
import { createContact } from "../sdk/helpers"
import { DATA_COLLECTION_VERSION, type CreateDataCollectionDto } from "../sdk/utils/entities/sdk.dataCollection.types"
import {
  DATASET_VERSION,
  type CollectionRef,
  type ContactInfoDto,
  type DatasetDtoCreate,
} from "../sdk/utils/entities/sdk.dataset.types"
import { ResourceKind, StorageClass, StorageController } from "../sdk/utils/entities/sdk.resource.types"

export const slugify = (name: string) => name.toLowerCase().replaceAll(" ", "-")

const generateRandomString = (length: number) => {
  return window
    .btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(length * 2))))
    .replace(/[+/]/g, "")
    .substring(0, length)
}

export const createQualifiedName = (displayName: string) => {
  const cleanedName = displayName.replace(/[^a-zA-Z0-9 ]/g, "")
  return `${slugify(cleanedName)}-${generateRandomString(7)}`
}

export const createDatasetDto = (args: CreateDataSet): DatasetDtoCreate => {
  return {
    kind: ResourceKind.dataset,
    version: DATASET_VERSION,
    metadata: {
      name: createQualifiedName(args.name),
      display_name: args.name,
      description: args.description,
    },
    spec: {
      storage_class: args.type === DatasetType.Files ? StorageClass.Raw : StorageClass.Tabular,
      storage_controller: args.type === DatasetType.Files ? StorageController.Raw : StorageController.Tabular,
      maintainer: createMaintainer(args),
      tags: args.tags,
      data_collection: args.collectionId ? createCollectionRefernceName(args.collectionId) : undefined,
    },
  }
}

export const createCollectionRefernceName = (collectionName: string): CollectionRef => {
  return `${ResourceKind.collection}/${collectionName}`
}

export const createDataCollectionDto = (args: createDataCollectionFrontend): CreateDataCollectionDto => {
  return {
    kind: ResourceKind.collection,
    version: DATA_COLLECTION_VERSION,
    metadata: {
      name: createQualifiedName(args.name),
      display_name: args.name,
      description: args.description,
    },
    spec: {
      tags: args.tags,
    },
  }
}

/**
 * Returns a ContactInfoDto object. Throws an error if contact email or last name is missing, since API regex for contact requires both.
 */
export const createMaintainer = (args: CreateDataSet): ContactInfoDto | null => {
  if (!args.fillableMetadata?.contactEmail) throw new Error("Contact email is required")
  if (!args.fillableMetadata?.contactLastName) throw new Error("Contact last name is required")

  const contact = args.fillableMetadata?.contactEmail
    ? createContact({
        firstName: args.fillableMetadata?.contactFirstName,
        lastName: args.fillableMetadata?.contactLastName,
        email: args.fillableMetadata?.contactEmail,
      })
    : null

  if (!contact) return null

  return {
    contact,
  }
}

type PatchOperation = {
  op: "add" | "remove" | "replace" | "move" | "copy" | "test"
  path: string
  value: any
}

export const generatePatchOperations = (obj: Record<string, any>): PatchOperation[] => {
  const flatObject = oneLevel(obj)
  return Object.entries(flatObject)
    .filter(el => el !== undefined)
    .map(([key, value]): PatchOperation | undefined => {
      return {
        op: "add",
        path: `/${key}`,
        value,
      }
    }) as PatchOperation[]
}

export const generatePatchOperationsNested = (obj: Record<string, any>): PatchOperation[] => {
  const flatObject = flattenObject(obj)
  return Object.entries(flatObject)
    .filter(el => el !== undefined)
    .map(([key, value]): PatchOperation | undefined => {
      return {
        op: "add",
        path: `/${key}`,
        value,
      }
    }) as PatchOperation[]
}

export const flattenObject = (ob: Record<string, any>) => {
  const toReturn: Record<string, any> = {}

  Object.entries(ob).forEach(([key, val]) => {
    if (typeof val === "object" && val !== null) {
      const flatObject = flattenObject(val as object)
      Object.entries(flatObject).forEach(([inKey, inVal]) => {
        toReturn[key + "/" + inKey] = inVal
      })
    } else {
      toReturn[key] = val
    }
  })
  return toReturn
}

export const oneLevel = (ob: Record<string, any>) => {
  const toReturn: Record<string, any> = {}

  Object.entries(ob).forEach(([key, val]) => {
    if (typeof val === "object" && val !== null) {
      Object.entries(val as object).forEach(([inKey, inVal]) => {
        toReturn[key + "/" + inKey] = inVal
      })
    } else {
      toReturn[key] = val
    }
  })
  return toReturn
}
