import mapboxgl from "mapbox-gl"
import { useEffect, useState } from "react"
import { colorPalette } from "../../styling/theme"
import { getBoundsToShowOnMap } from "./minimap.helpers"

type MapStyling = {
  setup: {
    style: string
    projection?: mapboxgl.ProjectionSpecification
    bounds?: mapboxgl.LngLatBoundsLike
  }
  color: string
}

export type MapVersion = "globe" | "standardBlue"

export const predefinedMaps: Record<MapVersion, MapStyling> = {
  globe: {
    setup: {
      style: "mapbox://styles/oceandatafoundation/clsvq63wb006201qz9s1lhvnc",
      projection: {
        name: "globe",
        center: [0, 0],
        parallels: [30, 30],
      },
      bounds: [-180, -90, 180, 90],
    },
    color: colorPalette.luminousGreen,
  },
  standardBlue: {
    setup: {
      style: "mapbox://styles/oceandatafoundation/clsvq63wb006201qz9s1lhvnc",
      bounds: [-170, -75, 170, 80],
      projection: {
        name: "mercator",
        center: [0, 0],
        parallels: [0, 0],
      },
    },
    color: colorPalette.deepPurple,
  },
}

const CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID = "catalogMapBBoxSource"
const CATALOG_SEARCH_BBOX_MAPBOX_LAYER_ID = "catalogMapBBoxLayer"
const CATALOG_SEARCH_BBOX_MAPBOX_LAYER_FILL_ID = "catalogMapBBoxLayerFill"
const CATALOG_SEARCH_BBOX_MAPBOX_LAYER_CIRCLE_ID = "catalogMapBBoxLayerCircle"

export const useBBoxMiniMap = ({
  containerId,
  bbox,
  mapVersion,
}: {
  containerId: string
  bbox?: GeoJSON.Position[]
  mapVersion?: MapVersion
}) => {
  useMiniMap({
    containerId,
    geometry: bbox ? { type: "Polygon", coordinates: [bbox] } : undefined,
    interactive: true,
    mapStyling: mapVersion ? predefinedMaps[mapVersion] : undefined,
  })
}

export const useMiniMap = ({
  containerId,
  geometry,
  interactive,
  mapStyling,
}: {
  containerId: string
  mapStyling?: MapStyling
  geometry?: GeoJSON.Geometry
  interactive: boolean
}) => {
  const [map, setMap] = useState<mapboxgl.Map>()
  const [mapLoaded, setMapLoaded] = useState(false)

  const bboxWithPadding = getBoundsToShowOnMap(geometry)

  const mapStylingUsed = mapStyling ?? predefinedMaps.globe

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: containerId,
      ...mapStylingUsed.setup,
      interactive,
      accessToken:
        "pk.eyJ1Ijoib2NlYW5kYXRhZm91bmRhdGlvbiIsImEiOiJjazk5bGxpNWkwYWU1M2Vya3hkcHh4czdrIn0.yf7kIiPfDNE7KP9_9wTN6A",
    })

    setMap(map)
  }, [containerId, interactive, mapStylingUsed])

  useEffect(() => {
    if (!map) return
    if (map.loaded()) return setMapLoaded(true)
    map.on("load", () => setMapLoaded(true))
  }, [map])

  useEffect(() => {
    if (map && mapLoaded) {
      const source = map.getSource(CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID)
      if (source) {
        map.removeLayer(CATALOG_SEARCH_BBOX_MAPBOX_LAYER_ID)
        map.removeLayer(CATALOG_SEARCH_BBOX_MAPBOX_LAYER_FILL_ID)
        map.removeLayer(CATALOG_SEARCH_BBOX_MAPBOX_LAYER_CIRCLE_ID)
        map.removeSource(CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID)
      }

      if (!geometry) {
        map.fitBounds(mapStylingUsed.setup.bounds as mapboxgl.LngLatBoundsLike)
        return
      }

      map.addSource(CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID, {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry,
        },
      })

      addLayers(map, geometry, mapStylingUsed)

      map.fitBounds([bboxWithPadding.minLong, bboxWithPadding.minLat, bboxWithPadding.maxLong, bboxWithPadding.maxLat])
    }
  }, [map, geometry, mapLoaded, bboxWithPadding, mapStylingUsed])
}

const FILL_TYPES: GeoJSON.Geometry["type"][] = ["Polygon", "MultiPolygon", "GeometryCollection"]
const LINE_TYPES: GeoJSON.Geometry["type"][] = [...FILL_TYPES, "LineString", "MultiLineString"]

const addLayers = (map: mapboxgl.Map, geometry: GeoJSON.Geometry, mapStyling: MapStyling) => {
  const isFillType = FILL_TYPES.includes(geometry.type)
  const isLineType = LINE_TYPES.includes(geometry.type)

  if (isFillType) {
    map.addLayer({
      id: CATALOG_SEARCH_BBOX_MAPBOX_LAYER_FILL_ID,
      type: "fill",
      source: CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID,
      layout: {},
      paint: {
        "fill-color": mapStyling.color,
        "fill-opacity": 0.2,
      },
    })
  }
  if (isLineType) {
    map.addLayer({
      id: CATALOG_SEARCH_BBOX_MAPBOX_LAYER_ID,
      type: "line",
      source: CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID,
      layout: {},
      paint: {
        "line-color": mapStyling.color,
        "line-width": 2,
      },
    })
  }
  // for points and every other type
  map.addLayer({
    id: CATALOG_SEARCH_BBOX_MAPBOX_LAYER_CIRCLE_ID,
    type: "circle",
    source: CATALOG_SEARCH_BBOX_MAPBOX_SOURCE_ID,
    paint: {
      "circle-radius": 3,
      "circle-color": mapStyling.color,
    },
  })
}
