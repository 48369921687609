import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { MenuItem, Typography } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../styling/theme"

export type NavItemProps = {
  pageName: string
  isExternal?: boolean
  onClick: () => void
}

const HamMenuItem = (p: NavItemProps) => {
  return (
    <MenuItem onClick={p.onClick}>
      <Typography textAlign="center">{p.pageName}</Typography>
      {p.isExternal && <OpenInNewIcon sx={{ fontSize: "medium", ml: 1, color: colorPalette.deepPurpleO60 }} />}
    </MenuItem>
  )
}

export default React.memo(HamMenuItem)
