import { TextField, type TextFieldProps } from "@mui/material"
import React from "react"
import { generateNumberRegex, getKeyDownChar, isPasteEvent } from "./numberInput.helpers"

type Props = TextFieldProps & {
  numberInputProps?: {
    allowDecimal?: boolean
    allowNegative?: boolean
  }
}

const NumInput = (props: Props) => {
  const { numberInputProps, ...textFieldProps } = props
  const { allowDecimal = false, allowNegative = false } = numberInputProps ?? {
    allowDecimal: false,
    allowNegative: false,
  }

  const numberRegex = generateNumberRegex(allowNegative, allowDecimal)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isPasteEvent(e)) return

    const char = getKeyDownChar(e)
    if (!char) return
    const target = e.target as HTMLInputElement
    if (target.selectionStart == null || target.selectionEnd == null) return
    const resultingStr =
      target.value.substring(0, target.selectionStart) + char + target.value.substring(target.selectionEnd)
    if (!resultingStr.match(numberRegex)) {
      e.preventDefault()
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    const text = e.clipboardData?.getData("Text")
    if (!text?.trim().match(numberRegex)) {
      e.preventDefault()
    }
  }

  return <TextField {...textFieldProps} onKeyDown={handleKeyDown} onPaste={handlePaste} />
}

export default React.memo(NumInput)
