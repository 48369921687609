import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, InputAdornment, TextField, type TextFieldProps } from "@mui/material"
import React, { useImperativeHandle, useRef, useState } from "react"

type Props = {
  clearCallback?: () => void
  alwaysShowClear?: boolean
}

const ClearableInput = React.forwardRef((p: TextFieldProps & Props, forwardedRef) => {
  const { alwaysShowClear, clearCallback, onChange, ...textFieldProps } = p
  const [showClear, setShowClear] = useState(false)

  const ref = useRef<HTMLInputElement | null>(null)
  useImperativeHandle(forwardedRef, () => ref.current)

  const onChangeWithClear = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setShowClear(true)
    } else {
      setShowClear(false)
    }
    onChange?.(e)
  }

  const clear = () => {
    if (ref.current) {
      ref.current.value = ""
      if (clearCallback) clearCallback()
    }
    setShowClear(false)
  }

  return (
    <TextField
      size="small"
      inputRef={ref}
      onChange={onChangeWithClear}
      InputProps={{
        sx: { pr: 0.5 },
        endAdornment: (
          <InputAdornment position="end" sx={{ visibility: showClear || alwaysShowClear ? "visible" : "hidden" }}>
            <IconButton onClick={clear} size="small">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...textFieldProps}
    />
  )
})

ClearableInput.displayName = "ClearableInput"

export default React.memo(ClearableInput)
