import { Box } from "@mui/material"
import React from "react"
import CsvExportButtonTrigger from "../../../components/CsvExportButtonTrigger"
import type { DataSet } from "../../../utils/frontendTypes/datasets.types"
import { S } from "../../CatalogCollection/CatalogCollection.style"
import CatalogSampleTable from "./CatalogSampleTable"

type Props = {
  dataset: DataSet
}

const CatalogTabularData = (p: Props) => {
  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <S.SectionHeader sx={{ mb: 3, mt: 3 }}>Data</S.SectionHeader>
        <Box>
          <CsvExportButtonTrigger datasetNameId={p.dataset.nameId} />
        </Box>
      </Box>
      <CatalogSampleTable datasetNameId={p.dataset.nameId} />
    </>
  )
}

export default React.memo(CatalogTabularData)
