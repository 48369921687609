import { Grid } from "@mui/material"
import { debounce } from "lodash"
import { useEffect, useMemo, useState } from "react"
import NumberInput from "../NumericInput/NumberInput"

type Props = {
  fullWidth?: boolean
  setFilterValues: (startYear?: number, endYear?: number) => void
}

const FilterTimeRange = (p: Props) => {
  const [startYearError] = useState("")
  const [endYearError] = useState("")

  const [localStartYear, setLocalStartYear] = useState<number | undefined>()
  const [localEndYear, setLocalEndYear] = useState<number | undefined>()

  const restrictedValue = (value?: number) => {
    if (value === undefined || isNaN(value)) return undefined
    const max = new Date().getFullYear() + 1
    if (value && value < 0) return 0
    if (value && value > max) return max
    return value
  }

  const startYearChange = (value?: number) => {
    const valueToSet = restrictedValue(value)
    setLocalStartYear(valueToSet)
  }

  const endYearChange = (value?: number) => {
    const valueToSet = restrictedValue(value)
    setLocalEndYear(valueToSet)
  }

  const setFilterValue = useMemo(
    () => debounce(() => p.setFilterValues(localStartYear, localEndYear), 500),
    [localStartYear, localEndYear, p]
  )

  useEffect(() => {
    setFilterValue()

    return () => {
      setFilterValue.cancel()
    }
  }, [setFilterValue])

  return (
    <Grid container spacing={2} columnSpacing={3}>
      <Grid item sx={{ width: p.fullWidth ? "100%" : undefined }}>
        <NumberInput
          label="From"
          size="small"
          placeholder="YYYY"
          error={!!startYearError}
          helperText={startYearError}
          onChange={e => startYearChange(parseInt(e.target.value))}
          value={localStartYear ?? ""}
          fullWidth
        />
      </Grid>
      <Grid item sx={{ width: p.fullWidth ? "100%" : undefined }}>
        <NumberInput
          label="To"
          size="small"
          placeholder="YYYY"
          value={localEndYear ?? ""}
          error={!!endYearError}
          helperText={endYearError}
          onChange={e => endYearChange(parseInt(e.target.value))}
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default FilterTimeRange
