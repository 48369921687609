import { divideFacets } from "../../pages/CatalogDataset/components/DataInteraction/dataInteraction.helpers"
import { mapStorageClassToDatasetType } from "../api/requests.types"
import { extractContactDetails } from "../sdk/helpers"
import type { DataCollectionDtoGet } from "../sdk/utils/entities/sdk.dataCollection.types"
import type { DatasetDtoGet } from "../sdk/utils/entities/sdk.dataset.types"
import { INTERNALY_SHARED_LABEL, RELEASED_LABEL } from "../sdk/utils/entities/sdk.resource.types"
import type { Nullable } from "../utils"
import type { DataCollection, PersonContact } from "./dataCollection.types"
import type { CatalogResult } from "./dataEntity.types"
import { collectionIdFromRef, type DataSet, type FillableMetadataDataset, type LoggedInUser } from "./datasets.types"

export const mapToDataset = (dto: DatasetDtoGet): DataSet => {
  const isPublic = dto.metadata.labels?.[RELEASED_LABEL] === true
  const isInternalyShared = dto.metadata.labels?.[INTERNALY_SHARED_LABEL] === true

  const { specialFacets, otherFacets } = divideFacets(dto.spec.facets)

  return {
    uuid: dto.metadata.uuid,
    description: dto.metadata.description ?? "Missing dataset description",
    name: dto.metadata.display_name ?? dto.metadata.name,
    nameId: dto.metadata.name,
    tags: dto.spec?.tags,
    fillableMetadata: mapDatasetDtoToFillableMetadata(dto),
    attributes: dto.spec.attributes,
    collectionId: dto.spec.data_collection ? collectionIdFromRef(dto.spec.data_collection) : undefined,
    type: mapStorageClassToDatasetType(dto.spec.storage_class),
    specialFacets,
    otherFacets,
    isPublic,
    isInternalyShared,
    ownerId: dto.metadata.owner,
  }
}

export const mapToDataCollection = (dto: DataCollectionDtoGet): DataCollection => {
  const isPublic = dto.metadata.labels?.[RELEASED_LABEL] === true
  const isInternalyShared = dto.metadata.labels?.[INTERNALY_SHARED_LABEL] === true

  const contactPersonDetailsParseResult = extractContactDetails(dto.spec.distribution?.published_by?.contact)

  const publisherPersonDetails: Record<string, any> = contactPersonDetailsParseResult ?? {}

  return {
    description: dto.metadata.description ?? "",
    name: dto.metadata.display_name ?? dto.metadata.name,
    nameId: dto.metadata.name,
    uuid: dto.metadata.uuid,
    tags: dto.spec.tags,
    metadata: {
      publisherContactOrganisation: dto.spec.distribution?.published_by?.organisation,
      ...publisherPersonDetails,
      publishedDate: dto.spec.distribution?.published_date,

      licenseFullText: dto.spec.distribution?.license?.full_text,
      licenseHref: dto.spec.distribution?.license?.href,
      licenseName: dto.spec.distribution?.license?.name,

      website: dto.spec.distribution?.website,
    },
    status: dto.status,
    isPublic,
    isInternalyShared,
    ownerId: dto.metadata.owner,
  }
}

export const mapToCatalogResult = (apiDataset: DatasetDtoGet | DataCollectionDtoGet): CatalogResult => {
  return {
    description: apiDataset.metadata.description ?? "",
    name: apiDataset.metadata.display_name ?? apiDataset.metadata.name,
    tags: apiDataset.spec.tags ?? [],
    uuid: apiDataset.metadata.uuid,
    nameId: apiDataset.metadata.name,
  }
}

const mapDatasetDtoToFillableMetadata = (datasetDto: DatasetDtoGet): Partial<Nullable<FillableMetadataDataset>> => {
  const maintainerContactDetails = extractContactDetails(datasetDto.spec?.maintainer?.contact)

  return {
    citation: datasetDto.spec.citation?.cite_as,
    doi: datasetDto.spec.citation?.doi,
    maintanierOrganisation: datasetDto.spec.maintainer?.organisation,
    ...maintainerContactDetails,
  }
}

export const mapLoggedInUserInfoToContact = (userInfo: LoggedInUser): PersonContact => {
  let contactLastName = ""

  if (userInfo.family_name) {
    contactLastName = userInfo.family_name
  } else if (userInfo.name) contactLastName = userInfo.name

  return {
    contactEmail: userInfo.email ?? "",
    contactFirstName: userInfo.given_name ?? "",
    contactLastName,
  }
}
