import { Typography } from "@mui/material"
import React, { useCallback } from "react"
import { type Facets } from "../../../../../../../utils/sdk/utils/entities/sdk.dataset.types"
import DialogSectionHeader from "./DialogSectionHeader"
import FacetDisplay from "./FacetDisplay"

type Props = {
  facets?: Facets | null
  facetsToDelete: string[]
  setFacetsToDelete: (keys: string[]) => void
}

const CurrentFacets = (p: Props) => {
  const noFacets = !p.facets || Object.keys(p.facets).length === 0

  const { facetsToDelete, setFacetsToDelete } = p

  const deleteFn = useCallback(
    (key: string) => {
      facetsToDelete ? setFacetsToDelete?.([...facetsToDelete, key]) : setFacetsToDelete?.([key])
    },
    [facetsToDelete, setFacetsToDelete]
  )

  const restoreFn = useCallback(
    (key: string) => {
      setFacetsToDelete(facetsToDelete.filter(el => el !== key))
    },
    [facetsToDelete, setFacetsToDelete]
  )

  return (
    <>
      <DialogSectionHeader title="Current Facets: " />
      {noFacets && <Typography>There are no defined facets.</Typography>}
      {!noFacets &&
        p.facets &&
        Object.entries(p.facets).map(([key, value]) => (
          <FacetDisplay
            key={key}
            value={value}
            facetKey={key}
            deleted={p.facetsToDelete?.includes(key)}
            deleteFn={deleteFn}
            restoreFn={restoreFn}
          />
        ))}
    </>
  )
}

export default React.memo(CurrentFacets)
