import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import { Box, IconButton, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import { colorPalette } from "../../styling/theme"

type Props = {
  optionalButton?: React.ReactNode
  name: string
  type: "Dataset" | "Data Collection"
  headerComponent?: React.ReactNode | null
}

const ResourceHeader = (p: Props) => {
  const nav = useNavigate()

  const onBackClick = () => {
    nav(-1)
  }

  return (
    <>
      <Box sx={{ position: "relative", display: "flex" }}>
        <Typography variant="h4" component="h1" sx={{ position: "relative", fontWeight: 500 }}>
          {p.name}
        </Typography>
        <IconButton
          color="inherit"
          sx={{ position: "absolute", left: "-42px", display: "flex", top: "1px" }}
          onClick={onBackClick}
          data-testid={"goBackBtn"}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {p.optionalButton}
      </Box>
      <Typography color={colorPalette.deepPurpleO60} fontSize={14}>
        {p.type}
        {p.headerComponent}
      </Typography>
    </>
  )
}

export default React.memo(ResourceHeader)
