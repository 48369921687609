import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import { StyledButton } from "../../../../../../../../../components/StyledButton"
import CatalogSampleTable from "../../../../../../../../CatalogDataset/components/CatalogSampleTable"

type Props = {
  open: boolean
  close: () => void
  nameId: string
}

const UploadedDataModal = (p: Props) => {
  return (
    <Dialog open={p.open} onClose={p.close} fullWidth maxWidth="xl" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle id="alert-dialog-title" fontWeight={700}>
        {"Data Sample"}
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center", minHeight: "150px" }}>
        <CatalogSampleTable datasetNameId={p.nameId} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <StyledButton variant="contained" onClick={() => p.close()}>
          Close
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(UploadedDataModal)
