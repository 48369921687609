import ArticleIcon from "@mui/icons-material/Article"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DownloadIcon from "@mui/icons-material/Download"
import { IconButton, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import React from "react"
import { Loader } from "../../../../../../../components/Loader/Loader"
import { type RawFileInfoGet } from "../../../../../../../utils/api/raw.types"
import { type DataSet } from "../../../../../../../utils/frontendTypes/datasets.types"
import { useDeleteRawFileForDatasetByNameIdMutation } from "../../../../../../../utils/redux/queries/raw.queries"
import { addRecentlyDeletedFileIdRA } from "../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../utils/redux/store"
import { createFileId, downloadRawFile } from "./rawFile.helpers"

const DisplayRawFileInfo = (p: { fileInfo: RawFileInfoGet } & { dataset: DataSet }) => {
  const dispatch = useAppDispatch()
  const clickDownload = async () => await downloadRawFile(p.dataset.nameId, p.fileInfo.name)

  const [deleteRawFile, { isLoading }] = useDeleteRawFileForDatasetByNameIdMutation()

  const clickDelete = async () => {
    const confirmed = window.confirm("Are you sure you want to delete this file?")
    if (!confirmed) return
    const result = await deleteRawFile({ datasetNameId: p.dataset.nameId, fileName: p.fileInfo.name })
    const resultSuccess = "data" in result
    if (resultSuccess) {
      dispatch(addRecentlyDeletedFileIdRA(createFileId(p.fileInfo)))
    }
  }

  return (
    <>
      <ListItem key={p.fileInfo.name + "_file"}>
        <ListItemIcon style={{ minWidth: 40 }}>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography>file name: {p.fileInfo.name}</Typography>
          <Typography sx={{ fontSize: 14 }}>type: {p.fileInfo.mime_type}</Typography>
          <Typography sx={{ fontSize: 14 }}>size: {p.fileInfo.size_bytes}</Typography>
        </ListItemText>

        {!isLoading && (
          <>
            <IconButton onClick={clickDownload} data-testid="downloadFileBtn">
              <DownloadIcon />
            </IconButton>
            <IconButton onClick={clickDelete} data-testid="deleteFileBtn">
              <DeleteOutlineIcon />
            </IconButton>
          </>
        )}
        {isLoading && <Loader size={25} />}
      </ListItem>
    </>
  )
}

export default React.memo(DisplayRawFileInfo)
