import React, { useCallback } from "react"
import FilterTimeRange from "../../../../../components/FilterTimeRange/FilterTimeRange"
import { setMyDataYearsFilterRA } from "../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../utils/redux/store"

const FilterTimeRangeMyData = () => {
  const dispatch = useAppDispatch()
  const setFilterValues = useCallback(
    (startYear?: number, endYear?: number) => {
      dispatch(setMyDataYearsFilterRA({ startYear, endYear }))
    },
    [dispatch]
  )

  return <FilterTimeRange fullWidth setFilterValues={setFilterValues} />
}

export default React.memo(FilterTimeRangeMyData)
