import { AppBar, Box, Container, Toolbar } from "@mui/material"
import { Link } from "react-router-dom"
import { applicationSegments, externalSegments, type ExternalPageNames, type PageNames } from "../navigation.helpers"
import DesktopNav from "./components/DesktopNav/DesktopNav"
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu"
import LogoIcon from "./components/LogoIcon"
import NavUser from "./components/NavUser/NavUser"

const Navbar = () => {
  const pages = Object.keys(applicationSegments) as PageNames[]
  const externalPages = Object.keys(externalSegments) as ExternalPageNames[]

  return (
    <AppBar position="relative" elevation={0}>
      <Container maxWidth="xl" sx={{ position: "relative" }}>
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", mr: 2 }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src="/ODP-Logo.svg" alt="Ocean Data Platform" style={{ maxHeight: "100%" }} />
            </Link>
          </Box>
          <HamburgerMenu pages={pages} externalPages={externalPages} />

          <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: "center", pl: 3, mr: 2, flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", height: "40px" }}>
              <LogoIcon sx={{ color: "white", width: "40px", height: "40px" }} />
            </Link>
          </Box>

          <DesktopNav pages={pages} externalPages={externalPages} />
          <NavUser />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar
