import { useIsAuthenticated } from "@azure/msal-react"
import React from "react"
import { Outlet } from "react-router-dom"
import Unauthorized from "../pages/Unauthorized/Unauthorized"

const PrivateRoute = (p: React.PropsWithChildren) => {
  const isAuthenticated = useIsAuthenticated()

  if (!isAuthenticated) return <Unauthorized />

  return <>{p.children ? p.children : <Outlet />}</>
}

export default PrivateRoute
