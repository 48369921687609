import React from "react"
import { DatasetType, type DataSet } from "../../../../utils/frontendTypes/datasets.types"
import DatasetDetailsLeftColumnRaw from "./components/DatasetDetailsLeftColumnRaw/DatasetDetailsLeftColumnRaw"
import DatasetDetailsLeftColumnTabular from "./components/MyDataContentTabular/DatasetDetailsLeftColumnTabular"

type Props = {
  dataset: DataSet
}

const MyDatasetContent = (p: Props) => {
  if (p.dataset.type === DatasetType.Tabular) return <DatasetDetailsLeftColumnTabular dataset={p.dataset} />
  if (p.dataset.type === DatasetType.Files) return <DatasetDetailsLeftColumnRaw dataset={p.dataset} />
}

export default React.memo(MyDatasetContent)
