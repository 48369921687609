import { IconButton } from "@mui/material"
import React from "react"

type Props = {
  icon: React.ReactNode
  clickEdit: () => void
}

const OptionalEditButton = (p: Props) => {
  return (
    <IconButton data-testid={"editResourceBtn"} color="inherit" onClick={p.clickEdit} sx={{ ml: 1 }}>
      {p.icon}
    </IconButton>
  )
}

export default React.memo(OptionalEditButton)
