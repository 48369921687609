import { Grid, Typography } from "@mui/material"
import React from "react"
import AutocompleteSearchMyData from "./AutocompleteSearchMyData"
import FilterInternallyShared from "./FilterInternallyShared"
import FilterTimeRangeMyData from "./FilterTimeRangeMyData"
import MyDataMap from "./MyDataMap"

const MyDataFilters = () => {
  const openRefineLocation = () => {}

  return (
    <Grid container columnSpacing={4} rowSpacing={2} sx={{ mt: 1 }}>
      <Grid item width={{ xs: "100%", sm: 350 }} sm={12} md={4}>
        <AutocompleteSearchMyData />
        <Typography sx={{ fontWeight: "bold", fontSize: 18, mt: 2, mb: 1 }}>Date Range</Typography>
        <FilterTimeRangeMyData />
      </Grid>
      <Grid item sx={{ width: { xs: "100%", sm: "auto" } }} sm={6} md={4}>
        <MyDataMap openRefineLocation={openRefineLocation} />
      </Grid>
      <Grid item sx={{ width: { xs: "100%", sm: 350 } }} sm={6} md={4}>
        <FilterInternallyShared />
      </Grid>
    </Grid>
  )
}

export default React.memo(MyDataFilters)
