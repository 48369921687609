import { Box, Typography } from "@mui/material"
import React from "react"

type Props = {
  description: string
}

const WidgetContent = (p: Props) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography>{p.description}</Typography>
    </Box>
  )
}

export default React.memo(WidgetContent)
