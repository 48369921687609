import { Box } from "@mui/material"
import React, { useEffect } from "react"
import { type BoundingMapboxCoords } from "../../../../../utils/frontendTypes/datasets.types"
import { useMapWithDraw } from "../useMapWithDraw"

type Props = {
  setSelectedCoords: React.Dispatch<number[][] | undefined>
  numericCoords?: BoundingMapboxCoords
}

const CatalogRefineMap = ({ setSelectedCoords, numericCoords }: Props) => {
  const { coordinates, draw } = useMapWithDraw(undefined, "refine-map")

  useEffect(() => {
    setSelectedCoords(coordinates)
  }, [coordinates, setSelectedCoords])

  useEffect(() => {
    if (!draw || !numericCoords) return

    const currentFeature = draw?.getAll().features[0]

    const feature = {
      id: currentFeature.id,
      type: "Feature" as const,
      properties: {},
      geometry: {
        type: "Polygon" as const,
        coordinates: [numericCoords],
      },
    }
    draw?.add(feature)
    setSelectedCoords(numericCoords)
    draw.changeMode("simple_select")
  }, [setSelectedCoords, numericCoords, draw])

  return (
    <>
      <Box sx={{ width: "100%", height: "850px", position: "relative" }}>
        <div id="refine-map" style={{ width: "100%", height: "100%" }} />
      </Box>
    </>
  )
}

export default React.memo(CatalogRefineMap)
