import React from "react"
import { type DataSet } from "../../../utils/frontendTypes/datasets.types"
import { generateJSONLD } from "../catalogDataset.helpers"

const JsonLd = (p: DataSet) => {
  const jsonContent = generateJSONLD(p)

  return <script type="application/ld+json">{JSON.stringify(jsonContent)}</script>
}

export default React.memo(JsonLd)
