import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { parse } from "wellknown"
import WktMapModal from "../../WktMapModal/WktMapModal"

type Props = {
  wkt: string
}

const WktVisualizeButton = (p: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [geometry, setGeometry] = useState<GeoJSON.Geometry | null>(null)

  const visualizationPossible = !!geometry

  const openDialog = () => {
    setDialogOpen(true)
  }

  const closeDialog = () => {
    setDialogOpen(false)
  }

  useEffect(() => {
    try {
      const parsedGeometry = parse(p.wkt)
      setGeometry(parsedGeometry)
    } catch (e) {
      setGeometry(null)
    }
  }, [p.wkt])

  if (!visualizationPossible) return null

  return (
    <>
      <IconButton onClick={openDialog} disabled={!visualizationPossible}>
        <OpenInNewIcon />
      </IconButton>
      <WktMapModal geometry={geometry} closeFn={closeDialog} open={dialogOpen} />
    </>
  )
}

export default React.memo(WktVisualizeButton)
