import { Box, Typography } from "@mui/material"
import React from "react"
import { colorPalette } from "../../../../../../../../../styling/theme"

const CollectionOption = (p: any) => {
  return (
    <Box>
      <Typography>{p.name}</Typography>
      <Typography variant="subtitle1" sx={{ color: colorPalette.deepPurpleO50, fontSize: "0.9rem" }}>
        {p.nameId}
      </Typography>
    </Box>
  )
}

export default React.memo(CollectionOption)
