import { SvgIcon, type SvgIconTypeMap } from "@mui/material"
import { type DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

const WorkspaceIcon = (p: DefaultComponentProps<SvgIconTypeMap<unknown, "svg">>) => {
  return (
    <SvgIcon {...p}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.047 14.2131C21.335 14.2131 21.6165 14.1277 21.856 13.9677C22.0954 13.8077 22.2821 13.5803 22.3923 13.3142C22.5025 13.0481 22.5313 12.7553 22.4752 12.4729C22.419 12.1904 22.2803 11.9309 22.0766 11.7273C21.873 11.5236 21.6135 11.3849 21.3311 11.3288C21.0486 11.2726 20.7558 11.3014 20.4897 11.4116C20.2237 11.5218 19.9962 11.7085 19.8362 11.9479C19.6762 12.1874 19.5908 12.4689 19.5908 12.7569C19.5908 13.1431 19.7442 13.5135 20.0173 13.7866C20.2904 14.0597 20.6608 14.2131 21.047 14.2131Z"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M11.4537 21.9792C11.7417 21.9792 12.0233 21.8938 12.2627 21.7338C12.5022 21.5738 12.6888 21.3464 12.799 21.0803C12.9093 20.8142 12.9381 20.5214 12.8819 20.239C12.8257 19.9565 12.687 19.697 12.4834 19.4934C12.2797 19.2897 12.0203 19.1511 11.7378 19.0949C11.4553 19.0387 11.1626 19.0675 10.8965 19.1777C10.6304 19.288 10.403 19.4746 10.243 19.7141C10.083 19.9535 9.99756 20.2351 9.99756 20.5231C9.99756 20.9093 10.151 21.2796 10.4241 21.5527C10.6971 21.8258 11.0675 21.9792 11.4537 21.9792Z"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M16.0226 19.6955C16.3106 19.6955 16.5921 19.6101 16.8316 19.4501C17.071 19.2901 17.2577 19.0627 17.3679 18.7966C17.4781 18.5305 17.5069 18.2377 17.4507 17.9553C17.3946 17.6728 17.2559 17.4134 17.0522 17.2097C16.8486 17.0061 16.5891 16.8674 16.3067 16.8112C16.0242 16.755 15.7314 16.7838 15.4653 16.894C15.1992 17.0043 14.9718 17.1909 14.8118 17.4304C14.6518 17.6698 14.5664 17.9514 14.5664 18.2394C14.5664 18.6256 14.7198 18.9959 14.9929 19.269C15.266 19.5421 15.6364 19.6955 16.0226 19.6955Z"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M11.4843 19.0672V15.1841L8.1709 11.7578"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M20.5123 22.9997C20.8003 22.9997 21.0818 22.9143 21.3213 22.7543C21.5608 22.5943 21.7474 22.3669 21.8576 22.1008C21.9678 21.8347 21.9967 21.5419 21.9405 21.2595C21.8843 20.977 21.7456 20.7176 21.542 20.5139C21.3383 20.3103 21.0789 20.1716 20.7964 20.1154C20.5139 20.0592 20.2211 20.088 19.9551 20.1982C19.689 20.3085 19.4616 20.4951 19.3016 20.7346C19.1416 20.974 19.0562 21.2556 19.0562 21.5436C19.0562 21.9298 19.2096 22.3001 19.4827 22.5732C19.7557 22.8463 20.1261 22.9997 20.5123 22.9997Z"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M4.16943 12.9187C5.19624 13.2352 5.985 13.203 6.98312 12.8052L10.6777 9.91235L14.5998 6.55371C14.9228 5.52804 14.9123 5.26771 14.5077 4.27138C14.1055 3.27495 12.0897 2.58306 10.8211 2.13405C9.66157 1.72362 7.2909 1.19095 6.22893 1.35489C5.15942 1.50234 4.17505 2.01888 3.44601 2.81518C2.72393 3.6127 2.29036 4.62947 2.21474 5.70265C2.13913 6.77583 2.31524 8.19226 2.67106 9.77813C3.12853 11.8171 3.14263 12.6022 4.16943 12.9187Z"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M20.5125 20.0873V18.2671L11.3687 9.24463"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M19.5913 12.4423L16.6219 11.5286L12.7388 7.64551"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
        <path
          d="M15.0227 17.0109L13.4238 14.0415L9.99756 10.7212"
          stroke="#9D59F4"
          strokeWidth="1.34462"
          strokeMiterlimit="10"
        />
      </svg>
    </SvgIcon>
  )
}

export default React.memo(WorkspaceIcon)
