import { Grid } from "@mui/material"
import React, { useCallback, useState } from "react"
import { StyledButton } from "../../../../../../../../components/StyledButton"
import { StyledSectionHeader } from "../../../../../../../../components/StyledTypography"
import UploadedDataModal from "./components/UploadedDataModal"

type Props = {
  nameId: string
}

const UploadedDataSample = (p: Props) => {
  const [showAddObservableModal, setShowAddObservableModal] = useState(false)

  const closeFn = useCallback(() => {
    setShowAddObservableModal(false)
  }, [])

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StyledSectionHeader>Uploaded data sample</StyledSectionHeader>
      </Grid>
      <Grid item>
        <StyledButton variant="outlined" onClick={() => setShowAddObservableModal(true)}>
          Show data sample
        </StyledButton>
      </Grid>
      <UploadedDataModal open={showAddObservableModal} close={closeFn} nameId={p.nameId} />
    </Grid>
  )
}

export default React.memo(UploadedDataSample)
