import React from "react"

import KeyValueDisplay from "../../../../../components/KeyValueDisplay"
import MetadataBlockDisplay from "../../../../../components/MetadataBlockDisplay"
import type { DatasetMetadata } from "../../../../../utils/frontendTypes/datasets.types"
import type { Nullable } from "../../../../../utils/utils"

type Props<T extends Record<string, string>> = {
  metadata: DatasetMetadata
  fillable: (keyof T)[]
  keyTransformFn: (key: string) => string
}

const DatasetMetadataDisplay = <T extends Record<string, string>>(p: Props<T>) => {
  const maintainerMetadata = {
    maintanierOrganisation: p.metadata.maintanierOrganisation,
    contactFirstName: p.metadata.contactFirstName,
    contactLastName: p.metadata.contactLastName,
    contactEmail: p.metadata.contactEmail,
  }

  const publlisherKeyNamesMap = {
    maintanierOrganisation: "Organisation",
    contactFirstName: "First name",
    contactLastName: "Last name",
    contactEmail: "Email",
  }

  return (
    <>
      {Object.entries(p.metadata)
        .filter(([key]) => !Object.keys(maintainerMetadata).includes(key))
        .map(([key, value]) => (
          <KeyValueDisplay key={key} objectKey={p.keyTransformFn(key)} value={value} />
        ))}
      <MetadataBlockDisplay<Partial<Nullable<typeof maintainerMetadata>>>
        title="Maintainer"
        metadata={maintainerMetadata}
        keyNamesMap={publlisherKeyNamesMap}
        sx={{ mb: 1 }}
      />
    </>
  )
}

export default React.memo(DatasetMetadataDisplay)
