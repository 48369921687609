import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { Box, Menu, MenuItem, Typography } from "@mui/material"
import React, { useState } from "react"
import { colorPalette } from "../styling/theme"

export enum CatalogMode {
  Dataset = "Dataset",
  Collection = "Data Collection",
}

export type CatalogModeMenuProps = {
  currentMode: CatalogMode
  setMode: React.Dispatch<CatalogMode>
}

const CollectionDatasetSwitch = (p: CatalogModeMenuProps) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const modes = Object.values(CatalogMode)

  const handleCloseUserMenu = () => {
    setAnchorElement(null)
  }

  const selectMode = (fnName: CatalogMode) => {
    setAnchorElement(null)
    p.setMode(fnName)
  }

  return (
    <>
      <Box
        sx={{ display: "flex", cursor: "pointer", ml: "auto", mr: 1 }}
        onClick={handleOpenUserMenu}
        data-testid="resultsModeSwitch">
        <Typography sx={{ color: colorPalette.deepPurpleO50, mr: 1 }}>{"View by:"}</Typography>
        <Typography>{p.currentMode}</Typography>
        <ArrowDropDownIcon />
      </Box>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleCloseUserMenu}>
        {modes.map(mode => (
          <MenuItem onClick={() => selectMode(mode)} key={"menu_" + mode}>
            <Typography textAlign="center">{mode}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default React.memo(CollectionDatasetSwitch)
