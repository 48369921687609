import { type ProjectCardProps } from "./components/ProjectCard"

export enum AuthorizedGroups {
  ODP_DEFAULT_ACCESS_GROUP = "82b38cd3-3de8-4901-aca8-63d64b924701",
  ODP_GCP_DEFAULT_ACCESS_GROUP = "27d04248-2ca5-45ee-903c-2d89a7652b5a",
  ODP_INTERNAL_READ_ALL = "e8c71084-e2e2-4bd8-bdf7-86f9e2154ed3",
  ODC_ACCESS_DASKHUB_USERS = "8fa722ae-ff94-482a-b908-5df3aee7b233",
  APP_LUSEDATA_READER = "a3cfb328-a92d-461c-82e3-e780c26fc03a",
}

export const projectsData: ProjectCardProps[] = [
  {
    imgSrc: "./appImgs/lusedata.png",
    title: "Lusedata",
    url: "https://lusedata.hubocean.earth/",
    description:
      "Get an overview of lice in aquaculture facilities around Norway. See how temperature, preventative measures and treatments can help control lice populations.",
  },
]

export const hasUserAuthorization = ({
  requiredGroups,
  authorizedGroups,
}: {
  requiredGroups?: AuthorizedGroups[]
  authorizedGroups: AuthorizedGroups[]
}): boolean => {
  if (!requiredGroups) return true
  return requiredGroups.some(group => {
    return authorizedGroups.includes(group)
  })
}
