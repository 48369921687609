import { Dialog, DialogActions, DialogContent } from "@mui/material"
import React, { useCallback } from "react"
import { StyledButton } from "../StyledButton"
import ModalMap from "./components/ModalMap"

type Props = {
  geometry: GeoJSON.Geometry
  closeFn: () => void
  open: boolean
}

const RefineLocationMapModal = (p: Props) => {
  const closeDialog = useCallback(() => {
    p.closeFn()
  }, [p])

  return (
    <>
      <Dialog open={p.open} onClose={closeDialog} fullWidth maxWidth="lg" PaperProps={{ sx: { p: 3 } }}>
        <DialogContent>{<ModalMap geometry={p.geometry} />}</DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <StyledButton onClick={closeDialog} size="large">
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(RefineLocationMapModal)
