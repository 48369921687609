import CloseIcon from "@mui/icons-material/Close"
import SearchIcon from "@mui/icons-material/Search"
import { Box, IconButton, TextField, keyframes } from "@mui/material"
import { debounce } from "lodash"
import React, { useRef, useState } from "react"

type Props = {
  setSearchString: React.Dispatch<React.SetStateAction<string>>
}

const expand = keyframes`
  from {
    max-width: 24px;
  }
  to {
    max-width: 300px; // arbitrary value that is larger than the input field
  }
`

const CollectionDatasetsSearch = (p: Props) => {
  const [showInput, setShowInput] = useState(false)

  const debouncedHandleSearchInputChange = useRef(
    debounce<(searchString: string) => any>(searchString => {
      p.setSearchString(searchString)
    }, 300)
  )

  const clickClose = () => {
    p.setSearchString("")
    setShowInput(false)
  }

  const endCloseIcon = (
    <IconButton sx={{ height: "12px", width: "12px" }} onClick={clickClose}>
      <CloseIcon sx={{ fontSize: "18px" }} />
    </IconButton>
  )

  return (
    <Box>
      {!showInput && (
        <IconButton onClick={() => setShowInput(true)} sx={{ ml: 1 }}>
          {<SearchIcon />}
        </IconButton>
      )}
      {showInput && (
        <TextField
          size="small"
          sx={{
            "&.MuiOutlinedInput-root.Mui-focused": { border: "none" },
            ml: 2,
            mr: 1,
            animation: `${expand} 800ms ease`,
          }}
          inputProps={{ sx: { height: "12px", pl: 1 } }}
          onChange={e => {
            const value = e.target.value
            debouncedHandleSearchInputChange.current(value)
          }}
          InputProps={{ endAdornment: endCloseIcon, sx: { pr: 1 } }}
        />
      )}
    </Box>
  )
}

export default React.memo(CollectionDatasetsSearch)
