import { Box, Link, Typography } from "@mui/material"
import React from "react"
import { StyledSectionHeader } from "../../../../../../../../components/StyledTypography"
import { externalPaths } from "../../../../../../../../components/navigation.helpers"

const UploadInstructions = () => {
  return (
    <Box>
      <StyledSectionHeader sx={{ mb: 1 }}>{"Tabular data upload"}</StyledSectionHeader>

      <Typography>
        To upload tabular data, follow the instructions in{" "}
        <Link href={externalPaths.TabularUploadNotebook} target="_blank" rel="noopener noreferrer">
          this notebook
        </Link>
        . You can also check our{" "}
        <Link href={externalPaths.Documentation} target="_blank" rel="noopener noreferrer">
          documentation
        </Link>{" "}
        for additional information.
      </Typography>
    </Box>
  )
}

export default React.memo(UploadInstructions)
