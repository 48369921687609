import { createCollectionRefernceName } from "../../api/requests.utils"
import { type ApiResponse } from "../../api/response.types"
import { type APIResultsDto, type DatasetDtoGet } from "../utils/entities/sdk.dataset.types"
import {
  INTERNALY_SHARED_LABEL,
  RELEASED_LABEL,
  ResourceKind,
  type OqsCondition,
  type ResourceFilterParams,
} from "../utils/entities/sdk.resource.types"
import { generateCaseInsensitivePattern } from "../utils/requests/sdk.request.helpers"
import type { requestFnType } from "../utils/requests/sdk.request.types"

export class CatalogSDK {
  requestFn: requestFnType

  constructor(requestFn: requestFnType) {
    this.requestFn = requestFn
  }

  async getDataProductById(uuid: string): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#EQUALS": ["$kind", ResourceKind.dataset],
      },
    })
  }

  async getDatasetSearchResult({
    typedSearchString,
    filterIds,
    searchInTags = true,
    isPublic,
    collectionId,
    isInternallyShared,
  }: ResourceFilterParams & { collectionId?: string }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    const oqsCollectionIdCondition = collectionId
      ? [{ "#EQUALS": ["$spec.data_collection", createCollectionRefernceName(collectionId)] }]
      : undefined

    return await this.getResourceSearchResultsAPI<DatasetDtoGet>({
      typedSearchString,
      filterIds,
      kind: ResourceKind.dataset,
      searchInTags,
      resourceAvailability: isPublic ? "public" : "private",
      otherOqsConditions: oqsCollectionIdCondition,
      isInternallyShared,
    })
  }

  async getResourceSearchResultsAPI<T>({
    typedSearchString,
    kind,
    searchFields = ["description", "display_name", "name"],
    filterIds,
    searchInTags = true,
    resourceAvailability,
    otherOqsConditions = [],
    isInternallyShared,
  }: ResourceFilterParams & {
    kind: ResourceKind
    searchFields?: string[]
    otherOqsConditions?: OqsCondition[]
    resourceAvailability: "public" | "private"
  }): Promise<ApiResponse<APIResultsDto<T>>> {
    const pattern = typedSearchString ? generateCaseInsensitivePattern(typedSearchString) : ""

    const oqsConditions: OqsCondition[] = [{ "#EQUALS": ["$kind", kind] }]

    if (resourceAvailability === "public") {
      oqsConditions.push({
        "#EQUALS": [`$labels.'${RELEASED_LABEL}'`, true],
      })
    }

    if (resourceAvailability === "private") {
      oqsConditions.push({
        "#OR": [{ "#IS_NULL": [`$labels.'${RELEASED_LABEL}'`] }, { "#EQUALS": [`$labels.'${RELEASED_LABEL}'`, false] }],
      })
    }

    if (isInternallyShared === true) {
      oqsConditions.push({
        "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, true],
      })
    }

    if (isInternallyShared === false) {
      oqsConditions.push({
        "#OR": [
          { "#IS_NULL": [`$labels.'${INTERNALY_SHARED_LABEL}'`] },
          { "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, false] },
        ],
      })
    }
    // if isInternallyShared is undefined we don't check for the label

    if (searchFields?.length) {
      oqsConditions.push({
        "#OR": searchFields.map(el => ({ "#REGEX_LIKE": [`$${el}`, pattern] })),
      })
    }
    if (filterIds?.length) oqsConditions.push({ "#WITHIN": ["$metadata.name", [...filterIds]] })
    if (searchInTags && typedSearchString) {
      oqsConditions[2]["#OR"]?.push({ "#WITHIN": [typedSearchString, "$spec.tags"] })
    }

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": [...oqsConditions, ...otherOqsConditions],
      },
    })
  }
}
