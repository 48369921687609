import { Alert, Typography } from "@mui/material"
import React from "react"
import { useDeleteObservableMutation } from "../../../../../../../utils/redux/queries/dataset.queries"
import type { ObservableDtoGet } from "../../../../../../../utils/sdk/utils/entities/sdk.observable.types"

const BrokenObservable = (p: ObservableDtoGet<any>) => {
  const [deleteObservable] = useDeleteObservableMutation()

  const clickDelete = () => {
    const confirmed = window.confirm("Do you want to delete this observable?")

    if (confirmed) void deleteObservable(p.metadata.uuid)
  }

  return (
    <Alert severity="error">
      {"This observable seems to be broken. "}
      <Typography
        onClick={clickDelete}
        fontSize={"small"}
        sx={{ display: "inline", textDecoration: "underline", cursor: "pointer" }}>
        {"Click here to delete it."}
      </Typography>
    </Alert>
  )
}

export default React.memo(BrokenObservable)
