import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { StyledButton } from "../../../../../../components/StyledButton"
import { useUpdateMetadataMutation } from "../../../../../../utils/redux/queries/dataset.queries"
import type { Facets } from "../../../../../../utils/sdk/utils/entities/sdk.dataset.types"
import AddNewFacet, { ADD_FACET_BUTTON_ID } from "./components/AddNewFacet"
import CurrentFacets from "./components/CurrentFacets"
import NewFacets from "./components/NewFacets"

type Props = {
  open: boolean
  closeFn: () => void
  datasetId: string
  currentOtherFacets?: Facets | null
  currentSpecialFacets?: Facets | null
}

const EditFacetsDialog = (p: Props) => {
  const [updateDataset] = useUpdateMetadataMutation()

  const [facetToDeleteKeys, setFacetToDeleteKeys] = useState<string[]>([])

  const [newFacets, setNewFacets] = useState<Facets>({})
  const [addingMode, setAddingMode] = useState(false)

  const close = () => {
    p.closeFn()
  }

  const addNewFacet = useCallback(
    (key: string, value: string) => {
      setNewFacets({ ...newFacets, [key]: value })
    },
    [newFacets]
  )

  const internalAddFacetClick = () => {
    document.getElementById(ADD_FACET_BUTTON_ID)?.click()
  }

  const saveFacets = async () => {
    let updatedFacets: Facets = {}
    if (p.currentOtherFacets) {
      for (const [key, value] of Object.entries(p.currentOtherFacets)) {
        if (!facetToDeleteKeys.includes(key)) {
          updatedFacets[key] = value
        }
      }
    }
    updatedFacets = { ...p.currentSpecialFacets, ...updatedFacets, ...newFacets }
    await updateDataset({ datasetId: p.datasetId, facets: updatedFacets })
  }

  // cleanup on close
  useEffect(() => {
    return () => {
      setFacetToDeleteKeys([])
      setNewFacets({})
    }
  }, [])

  return (
    <Dialog open={p.open} onClose={close} fullWidth maxWidth="sm" PaperProps={{ sx: { p: 3 } }}>
      <DialogTitle id="facets-dialog" fontWeight={700}>
        {"Edit facets"}
      </DialogTitle>
      <DialogContent sx={{ justifyContent: "center" }}>
        <CurrentFacets
          facets={p.currentOtherFacets}
          facetsToDelete={facetToDeleteKeys}
          setFacetsToDelete={setFacetToDeleteKeys}
        />
        <NewFacets newFacets={newFacets} setNewFacets={setNewFacets} />
        <AddNewFacet addFacet={addNewFacet} addingMode={addingMode} setAddingMode={setAddingMode} />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        {addingMode && (
          <StyledButton variant="outlined" onClick={internalAddFacetClick}>
            Add facet
          </StyledButton>
        )}
        {!addingMode && (
          <StyledButton variant="contained" onClick={saveFacets}>
            Save
          </StyledButton>
        )}

        <StyledButton onClick={close}>Cancel</StyledButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditFacetsDialog)
