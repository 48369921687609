/* eslint-disable @typescript-eslint/quotes */
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent,
} from "@mui/material"

import React, { useState } from "react"
import DialogSectionHeader from "./DialogSectionHeader"

type Props = {
  addFacet: (key: string, value: any) => void
  addingMode: boolean
  setAddingMode: React.Dispatch<boolean>
}

export const ADD_FACET_BUTTON_ID = "add-facet-button"

enum KeyError {
  emptyKey = "Key cannot be empty.",
}

const AddNewFacet = (p: Props) => {
  const types = ["string", "number", "boolean"]

  const defaultBooleanVal = true

  const [type, setType] = useState(types[0])
  const [booleanValue, setBooleanValue] = useState<boolean>(defaultBooleanVal)
  const [value, setValue] = useState<string | number | boolean>("")
  const [key, setKey] = useState("")
  const [keyError, setKeyError] = useState<KeyError | null>(null)

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value)
    if (event.target.value === "boolean") setValue(defaultBooleanVal)
  }

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value)
    setKeyError(null)
  }

  const handleChangeBoolean = (event: SelectChangeEvent) => {
    const value = event.target.value === "true"
    setBooleanValue(value)
    setValue(value)
  }

  const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(event.target.value))
  }

  const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }

  const validateInputs = () => {
    if (key === null || key === "") {
      setKeyError(KeyError.emptyKey)
      return false
    }
    return true
  }

  const clickAddIcon = () => {
    if (value === null) return
    if (!validateInputs()) return
    setTimeout(() => p.addFacet(key, value), 400)
    p.setAddingMode(false)
    setKey("")
  }

  return (
    <Box>
      <Accordion
        elevation={0}
        sx={{ px: 0, m: 0 }}
        onChange={() => p.setAddingMode(!p.addingMode)}
        TransitionProps={{ unmountOnExit: true }}
        expanded={p.addingMode}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ pl: 0, m: 0, pr: 1 }}>
          <DialogSectionHeader title="Adding new facet: " />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, mb: 1 }}>
          <Box display={"flex"} alignItems={"center"}>
            <Typography sx={{ mr: 3 }}>Value type: </Typography>
            <FormControl sx={{ display: "flex" }}>
              <RadioGroup
                row
                value={type}
                onChange={handleChangeType}
                aria-labelledby="dtype_select_label"
                name="radio-buttons-group"
                sx={{ display: "flex" }}>
                {types.map((el, i) => (
                  <FormControlLabel key={el} value={el} control={<Radio />} label={el} />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ mt: 1 }} display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
            <TextField
              size="small"
              label="Key: "
              onChange={handleKeyChange}
              value={key}
              fullWidth
              sx={{ mr: 2 }}
              error={keyError !== null}
              helperText={keyError}></TextField>
            {type === "string" && (
              <TextField size="small" label="Value: " onChange={handleChangeText} fullWidth></TextField>
            )}
            {type === "number" && (
              <TextField size="small" label="Value: " type="number" onChange={handleChangeNumber} fullWidth></TextField>
            )}
            {type === "boolean" && (
              <FormControl fullWidth>
                <InputLabel id="facet_value_label">Type</InputLabel>
                <Select
                  fullWidth
                  labelId="facet_value_label"
                  id="facet_value_select"
                  size="small"
                  value={booleanValue?.toString()}
                  label="Type"
                  onChange={handleChangeBoolean}>
                  {["true", "false"].map(el => (
                    <MenuItem key={"type_" + el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <IconButton onClick={clickAddIcon} sx={{ ml: 1 }} id={ADD_FACET_BUTTON_ID}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default React.memo(AddNewFacet)
