import "mapbox-gl/dist/mapbox-gl.css"
import React from "react"
import { useMiniMap } from "../../../utils/hooks/useMiniMap"

type Props = {
  geometry?: GeoJSON.Geometry
  width?: string
  height?: string
  containerId: string
}

const GeometryMiniMap = ({ geometry, width = "100%", height = "350px", containerId = "minimap" }: Props) => {
  useMiniMap({ containerId, geometry, interactive: true })

  return <div id={containerId} style={{ width, height, borderRadius: "4px" }} />
}

export default React.memo(GeometryMiniMap)
