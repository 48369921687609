import type { TimeRangeMiliSeconds } from "../../../frontendTypes/datasets.types"
import type { Modify } from "../../../utils"
import type { ResourceDtoCreate, ResourceKind, ResourceMetadataDtoGet, ResourceStatus } from "./sdk.resource.types"

export type TimeRangeObservableValue = TimeRangeMiliSeconds

export enum ObservableCls {
  BBox = "catalog.hubocean.io/observableClass/static-geometric-coverage",
  TimeRange = "catalog.hubocean.io/observableClass/static-coverage",
}

export const OBSERVABLE_VERSION = "v1alpha2"

type ObservableSpec<T> = {
  ref: string
  observable_class: ObservableCls
  details: {
    value: T
    attribute: "coverage"
  }
}

export type ObservableDtoCreate<T> = Modify<
  ResourceDtoCreate,
  {
    spec: ObservableSpec<T>
    kind: ResourceKind.observable
    version: typeof OBSERVABLE_VERSION
  }
>

export type ObservableDtoGet<T> = Modify<
  ObservableDtoCreate<T>,
  { status: ResourceStatus; metadata: ResourceMetadataDtoGet }
>
