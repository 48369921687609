import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import React from "react"
import { type DataPointsTable } from "../../utils/api/schema.mappers"
import DataCell from "./components/DataCell"

type Props = {
  data: DataPointsTable
}

const DataSampleTable = (p: Props) => {
  const data = p.data

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {data.columns.map(col => (
                <TableCell key={"col_" + col}>{col}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((row: any, rowIndex) => (
              <TableRow key={"row_sample_" + rowIndex} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {data.columns.map((col, colIndex) => (
                  <DataCell key={"cell_" + colIndex + rowIndex} column={col} value={row[col]} />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default React.memo(DataSampleTable)
