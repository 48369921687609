import React from "react"
import { externalPaths } from "../../../components/navigation.helpers"
import { colorPalette } from "../../../styling/theme"
import DashbordItem from "./components/DashbordItem"
import WidgetButton from "./components/WidgetButton"
import WidgetContent from "./components/WidgetContent"
import WidgetHeader from "./components/WidgetHeader"

const GetSupportWidget = () => {
  const onClick = () => {
    window.open(externalPaths.Support)
  }

  return (
    <DashbordItem sx={{ backgroundColor: colorPalette.luminousCoralO20 }} elevation={0}>
      <WidgetHeader title="Get Support" color={colorPalette.luminousCoral} />
      <WidgetContent description="Join our Slack community to stay in touch with others passionate about ocean data and the ODP." />
      <WidgetButton onClick={onClick} backgroundColor={colorPalette.luminousCoral} textColor={colorPalette.white}>
        Join our community
      </WidgetButton>
    </DashbordItem>
  )
}

export default React.memo(GetSupportWidget)
