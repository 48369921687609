import { Alert, Grid } from "@mui/material"
import { type SerializedError } from "@reduxjs/toolkit"
import { type FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import React, { useCallback, useEffect, useState } from "react"
import { Loader } from "../../../../../../../../components/Loader/Loader"
import { StyledButton } from "../../../../../../../../components/StyledButton"
import { StyledSectionHeader } from "../../../../../../../../components/StyledTypography"
import { type TableSchemaGetDto } from "../../../../../../../../utils/api/schema.types"
import { type DataSet } from "../../../../../../../../utils/frontendTypes/datasets.types"
import { updateTabularProgressStatus } from "../../../../../../../../utils/redux/reducers/datasetsReducer"
import { useAppDispatch } from "../../../../../../../../utils/redux/store"
import DisplaySchemaDialog from "./components/DisplaySchemaDialog"

type Props = {
  dataset: DataSet
  schema?: TableSchemaGetDto
  isLoading: boolean
  schemaError?: FetchBaseQueryError | SerializedError
}

const DatasetSchema = (p: Props) => {
  const [displaySchemaModalOpen, setDisplaySchemaModalOpen] = useState(false)

  const dispatch = useAppDispatch()
  const closeSchemaModal = useCallback(() => setDisplaySchemaModalOpen(false), [])

  useEffect(() => {
    if (p.schema && !p.schemaError) dispatch(updateTabularProgressStatus({ type: "schemaCreated", value: true }))
    else dispatch(updateTabularProgressStatus({ type: "schemaCreated", value: false }))
    return () => {
      dispatch(updateTabularProgressStatus({ type: "schemaCreated", value: false }))
    }
  }, [dispatch, p.schema, p.schemaError])

  const showWentWrongError = p.schemaError && "status" in p.schemaError && p.schemaError.status === 500
  const notFoundError = p.schemaError && "status" in p.schemaError && p.schemaError.status === 404

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <StyledSectionHeader>{"Dataset Schema"}</StyledSectionHeader>
      </Grid>
      {p.isLoading && (
        <Grid item sx={{ ml: 5 }}>
          <Loader size={25} />
        </Grid>
      )}
      {notFoundError && !p.isLoading && (
        <Grid item>
          <Alert severity={"info"}>Schema is not defined</Alert>
        </Grid>
      )}
      {showWentWrongError && (
        <Grid item>
          <Alert severity="error">Something went wrong.</Alert>
        </Grid>
      )}
      {p.schema && !p.isLoading && !p.schemaError && (
        <>
          <Grid item>
            <StyledButton variant="outlined" onClick={() => setDisplaySchemaModalOpen(true)}>
              Show Schema
            </StyledButton>
          </Grid>
          <DisplaySchemaDialog
            closeFn={closeSchemaModal}
            open={displaySchemaModalOpen}
            dataset={p.dataset}
            schema={p.schema}
          />
        </>
      )}
    </Grid>
  )
}

export default React.memo(DatasetSchema)
