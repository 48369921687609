import { Typography, type SxProps } from "@mui/material"
import React from "react"

type Props = {
  sx?: SxProps
  title: string
}

const DialogSectionHeader = (p: Props) => {
  return (
    <Typography fontWeight={"bold"} sx={{ ...p.sx }}>
      {p.title}
    </Typography>
  )
}

export default React.memo(DialogSectionHeader)
