import { styled, Typography, type TypographyProps } from "@mui/material"
import { type PropsWithChildren } from "react"
import { colorPalette } from "../../styling/theme"

const SectionHeader = (props: PropsWithChildren<TypographyProps>) => (
  <Typography variant="h5" sx={{ fontWeight: "bold", my: 1, ...props.sx }}>
    {props.children}
  </Typography>
)

const Tag = styled("div")(({ theme }) => ({
  borderRadius: "99999px",
  border: `1px solid ${colorPalette.graySuit}`,
  padding: "3px",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textAlign: "center",
  margin: theme.spacing(0.5),
  display: "inline-block",
}))

export const S = {
  SectionHeader,
  Tag,
}
