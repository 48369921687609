import { Box, FormControlLabel, Grid, Paper, Switch, Zoom } from "@mui/material"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import NumberInput from "../../../../../components/NumericInput/NumberInput"
import { StyledButton } from "../../../../../components/StyledButton"
import { type BoundingMapboxCoords } from "../../../../../utils/frontendTypes/datasets.types"

type Props = {
  coords?: BoundingMapboxCoords
  clickOverrideCoords: React.Dispatch<BoundingMapboxCoords>
}

const GeolocManualInputs = (p: Props) => {
  const [localCoords, setLocalCoords] = useState<BoundingMapboxCoords>(_.cloneDeep(p.coords) ?? [])
  const [showCoordInputs, setShowCoordInputs] = useState(true)

  useEffect(() => {
    if (p.coords) setLocalCoords(_.cloneDeep(p.coords))
  }, [p.coords])

  useEffect(() => {
    return () => {
      setLocalCoords([])
    }
  }, [])

  const changeCoord = (rowIndex: number, columnIndex: number, value: string) => {
    if (value === "-") return

    const numValue = parseFloat(value)
    const updated = _.cloneDeep(localCoords)
    updated[rowIndex][columnIndex] = numValue

    // last coordinates in polygon are equal to first ones
    if (rowIndex === 0) updated[localCoords.length - 1][columnIndex] = numValue

    setLocalCoords(updated)
  }

  const setClick = () => {
    p.clickOverrideCoords(localCoords)
  }

  const handleChange = () => {
    setShowCoordInputs(prev => !prev)
  }

  const inputWidth = 130

  if (!localCoords.length) return null

  return (
    <Box zIndex={1} position={"absolute"} right={0} mr={7} mt={1}>
      {!showCoordInputs && (
        <FormControlLabel
          control={<Switch checked={showCoordInputs} onChange={handleChange} />}
          label="Show coordinates"
          sx={{ position: "absolute", right: 0 }}
        />
      )}
      <Zoom in={showCoordInputs}>
        <Box component={Paper} p={2}>
          <Grid container direction={"column"} spacing={1}>
            {localCoords.map((el, i) => {
              if (i === localCoords.length - 1) return null
              return (
                <Grid item key={"preciseCoords" + i}>
                  <NumberInput
                    sx={{ mr: 1, width: inputWidth }}
                    label={"Longitude"}
                    defaultValue={localCoords[i][0]}
                    onChange={e => changeCoord(i, 0, e.target.value)}
                    size="small"
                    numberInputProps={{ allowNegative: true, allowDecimal: true }}
                  />
                  <NumberInput
                    label="Latitude"
                    onChange={e => changeCoord(i, 1, e.target.value)}
                    defaultValue={localCoords[i][1]}
                    size="small"
                    sx={{ width: inputWidth }}
                    numberInputProps={{ allowNegative: true, allowDecimal: true }}
                  />
                </Grid>
              )
            })}
            <Grid item>
              <StyledButton onClick={setClick} variant={"contained"}>
                Update map coordinates
              </StyledButton>
              <StyledButton onClick={() => setShowCoordInputs(false)} sx={{ ml: 1 }}>
                Hide
              </StyledButton>
            </Grid>
          </Grid>
        </Box>
      </Zoom>
    </Box>
  )
}

export default React.memo(GeolocManualInputs)
